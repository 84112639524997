import React, { Component } from "react";
import { Form, notification, Icon } from 'antd';
import moment from 'moment';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user'
import { Row, Col, Button, Input, InputPassword, Block, FormItem, Title, TitleType, Upload } from '../../components';

import { UserProfileStyles } from "./UserProfileStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { Redirect } from "react-router";
import { StoreState, UserState, ApplicationState } from '../../types';

interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
	t: any;
}

interface ValidationObject {
	status: string,
	message: string
}

interface LoginFormValues {
	password: string,
	passwordValidation: ValidationObject,
	passwordConfirm: string,
	passwordConfirmValidation: ValidationObject,
	loading: any,
	imageUrl: any
}

interface ProfileEditProps {
	form: any;
	user: any;
	t: any;
	onSetProfileData: any;
}

interface ProfileEditValues {
	firstName: string,
	lastName: string,
	city: string,
	street: string,
	streetNr: string,
	postCode: string,
}

interface AvatarProps {
	loading: any,
	imageUrl: any
}

class ProfileEdit extends Component<ProfileEditProps> {

	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err: any, values: ProfileEditValues) => {
			if (!err) {
				userActions.updateProfile(
					values.firstName,
					values.lastName,
					values.city,
					values.street,
					values.streetNr,
					values.postCode
				)
					.then((result: any) => {
						const userData = { ...this.props.user.data, ...values };
						lockr.set('user', userData);
						this.props.onSetProfileData(userData);
						notification.success({ message: this.props.t('user.userProfileUpdated') });
					})
					.catch((error:any) => {
						console.log(error);
						notification.error({ message: 'Er ging iets verkeerd. Contacteer ons via de chat.' });
					})
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { user, t } = this.props;
		const dobFormat = 'DD/MM/YYYY';

		return (
			<Form onSubmit={this.handleSubmit} className="register-form">
				<FormItem>
					{getFieldDecorator('firstName', {
						initialValue: user.data.firstName || undefined,
						rules: [{ required: true, message: t('user.registerFirstNameRequiredValidation') }],
					})(
						<Input placeholder={t('user.userInputPlaceholderFirstName')} />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('lastName', {
						initialValue: user.data.lastName || undefined,
						rules: [{ required: true, message: t('user.registerLastNameRequiredValidation') }],
					})(
						<Input placeholder={t('user.userInputPlaceholderLastName')} />
					)}
				</FormItem>

				<div style={{ textAlign: 'right' }}>
					<Button type="primary" htmlType="submit" style={{ margin: '20px' }}> {t('user.userProfileUpdateProfile')} </Button>
				</div>
			</Form>
		);
	}
}

const defaultValidationObject = { status: '', message: '' };

function getBase64(img: any, callback: any) {
	console.log(img);
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		notification.error({ message: 'You can only upload JPG/PNG file!' });
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		notification.error({ message: 'Image must smaller than 2MB!' });
	}
	return isJpgOrPng && isLt2M;
}

class UserProfile extends Component<Props, LoginFormValues, AvatarProps> {
	state = {
		passwordConfirm: '',
		passwordConfirmValidation: defaultValidationObject,
		password: '',
		passwordValidation: defaultValidationObject,
		loading: false,
		imageUrl: this.props.user.data.avatar || 'https://fanarena.s3-eu-west-1.amazonaws.com/files/FA-BLANK.png'
	}

	handlePasswordUpdate = (e: any) => {
		e.preventDefault();

		if (this.state.passwordConfirmValidation.status !== 'error' && this.state.passwordValidation.status !== 'error') {
			userActions.updatePassword(this.state.password)
				.then(() => {
					notification.success({ message: this.props.t('user.passwordUpdated') });
					this.setState({
						passwordConfirm: '',
						passwordConfirmValidation: defaultValidationObject,
						password: '',
						passwordValidation: defaultValidationObject
					});
				})
				.catch((error:any) => {
					console.log(error)
					notification.error({ message: 'Er ging iets verkeerd. Contacteer ons via de chat.' });
				});
		}
	}

	onPasswordChange = (e: any) => {
		const newState = { password: e.target.value, passwordValidation: this.state.passwordValidation };
		if (!e.target.value) {
			newState.passwordValidation = { status: 'error', message: this.props.t('user.registerPasswordRequiredValidation') }
		} else if (e.target.value && e.target.value.length < 6) {
			newState.passwordValidation = { status: 'error', message: this.props.t('user.registerPasswordLengthValidation') }
		} else {
			newState.passwordValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	onPasswordConfirmChange = (e: any) => {
		const newState = { passwordConfirm: e.target.value, passwordConfirmValidation: this.state.passwordConfirmValidation };
		if (!e.target.value) {
			newState.passwordConfirmValidation = { status: 'error', message: this.props.t('user.registerPasswordRequiredValidation') }
		} else if (e.target.value && e.target.value.length < 6) {
			newState.passwordConfirmValidation = { status: 'error', message: this.props.t('user.registerPasswordLengthValidation') }
		} else if (e.target.value !== this.state.password) {
			newState.passwordConfirmValidation = { status: 'error', message: this.props.t('user.passwordResetPasswordsShouldMatch') }
		} else {
			newState.passwordConfirmValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	handleUpload = (f:any) => {
		this.setState({ loading: true });

		userActions.uploadAvatar(f)
			.then((result: any) => {
				getBase64(f, (imageUrl: any) =>
					this.setState({
						imageUrl: imageUrl,
						loading: false,
					}),
				);

				notification.success({ message: this.props.t('user.avatarUploaded') });
			})
			.catch((err: any) => {
				console.log(err);
				notification.error({ message: this.props.t('user.uploadFailed') });
			})
	}

	render() {
		const { password, passwordValidation, passwordConfirm, passwordConfirmValidation } = this.state;
		const { user, t } = this.props;
		const WrappedProfileForm = Form.create()((props: any) => <ProfileEdit {...props} t={this.props.t} user={user} onSetProfileData={this.props.onSetProfileData} />);

		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'}  style={{fontSize: "40px"}} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<UserProfileStyles>
				<Row>
					<Col md={12} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>{t('user.userProfileEditProfileTitle')}</Title>
							<WrappedProfileForm />
						</Block>
						<Block>
							<Title type={TitleType.h2}>{t('user.uploadAvatar')}</Title>
								<Upload
									name="avatar"
									accept=".png,.jpeg,.jpg,.gif"
									listType="picture-card"
									className="avatar-uploader"
									showUploadList={false}
									action={this.handleUpload}
									beforeUpload={beforeUpload}
								>
									{this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
									<Icon type={this.state.loading ? 'loading' : 'plus'}  style={{fontSize: "40px"}} />
								</Upload>
						</Block>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>{t('user.userProfileChangePasswordTitle')}</Title>
							<Form className="login-form">
								<FormItem
									validateStatus={passwordValidation.status}
									help={passwordValidation.message}>
									<InputPassword
										type="password"
										name="password"
										value={password}
										onChange={this.onPasswordChange}
										placeholder={t('user.userPasswordPlaceholder')} />
								</FormItem>

								<FormItem
									validateStatus={passwordConfirmValidation.status}
									help={passwordConfirmValidation.message}>
									<InputPassword
										type="password"
										name="password"
										value={passwordConfirm}
										onChange={this.onPasswordConfirmChange}
										placeholder={t('user.userProfileChangePassowordConfirm')} />
								</FormItem>
								<div style={{ textAlign: 'right' }}>
									<Button type="primary" onClick={this.handlePasswordUpdate} htmlType="submit" style={{ margin: '20px' }}>
									{t('user.userProfileChangePasswordTitle')}
										</Button>
								</div>
							</Form>
						</Block>
					</Col>
				</Row>
			</UserProfileStyles>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
