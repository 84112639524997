import mediaQueries from "./media-queries";
import styled, {createGlobalStyle} from "./styled-components";
import bgPattern from '../assets/img/bg-pattern.png'; 

const GlobalStyle = createGlobalStyle`
    body {
        background-image: url(${bgPattern}), linear-gradient(to bottom, black, black);
        background-repeat: repeat; /* No repeating of the pattern */
        background-size: 15%;
        color: #FFF;
    }

    a {
        color: #FFF;

        &:hover {
            color: #FFF;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        color: #FFF;
    }

    .ant-select-dropdown-menu-item, .ant-select-selection-selected-value {
        color: #FFF;
    }

    // .ant-pagination-item {
    //     background: transparent;
    //     border: 0;
    //     a {
    //         color: #FFF;
    //     }
    // }

    // .ant-pagination-item-active {
    //     background: #FF286E;
    //     border-radius: 50px;
    //     color: #000;

    //     a { 
    //         color: #000;
    //     }
    // }

    // .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    //     background: transparent;
    //     border: 0;
    // }

    // .ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a {
    //     color: #FF286E;
    // }

    // .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    //     background: transparent;
    // }

`;

const PageStyle = styled.div`
    padding: 10px 0;

    @media ${mediaQueries.tablet} {
        padding: 50px 0;
    }
`;

const H1 = styled.h2`
    font-size: 30px;
    font-weight: bold;
`;

const H2 = styled.h2`
    font-size: 24px;
    font-weight: bold;
`;

const H3 = styled.h2`
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
    text-shadow: 2px 2px 20px #000000;
`;

export {
    GlobalStyle,
    PageStyle,
    H1,
    H2,
    H3
};
