import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import {
	ContainerStyle,
	SelectGroupStyle,
	TableStyle,
	PlayerStyle,
} from './PlayerListStyle';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import PlayerAvatar from '../Player/Player';
import Select from '../../components/UI/Select/Select';
import Popover from '../../components/UI/Popover/Popover';
import { Link } from '../UI/Link/Link';
import { theme } from "../../styles/theme-sporza";
import { PlayerType } from '../Player/Player';
import { Club, Player, OnPickType, IsPickAbleType } from './../../types';
import { roundNextHalf, getPlayerPositionHexColor } from './../../lib/helpers';
import { 
	BikeSvg,
	FootballSvg,
	EuroSvg,
	FootballFieldSvg,
	PowerSubSvg,
	CaptainSvg,
	SetPiecesSvg,
	StarSvg,
	StarterSvg,
	PlusSvg,
	GreenSvg,
	OrangeSvg,
	RedSvg,
	StrategySvg
} from './../../styles/custom-icons';

const FootballIcon = (props: any) => <Icon component={FootballSvg} {...props} />;
const FootballFieldIcon = (props: any) => <Icon component={FootballFieldSvg} {...props} />;
const EuroIcon = (props: any) => <Icon component={EuroSvg} {...props} />;
const PowerSubIcon = (props: any) => <Icon component={PowerSubSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainSvg} {...props} />;
const SetPiecesIcon = (props: any) => <Icon component={SetPiecesSvg} {...props} />;
const StarIcon = (props: any) => <Icon component={StarSvg} {...props} />;
const StarterIcon = (props: any) => <Icon component={StarterSvg} {...props} />;
const PlusIcon = (props: any) => <Icon component={PlusSvg} {...props} />;
const GreenIcon = (props: any) => <Icon component={GreenSvg} {...props} />;
const OrangeIcon = (props: any) => <Icon component={OrangeSvg} {...props} />;
const RedIcon = (props: any) => <Icon component={RedSvg} {...props} />;
const StrategyIcon = (props: any) => <Icon component={StrategySvg} {...props} />;

export interface PlayerListProps {
	data: any[];
	size: number;
	action: boolean;
	isLoading?: boolean;
	hidePositions?: boolean;
	onPick?: OnPickType;
	onSelect?: any;
	isPickAble?: IsPickAbleType;
	showHeader?: boolean;
	playerType: PlayerType;
	clubs: any[];
	activePositionFilter?: number,
	setActivePositionFilter?: any,
	assetsCdn: string;
	matches?: any;
	deadlineWeek?: any;
	actionLabel?: string;
	playerTax?: number | undefined;
	t?: any;
}

export interface PlayerListState {
	filters: any;
}

class PlayerList extends Component<PlayerListProps, PlayerListState> {

	constructor(props: PlayerListProps) {
		super(props);

		this.state = {
			filters: {
				search: '',
				budget: -1,
				club: -1,
				position: props.activePositionFilter || -1
			},
		};
	}

	componentDidUpdate(prevProps: PlayerListProps) {
		if (this.props.activePositionFilter !== prevProps.activePositionFilter) {
			const filters = { ...this.state.filters, position: this.props.activePositionFilter }
			this.setState({ filters });
		}
	}

	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});

		if (this.props.activePositionFilter && this.props.setActivePositionFilter && name === 'position') {
			this.props.setActivePositionFilter(value);
		} else {
			this.setState({ filters });
		}
	};

	playerFilter = (player: Player, filters: any) => {
		let show = true;

		if (
			filters.search.length &&
			player.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1
		) {
			show = false;
		}

		if (filters.club !== -1 && filters.club !== player.clubId) {
			show = false;
		}

		if (filters.position !== -1 && filters.position !== player.positionId) {
			show = false;
		}

		if (filters.budget !== -1 && player.value > filters.budget) {
			show = false;
		}
		return show;
	};

	onPickHandler = (e: any, record: any) => {
		e.stopPropagation();

		if (this.props.onPick) {
			this.props.onPick(record);
		}
	};

	render() {
		const {
			data,
			action,
			size,
			showHeader,
			clubs,
			hidePositions,
			playerType,
			isLoading,
			assetsCdn,
			matches,
			deadlineWeek,
			actionLabel,
			playerTax,
			t
		} = this.props;

		const clubsList = [{
			id: -1,
			name: <span className={'prefixed-label'}> <FootballIcon style={{ marginRight: 5 }} /> {t('general.footballAllClubs')} </span>
		}]
			.concat(clubs);

		const playersList = data.filter(player =>
			this.playerFilter(player, this.state.filters)
		);

		const budgets = [
			{ text: <span className={'prefixed-label'}> <EuroIcon /> {t('general.footballAllBudget')} </span>, value: 100 },
			{ text: `${t('general.budgetFilterPrefix')} 10 ${t('general.budgetFilterSuffix')}`, value: 10 },
			{ text: `${t('general.budgetFilterPrefix')} 9.5 ${t('general.budgetFilterSuffix')}`, value: 9.5 },
			{ text: `${t('general.budgetFilterPrefix')} 9 ${t('general.budgetFilterSuffix')}`, value: 9 },
			{ text: `${t('general.budgetFilterPrefix')} 8.5 ${t('general.budgetFilterSuffix')}`, value: 8.5 },
			{ text: `${t('general.budgetFilterPrefix')} 8 ${t('general.budgetFilterSuffix')}`, value: 8 },
			{ text: `${t('general.budgetFilterPrefix')} 7.5 ${t('general.budgetFilterSuffix')}`, value: 7.5 },
			{ text: `${t('general.budgetFilterPrefix')} 7 ${t('general.budgetFilterSuffix')}`, value: 7 },
			{ text: `${t('general.budgetFilterPrefix')} 6.5 ${t('general.budgetFilterSuffix')}`, value: 6.5 },
			{ text: `${t('general.budgetFilterPrefix')} 6 ${t('general.budgetFilterSuffix')}`, value: 6 },
			{ text: `${t('general.budgetFilterPrefix')} 5.5 ${t('general.budgetFilterSuffix')}`, value: 5.5 },
			{ text: `${t('general.budgetFilterPrefix')} 5 ${t('general.budgetFilterSuffix')}`, value: 5 },
			{ text: `${t('general.budgetFilterPrefix')} 4.5 ${t('general.budgetFilterSuffix')}`, value: 4.5 },
			{ text: `${t('general.budgetFilterPrefix')} 4 ${t('general.budgetFilterSuffix')}`, value: 4 }
		];

		const positions = [
			{ id: -1, name: <span className={'prefixed-label'}> <StrategyIcon style={{verticalAlign: '-0.4em'}} /> {t('general.footballAllPositions')} </span> },
			{ id: 0, name: t('player.coach'), short: t('player.coachShort') },
			{ id: 1, name: t('player.goalkeeper'), short: t('player.goalkeeperShort') },
			{ id: 2, name: t('player.defender'), short: t('player.defenderShort') },
			{ id: 3, name: t('player.midfielder'), short: t('player.midfielderShort') },
			{ id: 4, name: t('player.attacker'), short: t('player.attackerShort') },
		];

		const columns = [
			{
				title: '',
				key: 'avatar',
				dataIndex: 'avatar',
				width: '10%',
				render: (text: string, record: any) => {
					const sportSpecificProps: {
						shirtCycling?: string,
						shirtSoccer?: string,
						soccerJersey?: string,
						clubBadge?: string,
						portraitFace?: string,
						shirtFallback?: string,
						portraitFaceFallBack?: string,
						club?: Club
					} = {};

					if (PlayerType.SoccerPortrait === this.props.playerType && record) {
						// sportSpecificProps.soccerJersey = `${assetsCdn}/jerseys/club_${record.clubId}.png`;
						sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${record.clubId}.png`;
						sportSpecificProps.portraitFace = record.portraitUrl;
						sportSpecificProps.portraitFaceFallBack = `${assetsCdn}/players/dummy.png`;
					}

					if (PlayerType.SoccerShirt === this.props.playerType && record) {
						sportSpecificProps.shirtSoccer = `${assetsCdn}/jerseys/football/${record.clubId}.png`;
					}


					return (
						<div className="avatar-container">
							<PlayerAvatar
								key={`playerId-${record.id}`}
								pointsColor={'#000'}
								pointsBgColor={'#fff'}
								badgeColor={'#fff'}
								badgeBgColor={'#fff'}
								avatarOnly={true}
								player={record}
								type={this.props.playerType}
								t={this.props.t}
								{...sportSpecificProps}
							/>
						</div>
					);
				},
			},
			{
				key: 'name',
				title: 'Player',
				dataIndex: 'name',
				width: '40%',
				render: (text: string, record: any) => {
					const club = clubs.find(club => club.id === record.clubId);
					const position = positions.find(
						position => position.id === record.positionId
					);
					const positionColor = getPlayerPositionHexColor(record, theme);

					const weekMatches: any[] = matches.filter((match: any) => match.weekId === deadlineWeek && ([match.homeId.id, match.awayId.id].includes(record.clubId)));
					let opponentInfo = { playing: '', short: '' }

					if (weekMatches.length) {
						const opponentClubId = weekMatches[0].homeId.id === record.clubId ? weekMatches[0].awayId.id : weekMatches[0].homeId.id;
						const club = clubs.find((item: any) => item.id === opponentClubId);
						opponentInfo.short = club ? club.short : '';
						opponentInfo.playing = weekMatches[0].homeId.id === record.clubId ? t('player.opponentHome') : t('player.opponentAway');
					}

					return (
						<React.Fragment>
							<PlayerStyle
								type="mobile"
								clubColor={'84FF00'}
								position={record.positionId}
							>
								<p className="mobile-name">{record.short}</p>
								<p>
									<span className="player-position" style={{ color: positionColor }}>
										{(!hidePositions && position && position.short) || null}
									</span> vs {opponentInfo.short}
								</p>
								<p>
									{/* {(record.squadStatus === 'First team') && <Tooltip title="Basisspeler"><GreenIcon style={{ marginRight: "2px" }}/></Tooltip>}
									{(record.squadStatus === 'Rotation') && <Tooltip title="Twijfelgeval"><OrangeIcon style={{ marginRight: "2px" }}/></Tooltip>}
									{(record.squadStatus === 'Backup') && <Tooltip title="Bankzitter"><RedIcon style={{ marginRight: "2px" }}/></Tooltip>} */}

									{(record.star === 1) && <Tooltip title="Sterspeler"><StarIcon style={{ marginRight: "2px" }}/></Tooltip> }
									{(record.captain === 1) && <Tooltip title="Aanvoerder"><CaptainIcon style={{ marginRight: "2px" }}/></Tooltip> }
									{(record.setPieces === 1) && <Tooltip title="Vrije trap/strafschop nemer"><SetPiecesIcon style={{ marginRight: "2px" }}/></Tooltip> }
								</p>
							</PlayerStyle>
							<PlayerStyle
								type="desktop"
								clubColor={'84FF00'}
								position={record.positionId}
							>
								<p className="name">
									<span> {record.short} </span>
								</p>
								<p>
									<span className="player-position" style={{ color: positionColor }}>
										{(!hidePositions && position && position.name) || null}
									</span> vs {opponentInfo.short}
								</p>
								<p>
								{/* {(record.squadStatus === 'First team') && <Tooltip title="Basisspeler"><GreenIcon style={{ marginRight: "2px" }}/></Tooltip>}
									{(record.squadStatus === 'Rotation') && <Tooltip title="Twijfelgeval"><OrangeIcon style={{ marginRight: "2px" }}/></Tooltip>}
									{(record.squadStatus === 'Backup') && <Tooltip title="Bankzitter"><RedIcon style={{ marginRight: "2px" }}/></Tooltip>} */}

									{(record.star === 1) && <Tooltip title="Sterspeler"><StarIcon style={{ marginRight: "2px" }}/></Tooltip> }
									{(record.captain === 1) && <Tooltip title="Aanvoerder"><CaptainIcon style={{ marginRight: "2px" }}/></Tooltip> }
									{(record.setPieces === 1) && <Tooltip title="Vrije trap/strafschop nemer"><SetPiecesIcon style={{ marginRight: "2px" }}/></Tooltip> }
								</p>
							</PlayerStyle>
						</React.Fragment>
					);
				},
			},
			{
				key: 'value',
				title: 'Value',
				dataIndex: 'value',
				width: '30%',
				render: (playerValue: number, record: any) => {
					const calculatedPlayerValue = !playerTax
						? playerValue
						: roundNextHalf(playerValue + (playerValue * playerTax) / 100);

					const infoValueContent = (
						<div>
							{(playerTax && (
								<span>
									{`${calculatedPlayerValue}M = ${playerValue}M + ${calculatedPlayerValue -
										playerValue}M (${playerTax}% belasting)`}
								</span>
							)) ||
								null}
							<br />
							{(playerTax && (
								<i style={{ fontSize: '10px' }}>
									{`Afgerond van ${(
										(playerValue * playerTax) /
										100
									).toFixed(2)}M naar ${calculatedPlayerValue - playerValue}M`}
								</i>
							)) ||
								null}
						</div>
					);

					return (
						<span>
							€{calculatedPlayerValue}
							{(playerTax && (
								<Popover content={infoValueContent} title={false}>
									<Icon
										style={{ paddingLeft: '5px', cursor: 'pointer' }}
										type="info-circle"
										theme="filled"
									/>
								</Popover>
							)) ||
								null}
						</span>
					);
				},
			},
		];

		if (action) {
			columns.push({
				key: 'action',
				title: 'Pick player',
				dataIndex: 'action',
				width: '20%',
				render: (text: string, record: any) => {
					return (
						(this.props.isPickAble && this.props.isPickAble(record) && (
							<Button
								type="primary"
								onClick={(e: any) => this.onPickHandler(e, record)}
								size="small"
							>
								{/* {actionLabel || t('general.pick')} */}
								<Icon type="plus-circle" theme="filled" />
							</Button>
						)) || <span />
					);
				},
			});
		}

		let tableEventHandler: any = () => { };

		if (this.props.onSelect) {
			tableEventHandler = (player: any) => ({
				onClick: (event: any) => {
					this.props.onSelect(player);
				},
			});
		}

		return (
			<ContainerStyle>
				{
					<Input
						prefix={<Icon type="search" style={{color: '#83FF00'}} />}
						type="text"
						placeholder={t('general.playersListSearchInputPlaceholder')}
						name="search"
						onChange={(event: any) =>
							this.onFilterChange(event.target.name, event.target.value)
						}
						style={{ margin: 0 }}
					/>
				}
				<SelectGroupStyle>
					<Select
						block
						values={clubsList}
						style={{ marginLeft: 0 }}
						keyProperty={'id'}
						onSelect={(value: any) => this.onFilterChange('club', value)}
						textProperty={'name'}
						placeholder={clubsList[0].name}
					/>
					{(!hidePositions && (
						<Select
							style={{ margin: '0px 0px' }}
							block
							value={this.state.filters.position}
							keyProperty={'id'}
							textProperty={'name'}
							onSelect={(value: any) => this.onFilterChange('position', value)}
							values={positions}
							placeholder={positions[0].name}
						/>
					)) || null}
					<Select
						block
						values={budgets}
						style={{ marginRight: 0 }}
						keyProperty={'value'}
						onSelect={(value: any) => this.onFilterChange('budget', value)}
						textProperty={'text'}
						placeholder={budgets[0].text}
					/>
				</SelectGroupStyle>

				<TableStyle
					columns={columns}
					dataSource={playersList}
					showHeader={showHeader}
					locale={{ emptyText: t('general.playersListEmpty') }}
					loading={isLoading}
					pagination={{ pageSize: size, showLessItems: true }}
					onRow={tableEventHandler}
					rowKey="id"
					rowClassName={(record: object, index: number) =>
						`${index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'} ${
						this.props.onSelect ? 'cursor-pointer' : ''
						}`
					}
				/>
			</ContainerStyle>
		);
	}
}

export default PlayerList;
