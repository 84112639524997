import styled from "../../styles/styled-components";
import mediaQueries from "../../styles/media-queries";

const RulesStyles = styled.div`
    .rules {
        h1 {
            font-size: 24px;
            text-decoration: none;
            // border-bottom: 1px solid #3C3C3C;
            font-family: "C-Bold";
            color: #FFF;
            margin: 0;
            padding: 0;
        }
        h2 {
            font-size: 24px;
            color: #83FF00;
        }
    
        h3 {
            font-size: 21px;
            color: #83FF00;
        }
    
        h4, h5 {
            font-size: 18px;
        }
    }
`;

export {
	RulesStyles
};