import React, { Component } from "react";
import { Form, notification, Icon } from 'antd';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';
import { HmacSHA256 } from 'crypto-js';

import * as userActions from '../../actions/user'
import { Row, Col, Button, Input, InputPassword, Checkbox, FormItem, Title, TitleType } from '../../components';

import config from '../../config';
import { FacebookIconSvg } from './../../styles/custom-icons';
import { LoginStyle } from "./LoginStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { Redirect } from "react-router";
import { StoreState, UserState, ApplicationState } from './../../types';
import FacebookLogin from 'react-facebook-login';
const FacebookIcon = (props: any) => <Icon component={FacebookIconSvg} {...props} />;

export interface Props {
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	application: ApplicationState;
	t: any;
}

export interface ValidationObject {
	status: string,
	message: string
}

export interface LoginFormValues {
	email: string,
	emailValidation: ValidationObject,
	password: string,
	passwordValidation: ValidationObject
}

const defaultValidationObject = { status: '', message: '' };

class Login extends Component<Props, LoginFormValues> {
	state = {
		email: '',
		emailValidation: defaultValidationObject,
		password: '',
		passwordValidation: defaultValidationObject
	}

	facebookCallback = (data: any) => {
		if (data && data.accessToken) {
			userActions.facebookLogin(data.accessToken, undefined)
				.then((result: any) => {
					const user = pick(result.user, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode', 'email', 'favoriteClubId', 'avatar', 'role']);
					lockr.set('token', result.token);
					lockr.set('user', user);

					if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
						(window as any).$crisp.push(["set", "user:email", result.email]);
						// name: `${result.firstName} ${result.lastName}`,
					}

					lockr.rm('authState');
					this.props.onSetProfileData(user);
					this.props.fetchUserTeams(this.props.application.competition.competitionFeed);
				})
				.catch((error: any) => {
					console.log(error)
					notification.error({ message: 'You should register with facebook first.' });
				});
		}
	}

	handleSubmit = (e: any) => {
		e.preventDefault();
		if (this.state.email.length && this.state.password.length && this.state.emailValidation.status !== 'error' && this.state.passwordValidation.status !== 'error') {
			userActions.login(this.state.email, this.state.password)
				.then(result => {
					const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode', 'email', 'registerInfo', 'avatar', 'role']);
					lockr.set('token', result.token);
					lockr.set('user', user);

					if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
						(window as any).$crisp.push(["set", "user:email", result.email]);
						// name: `${result.firstName} ${result.lastName}`,
					}

					this.props.onSetProfileData(user);
					this.props.fetchUserTeams(this.props.application.competition.competitionFeed);
				})
				.catch((error:any) => {
					console.log('error', error);
					notification.error({ message: this.props.t('user.loginInvalidEmailOrPassword') });
				});
		}
	}

	onEmailChange = (e: any) => {
		const newState = { email: e.target.value, emailValidation: this.state.emailValidation };
		if (!e.target.value) {
			newState.emailValidation = { status: 'error', message: this.props.t('user.registerEmailRequiredValidation') };
		} else {
			newState.emailValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	onPasswordChange = (e: any) => {
		const newState = { password: e.target.value, passwordValidation: this.state.passwordValidation };
		if (!e.target.value) {
			newState.passwordValidation = { status: 'error', message: this.props.t('user.registerPasswordRequiredValidation') }
		} else {
			newState.passwordValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	render() {
		const { email, emailValidation, password, passwordValidation } = this.state;
		const { t } = this.props;

		return (
			<LoginStyle>
				{this.props.user.authenticated && this.props.user.fetched && <Redirect
					to={{ pathname: `/new` }}
				/>}
				<Title type={TitleType.h2}>{t('user.loginTitle')}</Title>

				<Row className="login-content">
					<p>Nog geen account?</p>
					<Col md={24} lg={24} xs={24} sm={24}>
						<Link to={`/register`} style={{ textDecoration: 'underline' }}>
							<Button type="primary" className="register-button">
								<Icon type="mail" /> {t('user.registerWithEmail')} </Button>
						</Link>
					</Col>
					<Col md={24} lg={24} xs={24} sm={24}>
						<FacebookLogin
							appId={config.FB_APP_ID || ''}
							autoLoad={false}
							disableMobileRedirect={true}
							textButton={t('user.loginWithFacebook')}
							fields="name,email,picture"
							icon={<FacebookIcon />}
							cssClass="facebook-button"
							callback={this.facebookCallback} />
					</Col>
				</Row>
				<Row className="login-content">
					<Col className="gutter-row" md={24}>
						<div className="gutter-box">
							<p>Heb je al een account?</p>

							<Form className="login-form">
								<FormItem
									validateStatus={emailValidation.status}
									help={emailValidation.message}>
									<Input
										type="email"
										value={email}
										name="email"
										onChange={this.onEmailChange}
										placeholder={t('user.userEmailPlaceholder')} />
								</FormItem>

								<FormItem
									validateStatus={passwordValidation.status}
									help={passwordValidation.message}>
									<InputPassword
										type="password"
										name="password"
										value={password}
										onChange={this.onPasswordChange}
										placeholder={t('user.userPasswordPlaceholder')} />
								</FormItem>

								<Button type="primary" onClick={this.handleSubmit} htmlType="submit" style={{ margin: '20px auto' }}>{t('user.loginTitle')}</Button>

								<p><Link to={`/forgot`} style={{ textDecoration: 'underline' }}> {t('user.loginPasswordForgotLabelQuestion')}</Link></p>
							</Form>
						</div>
					</Col>
				</Row>
			</LoginStyle>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
