const players = [];

const clubs = // 20210630121236
// https://ekmanager-api.fanarena.com/clubs?competitionFeed=EURO&seasonId=2021
[
    {
      "id": 1521,
      "name": "Albanië",
      "short": "ALB",
      "styling": null,
      "externalId": 18641
    },
    {
      "id": 892,
      "name": "België",
      "short": "BEL",
      "styling": null,
      "externalId": 18743
    },
    {
      "id": 871,
      "name": "Denemarken",
      "short": "DEN",
      "styling": null,
      "externalId": 18583
    },
    {
      "id": 879,
      "name": "Duitsland",
      "short": "DUI",
      "styling": null,
      "externalId": 18660
    },
    {
      "id": 875,
      "name": "Engeland",
      "short": "ENG",
      "styling": null,
      "externalId": 18645
    },
    {
      "id": 876,
      "name": "Frankrijk",
      "short": "FRA",
      "styling": null,
      "externalId": 18647
    },
    {
      "id": 1522,
      "name": "Georgië",
      "short": "GEO",
      "styling": null,
      "externalId": 18692
    },
    {
      "id": 891,
      "name": "Hongarije",
      "short": "HON",
      "styling": null,
      "externalId": 18732
    },
    {
      "id": 878,
      "name": "Italië",
      "short": "ITA",
      "styling": null,
      "externalId": 18658
    },
    {
      "id": 872,
      "name": "Kroatië",
      "short": "KRO",
      "styling": null,
      "externalId": 18588
    },
    {
      "id": 881,
      "name": "Nederland",
      "short": "NED",
      "styling": null,
      "externalId": 18694
    },
    {
      "id": 873,
      "name": "Oekraïne",
      "short": "OEK",
      "styling": null,
      "externalId": 18624
    },
    {
      "id": 874,
      "name": "Oostenrijk",
      "short": "OOS",
      "styling": null,
      "externalId": 18643
    },
    {
      "id": 882,
      "name": "Polen",
      "short": "POL",
      "styling": null,
      "externalId": 18699
    },
    {
      "id": 883,
      "name": "Portugal",
      "short": "POR",
      "styling": null,
      "externalId": 18701
    },
    {
      "id": 1519,
      "name": "Roemenië",
      "short": "ROE",
      "styling": null,
      "externalId": 18638
    },
    {
      "id": 885,
      "name": "Schotland",
      "short": "SCH",
      "styling": null,
      "externalId": 18706
    },
    {
      "id": 1523,
      "name": "Servië",
      "short": "SER",
      "styling": null,
      "externalId": 18873
    },
    {
      "id": 1520,
      "name": "Slovenië",
      "short": "SLO",
      "styling": null,
      "externalId": 18630
    },
    {
      "id": 877,
      "name": "Slowakije",
      "short": "SVK",
      "styling": null,
      "externalId": 18648
    },
    {
      "id": 887,
      "name": "Spanje",
      "short": "SPA",
      "styling": null,
      "externalId": 18710
    },
    {
      "id": 889,
      "name": "Tsjechië",
      "short": "TSJ",
      "styling": null,
      "externalId": 18718
    },
    {
      "id": 888,
      "name": "Turkije",
      "short": "TUR",
      "styling": null,
      "externalId": 18716
    },
    {
      "id": 886,
      "name": "Zwitserland",
      "short": "ZWI",
      "styling": null,
      "externalId": 18708
    }
  ]
;

export {
	players,
	clubs
};