import styled, {keyframes} from "../../styles/styled-components";
import mediaQueries from "../../styles/media-queries";

const Block = styled.div`	
	background: #1E1F1D;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 8px;

	// @media ${mediaQueries.mobileL} {
	// 	padding-top: 15px;
	// 	padding-bottom: 15px;
		
	// 	&.no-margin {
	// 		margin: 0px;
	// 	}
	// }
	
	// @media ${mediaQueries.mobileS} {
	// 	padding-top: 0px;
	// 	padding-bottom: 0px;
	// }
	
	// @media (max-width: 425px) {
	// 	margin-left: 5px;
	// 	margin-right: 5px;
	// }
`;

export {
	Block
}