import React, { Component } from "react";
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { Layout } from "./../../components";
import Link from '../UI/Link/Link';

import { StoreState, UserState } from './../../types';

import { FooterStyle } from "./FooterStyle";

type FooterProps = {
	user: UserState
}

class Footer extends Component<FooterProps> {
	state = {
		showChat: false
	};

	openChat = (e: any) => {
		e.preventDefault();

		if ((window as any).$crisp && (window as any).$crisp.is("chat:closed")) {
			(window as any).$crisp.push(['do', 'chat:open']);
		}

	};

	render() {

		return (
			<FooterStyle>
				<ul>
					<li><Link to={`/contact`} onClick={this.openChat}> <Icon type="wechat" /> Help</Link></li>
				</ul>
			</FooterStyle>
		);
	}
}
export const mapDispatchToProps = {
};

export function mapStateToProps({ user }: StoreState.All) {
	return {
		user,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
