import {
	loadTeams,
	teamsFetchedWithSuccess,
	teamsFetchFailed,
	UserAction,
	teamNameChanged
} from './user';

import { Dispatch } from "redux";
import http from './../lib/http';
import { notification } from 'antd';
import lockr from 'lockr';
import i18n from 'i18next';

export function add(competitionFeed: string, seasonId: number, teamName: string, starting: number[],
	bench: number[], captainId: number, viceCaptainId: number, nameVisible: number) {
	return function (dispatch: Dispatch<UserAction>) {
		dispatch(loadTeams());

		const user = (nameVisible) ? lockr.get('widgetUser') : 0;

		return http.post('team/add', { competitionFeed, seasonId, teamName, starting, bench, captainId, viceCaptainId, user })
			.then(createdTeam => {
				dispatch(teamsFetchedWithSuccess([createdTeam], true));
				notification.success({ message: 'Team bewaard' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Error creating your team' });
			})
	};
}

export function copy(teamId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.post(`team/${teamId}/copy`, {})
			.then(result => {
				dispatch(teamsFetchedWithSuccess([result.team], true));
				notification.success({ message: 'Team copied.' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Team could not be copied.' });
			})
	};
}

export function drop(teamId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.delete(`team/${teamId}`, {})
			.then(result => {
				dispatch(teamsFetchedWithSuccess(result.teams));
				notification.success({ message: 'Team deleted.' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Team could not be deleted.' });
			})
	};
}

export function get(teamId: number, weekId?: number, transfersWithMeta?: boolean) {
	const params: any = {
		_t: new Date().getTime()
	};
	if (weekId) {
		params.weekId = weekId;
	}

	if (transfersWithMeta) {
		params.transfersWithMeta = 1;
	}
	return http.get(`team/${teamId}`, params);
}

export function points(teamId: number, weekId?: number) {
	const params: any = {};
	return http.get(`team/${teamId}/points/${weekId}?withWeekWinner=1&withWeekAveragePoints=1`, params);
}

export function activateBooster(teamId: number, boosterType: string) {
	const params: any = { boosterType };
	return http.post(`team/${teamId}/booster`, params);
}

export function updateTeamName(teamId: number, teamName: string) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.post(`team/${teamId}/name`, { teamName })
			.then(() => {
				notification.success({ message: 'Teamnaam bewaard' });
				dispatch(teamNameChanged(teamId, teamName));
				return true;
			})
			.catch((error:any) => {
				console.log(error)
				notification.warning({ message: 'Error updating team name' });
			})
	};
}

export function edit(teamId: number, competitionFeed: string, seasonId: number, teamName: string, starting: number[],
	bench: number[], captainId: number | undefined, viceCaptainId: number | undefined, nameVisible: boolean | undefined) {

		const userNames = (nameVisible) ? lockr.get('widgetUser') : 0;
		console.log('name visible', nameVisible);
		console.log(userNames);
		console.log('payload', {
			competitionFeed,
			seasonId,
			teamName,
			starting,
			bench,
			captainId,
			viceCaptainId,
			userNames
		})

	return http.post(`team/${teamId}`, {
		competitionFeed,
		seasonId,
		teamName,
		starting,
		bench,
		captainId,
		viceCaptainId,
		userNames
	})
		.then(() => {
			notification.success({ message: 'Team aangepast' });
		})
		.catch((err) => {
			console.log(err);
			notification.warning({ message: 'Error updating your team' });
		});
}

export function editWeekSelections(teamId: number, competitionFeed: string, seasonId: number, teamName: string,
	starting: number[], bench: number[], captainId: number | undefined, viceCaptainId: number | undefined, weekId: number) {
	return http.post(`team/${teamId}/selections`, {
		competitionFeed,
		seasonId,
		teamName,
		starting,
		bench,
		captainId,
		viceCaptainId,
		weekId
	})
		.then(() => {
			notification.success({ message: 'Team aangepast' });
		})
		.catch((error:any) => {
			console.log(error)
			notification.warning({ message: 'Error editing your team' });
		});
}

export function submitTransfers(teamId: number, transfers: any[]) {
	return http.post(`team/transfers/${teamId}`, { transfers })
		.then(() => {
			notification.success({ message: 'Transfers bewaard' });
		})
		.catch((error:any) => {
			console.log(error)
			notification.warning({ message: `${i18n.t(error.response.data.message)}` });
		});
}

export function resetTransfers(teamId: number) {
	return http.post(`team/transfers/${teamId}/reset`, {})
		.then(() => {
			notification.success({ message: 'Transfers gereset' });
		})
		.catch((error:any) => {
			console.log(error)
			notification.warning({ message: 'Error resetting your transfers' });
		});
}

export function getRankings(competitionFeed: string, rankingType: string, pageRecords: number, pageNumber: number, name?: string, weekId?: number, month?: number, rankingTypeId?: number | null) {
	return http.get(`rankings/${rankingType}`, { pageRecords, pageNumber, name, weekId, month, competitionFeed, rankingTypeId });
	// return http.get(`redis/${rankingType}`, { pageRecords, pageNumber, name, weekId, competitionFeed, rankingTypeId });
}

export function getSubscriptions(competitionFeed: string, seasonId: number) {
	return http.get(`subscriptions`, { competitionFeed, seasonId });
}

export function createPayment(coupons: string[], competitionFeed: string, seasonId: number, teamIds: number[], redirectURL: string) {
	return http.post(`payments/create`, { coupons, competitionFeed, seasonId, teamIds, redirectURL });
}

export function cancelPayment(paymentId: number) {
	return http.post(`payments/cancel`, { paymentId });
}

export function validateCoupon(coupon: string, competitionFeed: string) {
	return http.post(`payments/coupon`, { coupon, competitionFeed });
}

export function getPointsConfirmation(competitionFeed: string, seasonId: number) {
	return http.get(`points-confirmation`, { competitionFeed, seasonId });
}

export function uploadBadge(file: any, teamId: any) {
	const formData = new FormData();
	formData.append('teamId', teamId)
    formData.append('badge',file)
    const config = {'Content-Type': 'multipart/form-data'};
	
	return http.post(`team/badge`, formData, config);
}
