import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Card, Form } from 'antd';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import lockr from 'lockr';
import Media from 'react-media';

import * as userActions from '../../actions/user'
import * as teamsActions from '../../actions/teams';
import { StoreState, UserState, MatchesState, Match, ApplicationState } from './../../types';
import { Button, VrtLogin, InputPassword, Checkbox, Team, Title, TitleType, FormItem, RegisterForm, Row, Col, Block, FootballCalendar } from './../../components';
import config from './../../config';
import { CardStyle, HomeStyle } from "./HomeStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { sizes } from '../../styles/media-queries';
import moment from 'moment';
import Countdown from 'react-countdown-now';
import { Redirect } from "react-router";
import { notification } from 'antd';
import Login from "../Login/Login";

const { Panel } = Collapse;

export interface Props {
	user: UserState;
	match: any;
	matches: MatchesState;
	application: ApplicationState;
	onLogOut?: () => void;
	t: any;
}

export interface State {

}

const { Meta } = Card;

const WrappedRegisterForm = Form.create()(RegisterForm);

class Home extends Component<Props> {

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	render() {
		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;

		const momentDeadlineDate = deadlineDate ? moment(deadlineDate).valueOf() : moment().valueOf();
		const deadlineMatch = this.props.matches.data
			.find((match: Match) => !!(
				this.props.matches.info &&
				this.props.matches.info.deadlineWeek &&
				match.weekId === this.props.matches.info.deadlineWeek
			));
		const hasDeadlineWeek = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek;

		const deadlineRenderer = ({ days, hours, minutes, seconds }: any) => {
			return <H3 style={{ color: 'white' }}>
				Deadline speeldag {deadlineMatch ? deadlineMatch.weekId : ''}<br/>{days}d {hours}u {minutes}m {seconds}sec
			</H3>;
		};

		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];
		const redirectToPoints = this.props.match.path === '/' && team && deadlineMatch && team.weekId < deadlineMatch.weekId;

		return (
			<HomeStyle>
                {redirectToPoints && <Redirect to={{ pathname: `/points/${team.id}` }} />}
                <Row className='cover'>
                    <Col xs={24}>
                        <H1>Sporza EK Manager 2024</H1>
                        {hasDeadlineWeek && momentDeadlineDate ? (
                            <Countdown renderer={deadlineRenderer} date={momentDeadlineDate} />
                        ) : null}

                        {!this.props.user.data.id && (
                            <React.Fragment>
                                <span className="win">Stel jouw ideale elftal samen voor het EK voetbal</span>
                                <p style={{padding: "10px 0"}}>
                                    Klik op &quot;Aanmelden&quot; om jouw ploeg te maken of beheren.
                                </p>
                                <VrtLogin id="home" theme="c4" elemStyle={{ width: '9em', margin: '10px auto', fontSize: '18px' }} />
                            </React.Fragment>
                        )}

                        {this.props.user.data.id && !team ? (
                            
                        <div style={{margin: '20px 0'}}>
                            <Link to={`/new`}>
                                <Button style={{ fontSize: '18px' }}>Maak een ploeg</Button>
                            </Link>
                        </div>
                        ) : null}

                        {this.props.user.data.id && team ? (
                        <div style={{margin: '20px 0'}}>
                            <Link to={`/points/${team.id}`} style={{margin: '20px 0'}}>
                                <Button style={{ fontSize: '18px' }}>Naar jouw ploeg</Button>
                            </Link>
                        </div>
                        ) : null}
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="denk strategisch"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap1_ek24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Denk strategisch"
                                    description={
                                        'Selecteer 15 EK-spelers en één bondscoach. Stel voor elke speeldag het beste elftal op door te kijken naar de spelers in vorm en hun tegenstand. Maak een evenwichtig team met wisselmogelijkheden.'
                                    }
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="kies renners"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap2_ek24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Verdien punten"
                                    description="Na elke speeldag verdienen je 11 basisspelers punten. De kapitein vermenigvuldigt zijn punten met 1,5."
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="denk strategisch"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap3_ek24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Doe transfers"
                                    description={
                                        'Niet tevreden over één van je spelers? Gooi hem uit je selectie en transfereer een andere speler naar je team. Maar let op: elke speeldag krijg je maar 2 transfers. Gebruik je ze meteen of spaar je ze op?'
                                    }
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="kies renners"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap4_ek24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Speel mee en win"
                                    description="Daag je vrienden uit in een minicompetitie en verdien zo eeuwige roem."
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                </Row>
            </HomeStyle>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {
		onLogOut: () => dispatch(userActions.logOut()),
	}
}

export function mapStateToProps({ user, matches, application }: StoreState.All) {
	return {
		user,
		matches,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
