import styled from "styled-components";
import { Table } from "antd";

import mediaQueries from "../../styles/media-queries";
import { theme } from './../../styles/theme-sporza';

import "antd/lib/table/style";

// const tablePagination = `
// .ant-pagination {
// 	width: 100%;
// 	text-align: center;

// 	.ant-pagination-prev, .ant-pagination-next {
// 		a {
// 			border-radius: 0px;
// 		}

// 		&:hover:not(.ant-pagination-disabled) a {
// 			background-color: ${theme.primaryColor};
// 			border-color: ${theme.primaryColor};
// 			color: white;
// 		}
// 	}

// 	.ant-pagination-item {
// 		border-radius: 0px;

// 		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
// 			background-color: ${theme.primaryColor};
// 			border-color: ${theme.primaryColor};

// 			a {
// 				font-weight: bold;
// 				background-color: rgba(24, 88, 168, 0.05);
// 			}
// 		}
// 	}

// 	.ant-pagination-disabled {
// 		a {
// 			border-color: ${theme.primaryContrast};
// 			background: ${theme.primaryContrast};
// 			font-weight: bold;
// 			color: #ababab;
// 		}
// 	}
// }
// `;

const ContainerStyle = styled.div`
`;

// const BasicTableStyle = styled(Table)`
// 	// .ant-table {
// 	// 	color: #FFF;
// 	// }

// 	.ant-table-content {
// 		overflow-x: scroll;
// 	}

// 	.ant-table-thead {
// 		th {
// 			padding-top: 10px;
// 			padding-bottom: 10px;
// 			background-color: rgba(24, 88, 168, 0.05);
// 			color: 84FF00;
// 			padding: 7.5px;
// 			&:last-child {
// 				text-align: right;
// 			}
// 		}

// 		> tr { 
// 			&:first-child, :last-child { 
// 				> th {
// 					border: 0;
// 					&:first-child, :last-child {
// 						border-radius: 0;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.ant-table-tbody {
// 		.ant-table-row {
// 			background-color: ;
// 			> td {
// 				border: none;
// 				padding: 7.5px;
// 				&:last-child {
// 					text-align: right;
// 				}
// 			}

// 			&--odd, &:hover {
// 				background-color: rgba(24, 88, 168, 0.05);
// 			}
// 		}

// 		.cursor-pointer {
// 			cursor: pointer;
// 		}
// 	}

// 	${tablePagination}
// ` as any;

export {
	ContainerStyle,
	// BasicTableStyle
};