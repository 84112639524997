import React, { Component } from "react";
import { Row, Col } from 'antd';
import { Redirect } from "react-router-dom";
import lockr from 'lockr';
import { connect } from 'react-redux';
import { StoreState, UserState, ApplicationState } from './../../types';

export interface Props {
	location: any,
	match: any;
	user: UserState;
	application: ApplicationState;
}

class Join extends Component<Props> {
	state = {
		redirectToHome: false,
		redirectToNewGame: false,
		redirectToTeamsLeague: false,
		redirectTeamId: null
	};

	componentDidMount() {
		if (!this.props.user.authenticated) {
			this.setState({ redirectToHome: true });
		}
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.user.fetched !== prevProps.user.fetched) {
			if (this.props.match.params.code) {
				lockr.set('inviteCode', this.props.match.params.code);

				// const noLeaguesTeam = this.props.user.teams
				// 	.filter((team: any) => !team.leagues || !team.leagues.length)
				
				if (this.props.user.teams.length > 0) {
					const lastCreatedTeam = this.props.user.teams[this.props.user.teams.length - 1];
					this.setState({ redirectToTeamsLeague: true, redirectTeamId: lastCreatedTeam.id });
				} else {
					this.setState({ redirectToNewGame: true });
				}
			} else {
				this.setState({ redirectToHome: true });
			}
		}
	}

	render() {
		return (
			<Row>
				{this.state.redirectToHome && <Redirect
					to={{ pathname: `/home` }}
				/>}

				{this.state.redirectToNewGame && <Redirect
					to={{ pathname: `/new` }}
				/>}

				{this.state.redirectToTeamsLeague && <Redirect
					to={{ pathname: `/leagues` }}
				/>}
			</Row>
		);
	}
}

export const mapDispatchToProps = {};

export function mapStateToProps({ user, application }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Join);

