import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import { connect } from 'react-redux';
import { Block, Row, Col, Title, TitleType } from '../../components';
import * as userActions from '../../actions/user'
import { StoreState, ApplicationState } from './../../types';
import { RulesStyles } from './RulesStyles';

type RulesProps = {
	application: ApplicationState;

}

class Rules extends Component<RulesProps> {
	state = {
		text: ''
	}

	componentDidMount() {
		userActions.getPage(this.props.application.competition.competitionFeed, 'rules')
			.then((result: any) => {
				const body = result && result[0] && result[0].translations && result[0].translations[0] && result[0].translations[0].body;
				this.setState({ text: body || '' })
			});
	}

	render() {
		return (
			<RulesStyles>
				<Row>
					<Col lg={{ span: 20, offset: 2 }}>
						<Row gutter={[16, 16]}>
							<Col lg={{ span: 24 }}>
								<Block className="rules">
									<Title type={TitleType.h1}>Spelregels</Title>
									{ parseHTML(this.state.text) }
								</Block>
							</Col>
							{/* <Col lg={{ span: 8 }}>
								<Block>
										<a href='https://www.garmin.com/nl-BE/'><img alt="garmin_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_garmin.jpg'/></a>
										<a href='https://www.ridley-bikes.com/nl_BE'><img alt="ridley_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_ridley.jpg'/></a>
								</Block>
							</Col> */}
						</Row>
					</Col>
				</Row>
			</RulesStyles>
		);
	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Rules);
