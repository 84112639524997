import styled from "styled-components";
import { Table } from "antd";

import mediaQueries from "../../styles/media-queries";
import { theme } from './../../styles/theme-sporza';


import "antd/lib/table/style";

const ContainerStyle = styled.div`
	
`;

const SelectGroupStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;

	> div {
		margin: 5px 0;
	}

	@media ${mediaQueries.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;

		> div {
			margin: 0 5px;
		}
	}
`;

const tablePagination = `
// .ant-pagination-item {
// 	background: transparent;
// 	border: 0;
// 	a {
// 			color: #FFF;
// 	}
// }

// .ant-pagination-item-active {
// 	background: #FF286E;
// 	border-radius: 50px;
// 	color: #000;

// 	a { 
// 			color: #000;
// 	}
// }

// .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
// 	background: transparent;
// 	border: 0;
// }

// .ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a {
// 	color: #FF286E;
// }

// .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
// 	background: transparent;
// }

.ant-pagination {
	width: 100%;
	text-align: center;

	.ant-pagination-prev, .ant-pagination-next {
		a {
			background-color: transparent;
			border-color: transparent;
		
			font-weight: bold;
			color: ${theme.primaryColor};
		}

		&:hover:not(.ant-pagination-disabled) a {

		}
	}

	.ant-pagination-item {
		background-color: transparent;
		border-color: transparent;

		a {
			font-weight: bold;
			color: #FFF;
		}

		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};
			border-radius: 20px;

			a {
				color: #000;
			}
		}
	}

	.ant-pagination-disabled {
		a {
			background-color: transparent;
			border-color: transparent;
		
			font-weight: bold;
			color: #ababab;
		}
	}
}
`;

const TableStyle = styled(Table)`
	// background: #000;
	background: transparent;
	color: #fff;
	.ant-table {
			color: #FFF;
	}

	.ant-table-placeholder {
			color: #FFF;
			border: 0;
			border-radius: 10px;
			background: #3C3C3C !important;
			margin: 0 auto 10px;
	}

	.ant-table-thead {
			th {
				padding: 10px;
				border-bottom: 0;
				background-color: ${theme.primaryColor};
				color: #000;
			}

			> tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
				color: #000;
			}

			> tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
				background-color: #69CC00;
			}
	}

	.ant-table-tbody {
			.ant-table-row {
					> td {
							border: none;
							padding: 7.5px;

							&:first-child {
									padding: 2.5px 10px 0 15px;
							}
					}

					&--even {
							background-color: #3C3C3C;

							&:hover > td {
									background-color: #3C3C3C !important;
							}
					}

					&--odd {
							background-color: transparent;
							
							:hover > td {
									background-color: transparent !important;
							}
					}
			}

			.cursor-pointer {
					cursor: pointer;
			}
	}

	${tablePagination}	
` as any;

const BasicTableStyle = styled(Table)`
	border: 0;
	color: #FFF;

	.ant-table-content {
			overflow-x: scroll;
	}

	.ant-table-thead {
		th {
			padding: 10px;
			border-bottom: 0;
			background-color: ${theme.primaryColor};
			color: #000;
			&:last-child {
					text-align: right;
			}
		}
	}

	.ant-table-placeholder {
		color: #FFF;
		background: #3C3C3C;
		border: none;
		margin: 0 auto 10px;
	}

	.ant-table-tbody {
			.ant-table-row {
					> td {
							color: #FFF;
							border: none;
							padding: 7.5px 16px;
							&:last-child {
									text-align: right;
							}
					}

					&--odd {
							background-color: #3C3C3C;

							&:hover > td {
									background-color: #3C3C3C !important;
							}
					}

					// &--odd:hover > td {
					//     background-color: white !important;
					// }

			}

			.cursor-pointer {
					cursor: pointer;
			}
	}

	${tablePagination}
` as any;

const PlayerStyle = styled.div`
	${(props: any) =>
		props.type === "desktop" &&
		`
		display: none;
	`}
	
	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "desktop" &&
			`
			display: block;
		`}
	}

	.name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 150px;
	}

	.mobile-name {
		white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	max-width: 120px;
	}

	${(props: any) =>
		props.type === "mobile" &&
		`
		display: block;
	`}

	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "mobile" &&
			`
			display: none;
		`}
	}

	p {
		margin-bottom: 0;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			color: ${(props: any) => props.clubColor};

			span {
				${(props: any) =>
					props.position === "gk" &&
					`
					color: ${props.theme.positionGk};
				`}

				${(props: any) =>
					props.position === "df" &&
					`
					color: ${props.theme.positionDf};
				`}

				${(props: any) =>
					props.position === "mf" &&
					`
					color: ${props.theme.positionMf};
				`}

				${(props: any) =>
					props.position === "fw" &&
					`
					color: ${props.theme.positionFw};
				`}
			}
		}

	}
` as any;

export {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
	TableStyle,
	BasicTableStyle
};
