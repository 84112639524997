import React, { Component } from "react";
import { Form } from 'antd';
import { Row, Col, RegisterForm, Block, Title, TitleType } from '../../components';
import { RegisterStyle } from "./RegisterStyles";
import { PageStyle } from "../../styles/global";

export interface Props {
	t: any
}


class Register extends Component<Props> {
	render() {
		const { t } = this.props;
		const WrappedRegisterForm = Form.create()((props: any) => (<RegisterForm {...props} t={this.props.t} />));

		return (
			<Row style={{ padding: '40px' }}>
				<Col md={{ span: 10, offset: 7 }} sm={24} xs={24}>
					<Title type={TitleType.h2}>{t('user.registerPageTitle')}</Title>
					<Block>
						<div style={{ textAlign: 'center' }}>
							<WrappedRegisterForm />
						</div>
					</Block>
				</Col>
			</Row>
		);
	}
}

export default Register;
