import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from "react-router-dom";
import {Icon, Divider, Tooltip} from 'antd';

import * as userActions from '../../actions/user';
import { StoreState, ApplicationState, UserState, Coupon } from '../../types';
import {SidebarStyle} from './SidebarStyles';
import {staticPagesTitleMap} from './../Header/Header';

import { Row, Col, Payments, Button } from '..';

export interface Props {
	application: ApplicationState;
	user: UserState;
	match: any;
}

export interface State {

}

class Sidebar extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
		};
	};

	render() {
		const { user, match } = this.props;
		const teams = (user && user.teams) || [];
		const isActive = (match: string, exact?: boolean) => 
			exact ? location.pathname === match : location.pathname.indexOf(match) !== -1;

		return (
			<React.Fragment>
			<SidebarStyle>
					<Link 
						className={`${isActive(`${match.url}`, true) ? 'active-primary': null}`}
						to={`${match.url}`}>
							{staticPagesTitleMap['/my-teams']} 
					</Link>
					<Divider />				
					{
						teams.map((team: any) => {
								return <Link 
									className={`${isActive(`${match.url}/team/${team.id}`) ? 'active' : ''}`} 
									key={team.id} 
									to={`${match.url}/team/${team.id}`}>
										<span className="team-name"> 
											{
												team.paid ?
												<Tooltip title="Betaald">
													<span className="badge"> <Icon type="check-circle" /></span>
												</Tooltip> :
												<Tooltip title="Niet betaald">
												<span className="badge"> <Icon type="exclamation-circle" /></span>
												</Tooltip> 
											}
											{team.name} 
										</span> 
										{
											!isActive(`${match.url}/team/${team.id}`) ? 
												<Icon type="caret-right" /> :
												null
										}
								</Link>
						})
					}
					<Divider />				
					<Link 
						className={`${isActive(`${match.url}/payments`) ? 'active': null}`}
						to={`${match.url}/payments`}>
							<Icon type="credit-card" theme="filled" /> 
							{staticPagesTitleMap['/my-teams/payments']} 
							<Icon type="caret-right" />
					</Link>
				</SidebarStyle>
				<Button type="primary" style={{ marginTop: '20px', width: '100%' }}>
					<Link to="/new" type="primary"> {staticPagesTitleMap['/new']}</Link>
				</Button>
			</React.Fragment>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({ application, user }: StoreState.All) {
	return {
		application,
		user
	}
}

export default Sidebar;