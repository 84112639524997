import React, { Component } from "react";
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { StoreState, ClubsState, ApplicationState, UserState, PlayersState, Player, Club as ClubType } from '../../types';
import {
	Team, Substitutes, PlayerType, Row, Col, Block, Spinner, Title, TitleType
} from '../../components';
import { Link } from '../../components/UI/Link/Link';
import { theme } from '../../styles/theme-sporza';
import { groupBy, uniqBy } from 'lodash';

import teamBackground from './../../assets/img/bg-football.png';
import { startingListToPositionsList } from '../../lib/helpers'
import { TableStyle } from "../../components/PlayerList/PlayerListStyle";
import moment from 'moment';
import { Icon } from "antd";
import { redCardSvg } from '../../styles/custom-icons';
const RedCardIcon = (props: any) => <Icon component={redCardSvg} {...props} />;

const playerProps =
	['id', 'name', 'short', 'positionId', 'clubId', 'injury', 'form', 'forename', 'surname', 'points', 'pointsOverview', 'stats', 'squadDepth', 'fieldOrder', 'portraitUrl'];

export interface Props {
	match: any;
    clubs: ClubsState;
    players: PlayersState;
	user: UserState;
	application: ApplicationState;
	t: any;
}

export interface State {
	isFetching: boolean;
}

class Club extends Component<Props, State> {
	constructor(props: Props) {
        super(props);
        
        this.state = {
			isFetching: false
		};
    };

	render() {
        const { isFetching } = this.state;
        const { t } = this.props;
        const clubPlayers = this.props.players.data.filter((player: Player) => player && player.clubId == this.props.match.params.id);

        const startingPlayers = clubPlayers.filter((player: Player) => player && player.fieldOrder < 12 && player.clubId == this.props.match.params.id)
            .sort((p1: Player, p2: Player)=> p1.positionId - p2.positionId)
            .map((player: any) => {
                return pick({ ...player, ...{ points: 0, pointsOverview: 0 || {} } }, playerProps)
            });
            
        const homeStartingByPositions = startingListToPositionsList(startingPlayers, this.props.application.competition.lineupPositionRows, 'football');

        const club = this.props.clubs.data.find((item: ClubType) => item.id == this.props.match.params.id);
        const playerByPosition = groupBy(clubPlayers.sort((p1: Player, p2: Player)=> p1.squadDepth - p2.squadDepth), player => player.positionId);
        
        const positions = [
            { id: 0, name: this.props.t('player.coach') },
			{ id: 1, name: this.props.t('player.goalkeeper') },
			{ id: 2, name: this.props.t('player.defender') },
			{ id: 3, name: this.props.t('player.midfielder') },
			{ id: 4, name: this.props.t('player.attacker') },
		];

        const columns = [
			{
				title: '#',
				key: 'squadDepth',
				dataIndex: 'squadDepth',
				width: '10%',
				render: (text: any, player: any) => {
					return player.squadDepth
				}
			},
			{
				title: 'Speler',
				key: 'name',
				dataIndex: 'name',
				width: '70%',
				render: (text: string, player: any) => {                    
                    return <span>{player.name} { (player.injury === 1) ? " 🤕" : null } { (player.ban === 1) ? <RedCardIcon/> : null }</span>;
				}
            },
            {
				title: '$',
				key: 'value',
				dataIndex: 'value',
				width: '20%',
				render: (text: string, player: any) => {
					return player.value
				}
			}
        ];
        
		return <React.Fragment>
            {
				!club ?
					<Spinner /> : null
			}
			{
				club ?
            <Row>
                <Col md={16}>
                    <Title type={TitleType.h2}>{t('clubPage.expectedLineup')}</Title>
                    <Block>
                        <p style={{margin: "10px auto"}}>
                            <i>{t('clubPage.expectedLineupText')}:</i> {moment(club.updated).format('dddd DD/MM/YY')}
                        </p>
                        <Team
                            widthRatio={12}
                            heightRatio={10}
                            clubs={this.props.clubs.data}
                            bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg_ek24.jpg'}
                            assetsCdn={this.props.application.competition.assetsCdn}
                            showPlayerStatsPoints={false}
                            selection={homeStartingByPositions}
                            centerAligned={true}
                            playerType={PlayerType.SoccerShirt}
                            modalEnabled={true}
                            captainId={undefined}
                            playerBadgeColor="#000"
                            playerBadgeBgColor={theme.primaryColor}
                            playerPointsColor={"#000"}
                            playerPointsBgColor="#84FF00"
                            t={this.props.t} />
                    </Block>
                </Col>
                <Col md={8}>
                    <Title type={TitleType.h2}>{t('clubPage.depthChart')}</Title>
                    <Block>
                        <p style={{margin: "10px auto"}}>{t('clubPage.depthChartText')}</p>
                    { 
                        Object.keys(playerByPosition).map((position: any, key: number) => {
                            return <div key={`game-day-${key}`} className={`game-day day-${key}`}>
                                <Title type={TitleType.h3}>{positions[key].name}</Title>
                                <Block>
                                    <TableStyle
                                        columns={columns}
                                        dataSource={playerByPosition[key]}
                                        showHeader={true}
                                        locale={{ emptyText: 'No players found.' }}
                                        pagination={false}
                                        rowKey={(record: any, index: number) => `record-${index}`}
                                        rowClassName={(record: object, index: number) =>
                                            index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
                                        }
                                    />
                                </Block>
                            </div>
                        })
                    }
                    </Block>
                </Col>
            </Row>
            : null
        }
		</React.Fragment>;
	}
}

export function mapDispatchToProps() {
	return {}
}

export function mapStateToProps({ application, clubs, user, players }: StoreState.All) {
	return {
		clubs,
		application,
        user,
        players
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Club);