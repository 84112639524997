import styled from 'styled-components';

const MatchDaySelectorStyle = styled.div`
    position: relative;
    text-align: center;
    padding: 5px 0;

    background-color: ${props => props.theme.primaryColor};
    border-radius: 100px;
    color: #000;

    display: flex;
    align-items: center;
    justify-content: space-between;

    -webkit-user-select: none;     
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`;

const ButtonStyle = styled.a`
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url(/assets/img/arrow.svg) center center no-repeat;
    background-size: 20px 20px;
		margin: 0 0 0 5px;
    
    &.disabled {
    	cursor: not-allowed;
		}
		
    ${({ type }) => type === 'next' && `
        transform: rotate(180deg);
        margin: 0 5px 0 0;
    `}
`;

export {
	MatchDaySelectorStyle,
	ButtonStyle
};