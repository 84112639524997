import styled from "styled-components";
import {Alert} from 'antd';

import 'antd/lib/alert/style';

export const AlertStyle = styled(Alert)`

	&.ant-alert-info {
		background-color: #2E2F2D;
		color: #9FA8AD;
		border: 1px solid #3C3C3C;

		.ant-alert-message {
			color: #FFF;
		}
	
		.ant-alert-icon {

		}
	}
`;