import React, { Component } from "react";
import { MatchDaySelectorStyle, ButtonStyle } from "./MatchDaySelectorStyle";

export interface Props {
	day: number;
	name: string;
	min?: number;
	max?: number;
	onNext: any;
	onPrev: any;
	t: any;
}

export default class MatchDaySelector extends Component<Props> {
	render() {
		const { t } = this.props;
		const disablePrevious = this.props.min && this.props.day === this.props.min;
		const disableNext = this.props.max && this.props.day === this.props.max;
		return (
			<MatchDaySelectorStyle>
				<ButtonStyle
					type="previous"
					className={disablePrevious ? 'disabled' : ''}
					onClick={disablePrevious ? () => ({}) : this.props.onPrev}>
				</ButtonStyle>
				{t('general.footballWeek')} {this.props.day} {this.props.name ? `- ${this.props.name}` : ''}
				<ButtonStyle
					type="next"
					className={disableNext ? 'disabled' : ''}
					onClick={disableNext ? () => ({}) : this.props.onNext}>
				</ButtonStyle>
			</MatchDaySelectorStyle>
		);
	}
};
