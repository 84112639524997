import styled from 'styled-components';

const Overview = styled.div`
    align-items: center;
		padding-bottom:15px;

		.header {
			background-color: #F0F3F8;
			padding: 5px;

			.ant-col {
				text-align: center;
			}
		}
`;

const Value = styled.span`
	font-weight: bold;
`;

const Label = styled.span`
`;

export {
    Overview,
    Value,
	Label
};
