import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { StoreState, MatchesState, ApplicationState } from './../../types';
import { FootballCalendar, Row, Col, TitleType, Title, Block, Link } from './../../components';
import { PageStyle } from "../../styles/global";
import { News } from './DeadlinesStyles';
import { TableStyle } from './../../components/PlayerList/PlayerListStyle';
import * as matchesActions from '../../actions/matches'
import { Element, scroller } from 'react-scroll'
import parseHTML from 'html-react-parser';
import { Divider } from 'antd';


export interface Props {
	matches: MatchesState;
	application: ApplicationState;
	t: any;
}

export interface State {
	selectedWeekMatches: any;
	selectedWeek: any;
	rssFeeds: any[];
}

class Deadlines extends Component<Props, State> {

	constructor(props: any) {
		super(props);

		this.state = {
			selectedWeekMatches: [],
			selectedWeek: null,
			rssFeeds: []
		}
	}

	componentDidMount() {
		if (this.props.matches.info.displayWeek) {
			const week = this.props.matches.weeks.find(week => week.weekId === this.props.matches.info.displayWeek);
			this.getMatches(week);
		}
		matchesActions.getRssFeeds(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId)
			.then((result: any) => {
				const rssFeeds = (result && result.rss && result.rss.channel && result.rss.channel.item) || [];
				this.setState({ rssFeeds });
			})
			.catch((error:any) => {
				console.log(error)
			})
	}

	onWeekClick = (week: any) => {
		this.getMatches(week);
	};

	componentDidUpdate(prevProps: Props) {
		if ((this.props.matches.info.displayWeek !== prevProps.matches.info.displayWeek)) {
			const week = this.props.matches.weeks.find(week => week.weekId === this.props.matches.info.displayWeek);
			this.getMatches(week);
		}
	}

	getMatches(week: any) {
		matchesActions.fetchWeekMatches(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			week.weekId
		)
			.then((selectedWeekMatches: any) => {
				this.setState({ selectedWeekMatches: selectedWeekMatches.matches, selectedWeek: week });

				scroller.scrollTo('week-matches', {
					duration: 1000,
					delay: 100,
					smooth: true,
					offset: 0
				})
			});
	}

	render() {
		const { matches, t } = this.props;
		const { selectedWeekMatches, selectedWeek, rssFeeds } = this.state;
		const columns = [
			{
				title: 'Speeldag',
				key: 'weekId',
				dataIndex: 'weekId',
				width: '50%',
				render: (text: string, record: any) => {
					return <span>{text}</span>;
				}
			},
			{
				title: 'Deadline',
				key: 'deadlineDate',
				width: '50%',
				dataIndex: 'deadlineDate',
				render: (text: string, record: any) => {
					return <b>{moment(text).format('ddd DD/MM [om] HH:mm')}</b>
				}
			}
		];

		const tableEventHandler = (match: any) => ({
			onClick: (event: any) => {
				this.onWeekClick(match);
			},
		});

		return (
			<React.Fragment>
				<PageStyle>
					<Row>

						<Col md={12} sm={24}>
							<Block>
								<Title type={TitleType.h3}>WEDSTRIJDEN</Title>

								<TableStyle
									columns={columns}
									dataSource={matches && matches.weeks && matches.weeks.length ? matches.weeks : []}
									showHeader={true}
									locale={{ emptyText: 'No game weeks found.' }}
									loading={matches.isFetching}
									pagination={false}
									rowKey={(record: any, index: number) => `record-${index + 1}`}
									onRow={tableEventHandler}
									rowClassName={(record: any, index: number) => `cursor-pointer ${record && selectedWeek && record.weekId === selectedWeek.weekId ? 'selected-item' : ''} ${index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}`}
								/>
							</Block>
						</Col>
						{
							selectedWeekMatches && selectedWeekMatches.length ?
								<Element name="week-matches">
									<Col md={12} sm={24}>
										<Block>
											<Title type={TitleType.h3}>{`Speeldag ${selectedWeek && selectedWeek.weekId ? selectedWeek.weekId : ''}`}</Title>

											<FootballCalendar
												data={selectedWeekMatches}
												size={30}
												t={t}
												weekId={selectedWeek.weekId}
												showHeader={false}
												isLoading={matches.isFetching} />
										</Block>

										{
											rssFeeds.length ?
												<Block style={{ marginTop: '20px' }}>
													<Title type={TitleType.h3}>NIEUWS</Title>
													<News>
														{
															rssFeeds.map((rssFeed: any, index: number) => {
																const description = rssFeed.description ?
																	parseHTML(rssFeed.description)
																		.toString()
																		.replace('<P>', '')
																		.replace('</P>', '') : '';

																return <div className="feed" key={`feed-${index + 1}`}>
																	<h5 className="title">{rssFeed.title}</h5>
																	{
																		rssFeed.enclosure && rssFeed.enclosure['@_url'] ?
																			<div className="image-container"> <img src={rssFeed.enclosure['@_url']} />  </div> :
																			null
																	}
																	<p className="description">{description}</p>
																	<p className="more">
																		<Link target="_blank" type={'external'} href={rssFeed.link}>Lees meer...</Link>
																	</p>
																	<Divider />
																</div>
															})
														}
													</News>
												</Block>
												: null
										}
									</Col>
								</Element> : null
						}
					</Row>
				</PageStyle>
			</React.Fragment>
		);
	}
}

export function mapStateToProps({ user, matches, application }: StoreState.All) {
	return {
		matches,
		application
	}
}

export default connect(mapStateToProps, {})(Deadlines);
