import React, { Component } from "react";
import { Icon, notification } from 'antd';
import moment from 'moment';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user'
import * as teamsActions from '../../actions/teams';
import { Row, Col, Block, Title, TitleType, Button, PricingTable } from '../../components';

import { StoreState, UserState, ApplicationState } from '../../types';

interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
	t: any;
}

export interface State {
	newPaymentResult: any,
	isCreatingAPayment: boolean,
}

class Shop extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			newPaymentResult: null,
			isCreatingAPayment: false
		};
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if ((this.state.newPaymentResult !== prevState.newPaymentResult) && this.state.newPaymentResult.provider.mollie) {
			window.location.href = this.state.newPaymentResult.provider.mollie._links.checkout.href;
		}
    }
    
    initiatePayment = () => {
		const redirectURL = `https://${window.location.hostname}/thanks`;

        this.setState({ isCreatingAPayment: true });
        
		teamsActions.createPayment(
			[],
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			[0],
            redirectURL
		)
			.then((paymentResult: any) => {
				if (paymentResult && paymentResult.order && paymentResult.order.amount && (window as any).fbq) {
					(window as any).fbq('track', 'Purchase', { value: (paymentResult.order.amount / 100), currency: 'EUR' });
				}
				this.setState({ newPaymentResult: paymentResult, isCreatingAPayment: false });
			})
			.catch((error:any) => {
				console.log(error);
				notification.warning({ message: 'Betaling mislukt. Contacteer ons via de chat.' });
			});
	};

	render() {
		const { isCreatingAPayment } = this.state;
		const { user, t } = this.props;

		return (
			<React.Fragment>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>{t('shop.title')}</Title>
							<Row className="onboarding">
								<Col md={24} style={{padding: "40px 40px 0"}}>
									<p style={{marginBottom: "0px"}}>Als fans willen we niet dat Fantasy voor de Belgische Eerste klasse verdwijnt. Daarom is Fan Arena Fantasy gratis te spelen. Voor de fans die ons en de game dit seizoen willen steunen hebben we een Premium samengesteld. Daarin bieden we extra features om je voetbalbeleving dat extraatje te geven.</p>
								</Col>
							</Row>

							<PricingTable></PricingTable>
							{(user.data.role !== 'premium') ? 
								<div style={{textAlign: "center", padding: "10px 50px 50px"}}>
									<Button
										onClick={this.initiatePayment}
										loading={isCreatingAPayment}
										type="primary"
										size="default"
										style={{ margin: '20px auto 40px', width: '220px' }}>
										<Icon type="credit-card" theme="filled" />
										{t('shop.buy')}
									</Button>
									<h4 style={{color: "#FFF"}}>{t('shop.premiumPricing')} $9,90</h4>
									<h5 style={{color: "#FFF"}}>{t('shop.premiumTagline')}</h5>
								</div>
								: <div style={{textAlign: "center", padding: "10px 50px 50px"}}><h4 style={{color: "#FFF"}}>{t('shop.premiumMemberMessage')}</h4></div>
							}
						</Block>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);