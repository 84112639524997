import React, { Component } from "react";
import { connect } from 'react-redux';
import {
	StoreState, MatchesState, ApplicationState, PlayersState, ClubsState, Player, UserState,
	Week, Transfer, Boosters
} from './../../types';
import { notification } from 'antd';
import lockr from 'lockr';
// @ts-ignore
import FootballLineupValidator from '../../common/pick-football-players';
import { Decimal } from 'decimal.js';
import moment from 'moment';

import * as teamsActions from '../../actions/teams'
import * as userActions from '../../actions/user';
import * as matchesActions from '../../actions/matches'
import { roundNextHalf } from './../../lib/helpers';
import { pick } from 'lodash';
import { FootballMaxPositionsPicks, FootballPositionIds } from './../../lib/constants';

export interface Props {
	matches: MatchesState;
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
	user: UserState;
	create: any;
	updateTeamName: any;
	fetchMatches: any;
	fetchUserTeamsAndLeagues: any;
}

export interface State {
	starting: any[],
	bench: any[],
	leagues: any[],
	budget: number,
	captainId: number | undefined,
	viceCaptainId: number | undefined,
	visibleWeekId: number | null,
	teamName: string,
	teamNameInitial: string,
	teamNameChanged: boolean,
	swapPlayerId: number | null,
	swapPlayer: Player | null,
	swappedFrom: string | null,
	initializedExternally: boolean,
	savingTeamPending: boolean,
	teamPointsInfo: any,
	deadlineWeekTransfers: Transfer[],
	pastTransfers: Transfer[],
	draftTransfers: Transfer[],
	initialStarting: any[],
	initialBench: any[],
	initialBudget: number,
	cacheChanges: boolean,
	type: string,
	useFootballValidatorOnPick: boolean,
	footballValidator?: any;
	activePositionFilter: number;
	boosters: Boosters;
	isTeamOwner: boolean;
	hasActiveMatches: boolean;
	teamUser?: any;
	nameVisible: boolean;
}

export interface Options {
	type?: string;
	mode?: string;
	useFootballValidatorOnPick?: boolean
}

function playersToValidatorFormat(players: any) {
	return players
		.filter((player: any) => player && player.id)
		.map((player: any) => ({ Player: { id: player.id, 'position_id': player.positionId } }));
}

const defaultLineup = [
	{ id: null, positionId: 0 },
	{ id: null, positionId: 1 },
	{ id: null, positionId: 2 }, { id: null, positionId: 2 }, { id: null, positionId: 2 }, { id: null, positionId: 2 },
	{ id: null, positionId: 3 }, { id: null, positionId: 3 }, { id: null, positionId: 3 }, { id: null, positionId: 3 },
	{ id: null, positionId: 4 }, { id: null, positionId: 4 }
];

const defaultBench = [
	{ id: null, positionId: 1 },
	{ id: null, positionId: 2 },
	{ id: null, positionId: 3 },
	{ id: null, positionId: 4 }
];

const withAbstractTeam = (WrappedComponent: any, options?: Options) => {

	class AbstractTeam extends Component<Props, State> {
		constructor(props: Props) {
			super(props);

			const getInitializedList = (size: number, forStarting?: boolean) => {

				if (size && options && options.type === 'football') {
					return forStarting ? [].concat(defaultLineup as any) : [].concat(defaultBench as any);
				} else {
					const list = [];
					for (let item = 0; item < size; item++) {
						list.push(null);
					}
					return list;
				}

			};


			this.state = {
				starting: getInitializedList(props.application.competition.lineupSize, true),
				bench: getInitializedList(props.application.competition.benchSize),
				budget: props.application.competition.budget,
				visibleWeekId: options && options.mode === 'points' ? this.props.matches.info.displayWeek : this.props.matches.info.deadlineWeek,
				captainId: undefined,
				viceCaptainId: undefined,
				teamName: '',
				teamNameInitial: '',
				teamNameChanged: false,
				swapPlayerId: null,
				swapPlayer: null,
				swappedFrom: null,
				initializedExternally: false,
				savingTeamPending: false,
				leagues: this.props.user.leagues,
				deadlineWeekTransfers: [],
				pastTransfers: [],
				draftTransfers: [],
				boosters: {},
				isTeamOwner: false,
				teamPointsInfo: {
					generalPoints: null,
					generalRank: null,
					visibleWeekPoints: null,
					visibleWeekRank: null,
					weekPointsConfirmed: false,
					provisionalPoints: null,
					weekWinnerPoints: null
				},
				initialStarting: getInitializedList(props.application.competition.lineupSize, true),
				initialBench: getInitializedList(props.application.competition.benchSize),
				initialBudget: props.application.competition.budget,
				cacheChanges: false,
				type: (options && options.type) || 'cycling',
				useFootballValidatorOnPick: !!(options && options.useFootballValidatorOnPick),
				footballValidator: (options && options.type === 'football') ? FootballLineupValidator.default(FootballMaxPositionsPicks, FootballPositionIds) : null,
				activePositionFilter: -1,
				hasActiveMatches: !!(this.props.matches.data.filter((m) => {
					if(m.weekId === this.props.matches.info.deadlineWeek && moment(m.date).isAfter(moment.utc())) { return m }
				}).length > 0),
				teamUser: undefined,
				nameVisible: false,
			};
		};

		setStarting = (starting: any[]) => {
			this.setState({ starting });
		};

		setBench = (bench: any[]) => {
			this.setState({ bench });
		};

		setTeamName = (teamName: string) => {
			this.setState({ teamName });
		};

		resetTeamName = () => {
			this.setState({ teamName: this.state.teamNameInitial, teamNameChanged: false });
		};

		updateTeamName = (teamId: number) => {
			this.setState({ teamNameChanged: false, teamNameInitial: this.state.teamName })

			this.props.updateTeamName(teamId, this.state.teamName);
		};

		componentDidUpdate(prevProps: Props, prevState: State) {
			if (this.props.matches.info.deadlineWeek !== prevProps.matches.info.deadlineWeek) {
				this.setState({
					visibleWeekId: options && options.mode === 'points' ? this.props.matches.info.displayWeek : this.props.matches.info.deadlineWeek
				});
			}

			if (this.props.user.leagues.length !== prevProps.user.leagues.length) {
				this.setState({ leagues: this.props.user.leagues });
			}

			if(this.state.visibleWeekId !== prevState.visibleWeekId || this.props.matches.data !== prevProps.matches.data) {
				this.setState({
					hasActiveMatches: !!(this.props.matches.data.filter((m) => {
						if(m.weekId === this.state.visibleWeekId && moment(m.date).isAfter(moment.utc())) { return m }
					}).length > 0)
				})
			}
		}

		setCaptainId = (captainId: number) => {
			this.setState({ captainId });
		};

		activateCacheChanges = () => {
			this.setState({ cacheChanges: true });
		};

		setBudget = (budget: number) => {
			this.setState({ budget });
		};

		initTeamState = (
			starting: any[],
			bench: any[],
			teamName: string,
			captainId: number,
			budget: number,
			leagues?: any[] | undefined,
			visibleWeekId?: number | undefined,
			teamPointsInfo?: any,
			rawTransfers?: any[] | undefined,
			deadlineWeekTransfers?: any[] | undefined,
			pastTransfers?: any[] | undefined,
			viceCaptainId?: number,
			boosters?: Boosters,
			isTeamOwner?: boolean | undefined,
			teamUser?: any,
			nameVisible?: boolean,
		) => {

			const startingPlayersValidatorFormat = playersToValidatorFormat(starting);
			const benchPlayersValidatorFormat = playersToValidatorFormat(bench);

			if (this.state.type === 'football') {
				this.state.footballValidator.set(startingPlayersValidatorFormat, benchPlayersValidatorFormat);
			}

			console.log("SET TRUE INTITALISZe");

			this.setState({
				starting,
				bench,
				teamName,
				captainId,
				viceCaptainId,
				budget,
				teamNameInitial: teamName,
				initializedExternally: true,
				leagues: leagues || this.props.user.leagues,
				visibleWeekId: visibleWeekId || this.state.visibleWeekId,
				teamPointsInfo: teamPointsInfo || this.state.teamPointsInfo,
				deadlineWeekTransfers: deadlineWeekTransfers || [],
				pastTransfers: pastTransfers || [],
				draftTransfers: [],
				initialBench: bench,
				initialStarting: starting,
				initialBudget: budget,
				boosters: boosters || {},
				isTeamOwner: isTeamOwner || false,
				hasActiveMatches: !!(this.props.matches.data.filter((m) => {
					if(m.weekId === this.state.visibleWeekId && moment(m.date).isAfter(moment.utc())) { return m }
				}).length > 0),
				teamUser: teamUser || null,
				nameVisible: nameVisible || false,
			});
		};

		pickPlayer = (player: Player, taxForPicking?: boolean) => {
			const emptyPlayer = null;

			const wasPreviouslyInStartingOrBench: any = []
				.concat(this.state.initialStarting as any, this.state.initialBench as any)
				.find((item: Player) => item && player && item.id === player.id);

			let startingHasEmptySpot = this.state.starting
				.find(teamPlayer => !teamPlayer) === emptyPlayer;

			let benchHasEmptySpot = this.state.bench
				.find(teamPlayer => !teamPlayer) === emptyPlayer;

			let playerValue = !taxForPicking ?
				player.value :
				roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100));

			if (wasPreviouslyInStartingOrBench) {
				playerValue = wasPreviouslyInStartingOrBench.value;
			}

			if (this.state.type === 'football' && this.state.useFootballValidatorOnPick) {
				const pickResult = this.state.footballValidator.pick({ Player: { id: player.id, 'position_id': player.positionId } });
				if (pickResult.result.Bench.includes(player.id)) {
					startingHasEmptySpot = false;
					benchHasEmptySpot = true;
				} else {
					startingHasEmptySpot = true;
					benchHasEmptySpot = false;
				}
			}

			if (this.state.type === 'football' && !this.state.useFootballValidatorOnPick) {
				startingHasEmptySpot = this.state.starting
					.find(teamPlayer => (teamPlayer && !teamPlayer.id) && (teamPlayer && teamPlayer.positionId === player.positionId));

				benchHasEmptySpot = this.state.bench
					.find(benchPlayer => (benchPlayer && !benchPlayer.id) && (benchPlayer && benchPlayer.positionId === player.positionId));
			}

			if (startingHasEmptySpot) {
				let firstEmptySpotIndexInStarting: any = null;
				this.state.starting
					.forEach((teamPlayer: any, index: number) => {
						if (!firstEmptySpotIndexInStarting && (!teamPlayer || (teamPlayer && !teamPlayer.id && teamPlayer.positionId === player.positionId))) {
							firstEmptySpotIndexInStarting = index
						}
					});

				const starting = this.state.starting.map((teamPlayer: Player | null, playerIndex: number) => {
					if (playerIndex === firstEmptySpotIndexInStarting) {
						return !wasPreviouslyInStartingOrBench ? player : wasPreviouslyInStartingOrBench;
					} else {
						return teamPlayer;
					}
				});

				const budget = parseFloat(new Decimal(this.state.budget.toFixed(2))
					.minus(playerValue.toFixed(2))
					.toString());

				if (this.state.cacheChanges) {
					lockr.set('cachedNewTeamChanges', {
						starting,
						budget,
						bench: this.state.bench,
						captainId: this.state.captainId,
						teamName: this.state.teamName
					});
				}

				if(!this.state.captainId && player.positionId !== 0) {
					this.setState({ captainId: player.id})
				} else if(!this.state.viceCaptainId && player.positionId !== 0) {
					this.setState({ viceCaptainId: player.id})
				}

				this.setState({ starting, budget });

			} else if (benchHasEmptySpot) {
				let firstEmptySpotIndexInBench: any = null

				this.state.bench
					.forEach((teamPlayer: any, index: number) => {
						if (!firstEmptySpotIndexInBench && (!teamPlayer || (teamPlayer && !teamPlayer.id && teamPlayer.positionId === player.positionId))) {
							firstEmptySpotIndexInBench = index
						}
					});

				const bench = this.state.bench.map((teamPlayer: Player | null, playerIndex: number) => {
					if (playerIndex === firstEmptySpotIndexInBench) {
						return !wasPreviouslyInStartingOrBench ? player : wasPreviouslyInStartingOrBench;
					} else {
						return teamPlayer;
					}
				});

				const budget = parseFloat(new Decimal(this.state.budget.toFixed(2))
					.minus(playerValue.toFixed(2))
					.toString());

				if (this.state.cacheChanges) {
					lockr.set('cachedNewTeamChanges', {
						starting: this.state.starting,
						budget,
						bench,
						captainId: this.state.captainId,
						teamName: this.state.teamName
					})
				}

				this.setState({ bench, budget });
			}
		};

		removePlayer = (player: Player) => {
			const isInStarting = this.state.starting.find((startingPlayer: Player | null) => !!(startingPlayer && startingPlayer.id === player.id));
			const isInBench = this.state.bench.find((benchPlayer: Player | null) => !!(benchPlayer && benchPlayer.id === player.id));

			if (isInStarting) {
				this.removeStartingPlayer(player);
			}

			if (isInBench) {
				this.removeBenchPlayer(player);
			}
		}

		removeStartingPlayer = (player: Player) => {
			const newState = this.state.starting
				.map((startingPlayer: Player | null) => {
					if (startingPlayer && startingPlayer.id && startingPlayer.id === player.id) {
						return { id: null, positionId: player.positionId };
					} else {
						return startingPlayer;
					}
				});
			const budget = parseFloat(new Decimal(this.state.budget.toFixed(2))
				.plus(player.value.toFixed(2))
				.toString());

			const captainId = this.state.captainId === player.id ? undefined : this.state.captainId;
			const viceCaptainId = this.state.viceCaptainId === player.id ? undefined : this.state.viceCaptainId;

			if (this.state.type === 'football') {
				const removeResult = this.state.footballValidator.remove({ Player: { id: player.id, 'position_id': player.positionId } });
			}

			if (this.state.cacheChanges) {
				lockr.set('cachedNewTeamChanges', {
					starting: newState,
					budget,
					bench: this.state.bench,
					captainId: this.state.captainId,
					viceCaptainId: this.state.viceCaptainId,
					teamName: this.state.teamName
				});
			}

			this.setState({ starting: newState, budget, captainId, viceCaptainId });
		};

		removeBenchPlayer = (player: Player) => {
			const newState = this.state.bench
				.map((benchPlayer: Player | null) => {
					if (benchPlayer && benchPlayer.id && benchPlayer.id === player.id) {
						return { id: null, positionId: player.positionId };
					} else {
						return benchPlayer;
					}
				});

			const budget = parseFloat(new Decimal(this.state.budget.toFixed(2))
				.plus(player.value.toFixed(2))
				.toString());

			if (this.state.type === 'football') {
				const removeResult = this.state.footballValidator.remove({ Player: { id: player.id, 'position_id': player.positionId } });
			}

			if (this.state.cacheChanges) {
				lockr.set('cachedNewTeamChanges', {
					starting: this.state.starting,
					budget,
					bench: newState,
					captainId: this.state.captainId,
					teamName: this.state.teamName
				});
			}

			this.setState({ bench: newState, budget })
		};

		onCaptainSelect = (player: Player, captainShouldBeTheFirstInTheList?: boolean) => {
			const playerId: number = player.id;
			let playerIndex: number | null = null;

			this.state.starting
				.forEach((startingPLayer: Player | null, index: number) => {
					if (startingPLayer && startingPLayer.id === playerId) {
						playerIndex = index;
					}
				});

			const currentCaptain = this.state.starting
				.find((startingPLayer: any, index: number) => index === 0);

			const nextCaptain = this.state.starting
				.find((startingPLayer: any, index: number) => startingPLayer && startingPLayer.id === playerId);

			if (playerIndex !== 0 && captainShouldBeTheFirstInTheList) {
				const starting = this.state.starting
					.map((startingPLayer: any, index: number) => {
						if (index === 0) {
							return nextCaptain;
						} else if (index === playerIndex) {
							return currentCaptain;
						} else {
							return startingPLayer;
						}
					});
				this.setState({ captainId: playerId, starting });
			} else {
				this.setState({ captainId: playerId, viceCaptainId: (this.state.viceCaptainId === playerId ? undefined : this.state.viceCaptainId) });
			}
		};

		onViceCaptainSelect = (player: Player) => {
			const playerId: number = player.id;
			this.setState({ viceCaptainId: playerId, captainId: (this.state.captainId === playerId ? undefined : this.state.captainId) });
		};

		onTeamNameChange = (e: any) => {
			this.setState({ teamName: e.target.value, teamNameChanged: e.target.value !== this.state.teamNameInitial });
		};

		onNameVisible = (e: any) => {
			this.setState({nameVisible: false});
		};

		onNameVisibleChange = (e: any) => {
			this.setState({ nameVisible: e.target.checked });
		};

		validateTeam = (showNotifications?: boolean) => {
			let valid = true;

			const hasCaptain = !!this.state.captainId;
			const hasViceCaptain = !!this.state.viceCaptainId;

			const allStartingPicked = this.state.starting
				.filter((player: any) => player && player.id)
				.length === this.props.application.competition.lineupSize;

			const allBenchPicked = this.state.bench
				.filter((player: any) => player && player.id)
				.length === this.props.application.competition.benchSize;

			const teamNameValid = this.state.teamName.length > 2;

			if (!hasCaptain) {
				if (showNotifications) {
					notification.warning({ message: 'Kies een kapitein door op een speler te drukken.' });
				}
				valid = false;
			}

			if (!hasViceCaptain) {
				if (showNotifications) {
					notification.warning({ message: 'Kies een vice-kapitein door op een speler te drukken.' });
				}
				valid = false;
			}

			if (!allStartingPicked || !allBenchPicked) {
				if (showNotifications) {
					notification.warning({ message: 'Je hebt niet genoeg spelers gekozen.' });
				}
				valid = false;
			}

			if (!teamNameValid) {
				if (showNotifications) {
					notification.warning({ message: 'Jouw teamnaam is te kort.' });
				}
				valid = false;
			}
			return valid;
		};

		getStartingAndBenchIdsWithCaptainCheck() {
			let starting: any[] = [].concat(this.state.starting as any);
			let bench: any[] = [].concat(this.state.bench as any);

			let captainInBench = bench.find((benchPlayer: any, index: number) => benchPlayer.id === this.state.captainId);
			let viceCaptainInBench = bench.find((benchPlayer: any, index: number) => benchPlayer.id === this.state.viceCaptainId);

			if (captainInBench) {
				const possibleStartingPlayerReplacement: any[] = starting
					.filter((startingPlayer: any) => startingPlayer.positionId === captainInBench.positionId && startingPlayer.id !== this.state.viceCaptainId);
				const replacementPlayer = possibleStartingPlayerReplacement[possibleStartingPlayerReplacement.length - 1];
				starting = starting
					.map((startingPlayer: any, index: number) => replacementPlayer && startingPlayer.id === replacementPlayer.id ? captainInBench : startingPlayer);
				bench = bench
					.map((benchPlayer: any, index: number) => captainInBench && benchPlayer.id === captainInBench.id ? replacementPlayer : benchPlayer);
			}

			if (viceCaptainInBench) {
				const possibleStartingPlayerReplacement: any[] = starting
					.filter((startingPlayer: any) => startingPlayer.positionId === viceCaptainInBench.positionId && startingPlayer.id !== this.state.captainId);
				const replacementPlayer = possibleStartingPlayerReplacement[possibleStartingPlayerReplacement.length - 1];
				starting = starting
					.map((startingPlayer: any, index: number) => replacementPlayer && startingPlayer.id === replacementPlayer.id ? viceCaptainInBench : startingPlayer);
				bench = bench
					.map((benchPlayer: any, index: number) => viceCaptainInBench && benchPlayer.id === viceCaptainInBench.id ? replacementPlayer : benchPlayer);
			}

			return { starting, bench };
		}

		onTeamSave = (e: any) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const { starting, bench } = this.getStartingAndBenchIdsWithCaptainCheck();

				let startingIds = starting.map(player => player && player.id);
				let benchIds = bench.map(player => player && player.id);

				this.setState({ savingTeamPending: true });

				if (this.state.cacheChanges) {
					lockr.rm('cachedNewTeamChanges');
				}

				return this.props.create(
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId,
					this.state.viceCaptainId,
					this.state.nameVisible
				);
			} else {
				return Promise.reject('Invalid team');
			}
		};

		onTeamReset = (team: any) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const { starting, bench } = this.getStartingAndBenchIdsWithCaptainCheck();

				let startingIds = starting.map(player => player && player.id);
				let benchIds = bench.map(player => player && player.id);

				this.setState({ savingTeamPending: true });

				if (this.state.cacheChanges) {
					lockr.rm('cachedNewTeamChanges');
				}

				return teamsActions.edit(
					team.id,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId,
					this.state.viceCaptainId,
					this.state.nameVisible
				);
			} else {
				return Promise.reject('Invalid team');
			}
		};

		onTeamEdit = (teamId: number) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const { starting, bench } = this.getStartingAndBenchIdsWithCaptainCheck();

				const startingIds = starting.map((player: any) => player.id);
				const benchIds = bench.map((player: any) => player.id);

				return teamsActions.edit(
					teamId,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId,
					this.state.viceCaptainId,
					this.state.nameVisible
				)
			} else {
				return Promise.reject(isValid);
			}
		};

		onTeamSelectionsUpdate = (teamId: number, weekId: number) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const startingIds = this.state.starting.map((player: any) => player.id);
				const benchIds = this.state.bench.map((player: any) => player.id);

				teamsActions.editWeekSelections(
					teamId,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId,
					this.state.viceCaptainId,
					weekId
				)
			}
		};

		onDayChange = (direction: string) => {
			if (direction === 'next') {
				const upcomingWeekExists = this.props.matches.weeks
					.find((value: Week) => !!(this.state.visibleWeekId && (value.weekId > this.state.visibleWeekId)));

				if (upcomingWeekExists && this.state.visibleWeekId) {
					this.setState({ visibleWeekId: this.state.visibleWeekId + 1 });
				}
			} else if (direction === 'prev') {
				const previousWeekExists = this.props.matches.weeks
					.find((value: Week) => !!(this.state.visibleWeekId && (value.weekId < this.state.visibleWeekId)));

				if (previousWeekExists && this.state.visibleWeekId) {
					this.setState({ visibleWeekId: this.state.visibleWeekId - 1 });
				}
			} else {
				return;
			}
		};

		onPlayerSwap = (player: Player) => {
			if (player && player.id === this.state.swapPlayerId) {
				if (this.state.type === 'football') {
					const pickBackResult = this.state.footballValidator.pick({ Player: { id: player.id, 'position_id': player.positionId } });
				}

				this.setState({ swapPlayerId: null, swappedFrom: null, swapPlayer: null });
			} else if (this.state.swapPlayerId) {
				const previousSwapFromLineup = this.state.starting
					.find((startingPlayer: any) => startingPlayer && startingPlayer.id === this.state.swapPlayerId);
				const previousSwapFromBench = this.state.bench
					.find((benchPlayer: any) => benchPlayer && benchPlayer.id === this.state.swapPlayerId);
				let starting = null;
				let bench = null;
				let captainId = null;

				if (previousSwapFromLineup) {
					starting = this.state.starting
						.map((startingPlayer: any) =>
							startingPlayer && (startingPlayer.id === this.state.swapPlayerId) ?
								Object.assign({}, player, { inStarting: true }) : startingPlayer);

					bench = this.state.bench
						.map((benchPlayer: any) =>
							benchPlayer && (benchPlayer.id === player.id) ?
								Object.assign({}, previousSwapFromLineup, { inStarting: false }) : benchPlayer);

					// if (previousSwapFromLineup.id === this.state.captainId) {
					// 	captainId = player.id;
					// }
				} else {
					const secondSwapFromBench = this.state.bench
						.find((benchPlayer: any) => benchPlayer && benchPlayer.id === player.id);

					if (previousSwapFromBench && secondSwapFromBench) {
						starting = [].concat(this.state.starting as any);
						bench = this.state.bench
							.map((benchPlayer: any) => {
								if (benchPlayer.id === previousSwapFromBench.id) {
									return secondSwapFromBench;
								} else if (benchPlayer.id === secondSwapFromBench.id) {
									return previousSwapFromBench;
								} else {
									return benchPlayer
								}
							});
					} else {
						starting = this.state.starting
							.map((startingPlayer: any) =>
								startingPlayer && (startingPlayer.id === player.id) ?
									Object.assign({}, previousSwapFromBench, { inStarting: true }) : startingPlayer);

						bench = this.state.bench
							.map((benchPlayer: any) =>
								benchPlayer && (benchPlayer.id === this.state.swapPlayerId) ?
									Object.assign({}, player, { inStarting: false }) : benchPlayer);

						// if (player.id === this.state.captainId) {
						// 	captainId = previousSwapFromBench.id;
						// }
					}
				}

				if (this.state.type === 'football') {
					const startingPlayersValidatorFormat = playersToValidatorFormat(starting);
					const benchPlayersValidatorFormat = playersToValidatorFormat(bench);

					if (this.state.type === 'football') {
						this.state.footballValidator.set(startingPlayersValidatorFormat, benchPlayersValidatorFormat);
					}
				}

				this.setState({
					starting,
					bench,
					swappedFrom: null,
					swapPlayerId: null,
					swapPlayer: null,
					captainId: captainId || this.state.captainId
				});
			} else {
				const isLineupSwap = this.state.starting
					.find((startingPlayer: any) => startingPlayer && player && startingPlayer.id === player.id);

				if (this.state.type === 'football' && isLineupSwap) {
					const removeResult = this.state.footballValidator.remove({ Player: { id: player.id, 'position_id': player.positionId } });
				}

				this.setState({ swapPlayerId: player.id, swapPlayer: player, swappedFrom: isLineupSwap ? 'starting' : 'bench' })
			}
		};

		isPickAble = (player: Player, taxForPicking?: boolean, isTransferPick?: boolean, ) => {
			const notInStarting = !this.state.starting
				.find(startingPlayer => startingPlayer && startingPlayer.id && startingPlayer.id === player.id);
			const notInBench = !this.state.bench
				.find(benchPlayer => benchPlayer && benchPlayer.id && benchPlayer.id === player.id);
			const affordable = !taxForPicking ?
				player.value <= this.state.budget :
				roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100)) <= this.state.budget;
			
			const notTransferredOut = isTransferPick ?
				!this.state.draftTransfers.find(transfer => transfer.outId === player.id) :
				true;

			const hasEmptySpotInTeam =
				!!this.state.starting.find(startingPlayer => !startingPlayer.id) ||
				!!this.state.bench.find(benchPlayer => !benchPlayer.id);

			const pickedSoFarFromSameClub =
				this.state.starting
					.filter(startingPlayer => startingPlayer && startingPlayer.clubId === player.clubId)
					.length
				+
				this.state.bench
					.filter(benchPlayer => benchPlayer && benchPlayer.clubId === player.clubId)
					.length;

			const pickWeek = this.state.visibleWeekId || 0;
			const notBreakingClubLimit = Array.isArray(this.props.application.competition.teamSameClubPlayersLimit) ? 
				pickedSoFarFromSameClub < this.props.application.competition.teamSameClubPlayersLimit[pickWeek] 
				: pickedSoFarFromSameClub < this.props.application.competition.teamSameClubPlayersLimit;

			let notBreakingFootballLineup = true;

			if (this.state.type === 'football' && this.state.useFootballValidatorOnPick) {
				notBreakingFootballLineup = this.state.footballValidator.canPick({ Player: { 'id': player.id, 'position_id': player.positionId } });
			}

			if (this.state.type === 'football' && !this.state.useFootballValidatorOnPick) {
				const playerPositionMaxPicks = []
					.concat(this.state.starting as any, this.state.bench as any)
					.filter((item: any) => item.positionId === player.positionId)
					.length;
				const playerPositionAlreadyPicked = []
					.concat(this.state.starting as any, this.state.bench as any)
					.filter((item: any) => item.positionId === player.positionId && item.id)
					.length;
				notBreakingFootballLineup = playerPositionAlreadyPicked < playerPositionMaxPicks;
			}

			return notInStarting && notInBench && affordable && hasEmptySpotInTeam && notBreakingClubLimit && notTransferredOut && notBreakingFootballLineup;
		};

		isSwapAble = (player: Player) => {
			if (this.state.type === 'football' && this.state.swapPlayerId && this.state.swapPlayer) {

				const swapInitiatedInLineup = this.state.starting
					.find((startingPlayer: any) => startingPlayer && startingPlayer.id === this.state.swapPlayerId);

				if (swapInitiatedInLineup) {
					const canPick = this.state.footballValidator.canPick({ Player: { 'id': player.id, 'position_id': player.positionId } }, true);
					// return (canPick && player.upcomingMatches[0] && moment(player.upcomingMatches[0].date).isAfter(moment.utc()));
					return canPick;
				} else {
					const benchSwappedPlayer = this.state.bench.find((benchPlayer: any) => benchPlayer && benchPlayer.id === this.state.swapPlayerId);
					const isTheBenchedGoalkeeper = !player.inStarting && player.positionId === 1;
					const swappedPlayerIsGoalkeeperAndCurrentIteratedPlayerInBench =
						!player.inStarting && benchSwappedPlayer && benchSwappedPlayer.positionId === 1 && player.id !== this.state.swapPlayerId;

					if (isTheBenchedGoalkeeper || swappedPlayerIsGoalkeeperAndCurrentIteratedPlayerInBench) {
						return false;
					}

					if (player && player.id) {
						this.state.footballValidator.remove({ Player: { 'id': player.id, 'position_id': player.positionId } });
						const canPick = this.state.footballValidator.canPick({ Player: { 'id': this.state.swapPlayer.id, 'position_id': this.state.swapPlayer.positionId } }, true);
						this.state.footballValidator.pick({ Player: { 'id': player.id, 'position_id': player.positionId } });
						return canPick;
					} else {
						return false;
					}
				}
			// } else if(player && this.state.visibleWeekId !== this.props.matches.info.deadlineWeek || this.state.visibleWeekId === this.props.matches.weeks.length) {
			// 	//Power subs only for benched when deadlineweek passed and they didn't play yet
			// 	if(player.inStarting) { return false }

			// 	//isTeamOwner doesn't work on view
			// 	return !!(this.state.hasActiveMatches && player.upcomingMatches[0] && moment(player.upcomingMatches[0].date).isAfter(moment.utc()) && this.state.isTeamOwner && this.props.user.data.role === 'premium');
			} else {
				return true;
			}
		}

		loadAllMatches = () => {
			if (!this.props.matches.data.length) {
				this.props.fetchMatches(
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId
				);
			}
		};

		onTransferPlayerOut = (player: Player, extra?: boolean) => {
			this.removePlayer(player);

			const draftTransfers = this.state.draftTransfers
				.concat([{
					inId: null,
					outId: player.id,
					outPlayer: player,
					extra,
					weekId: this.props.matches.info.deadlineWeek
				}]);

			this.setState({ draftTransfers })
		};

		onTransferPlayerIn = (player: Player) => {
			const draftTransfers = ([] as Transfer[]).concat(this.state.draftTransfers);
			for (let transferIndex = 0; transferIndex < draftTransfers.length; transferIndex++) {
				if (!draftTransfers[transferIndex].inId && draftTransfers[transferIndex].outPlayer.positionId === player.positionId) {
					draftTransfers[transferIndex].inId = player.id;
					draftTransfers[transferIndex].inPlayer = player;
					break;
				}
			}
			this.setState({ draftTransfers })
		};

		onDraftTransfersClear = () => {
			if (this.state.type === 'football') {
				const startingPlayersValidatorFormat = playersToValidatorFormat(this.state.initialStarting);
				const benchPlayersValidatorFormat = playersToValidatorFormat(this.state.initialBench);
				this.state.footballValidator.set(startingPlayersValidatorFormat, benchPlayersValidatorFormat);
			}

			this.setState({
				draftTransfers: [],
				starting: this.state.initialStarting,
				bench: this.state.initialBench,
				budget: this.state.initialBudget
			})
		};

		onTransfersSubmit = (teamId: number) => {
			const transfers = this.state.draftTransfers
				.map((transfer: Transfer) => pick(transfer, ['inId', 'outId']));
			return teamsActions.submitTransfers(teamId, transfers)
		};

		onTransfersReset = (teamId: number) => {
			return teamsActions.resetTransfers(teamId)
		};

		reloadUserTeams = () => {
			this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
		};

		setActivePositionFilter = (positionId: number) => {
			this.setState({ activePositionFilter: positionId });
		};

		possibleFormation = () => {
			if (this.state.type === 'football') {
				return this.state.footballValidator.getPossibleFormations();
			} else {
				return [this.props.application.competition.lineupPositionRows && this.props.application.competition.lineupPositionRows.join('-')];
			}
		};

		render() {
			return (
				<WrappedComponent
					setStarting={this.setStarting}
					setBench={this.setBench}
					setBudget={this.setBudget}
					setTeamName={this.setTeamName}
					setCaptainId={this.setCaptainId}
					setActivePositionFilter={this.setActivePositionFilter}
					isPickAble={this.isPickAble}
					isSwapAble={this.isSwapAble}
					onTeamSave={this.onTeamSave}
					onTeamReset={this.onTeamReset}
					onTeamNameChange={this.onTeamNameChange}
					onNameVisible={this.onNameVisible}
					nameVisible={this.state.nameVisible}
					onNameVisibleChange={this.onNameVisibleChange}
					onCaptainSelect={this.onCaptainSelect}
					onViceCaptainSelect={this.onViceCaptainSelect}
					removeBenchPlayer={this.removeBenchPlayer}
					removeStartingPlayer={this.removeStartingPlayer}
					removePlayer={this.removePlayer}
					pickPlayer={this.pickPlayer}
					activePositionFilter={this.state.activePositionFilter}
					starting={this.state.starting}
					bench={this.state.bench}
					captainId={this.state.captainId}
					viceCaptainId={this.state.viceCaptainId}
					teamName={this.state.teamName}
					budget={this.state.budget}
					leagues={this.state.leagues}
					savingTeamPending={this.state.savingTeamPending}
					swapPlayerId={this.state.swapPlayerId}
					swappedFrom={this.state.swappedFrom}
					initializedExternally={this.state.initializedExternally}
					visibleWeekId={this.state.visibleWeekId}
					teamNameChanged={this.state.teamNameChanged}
					teamPointsInfo={this.state.teamPointsInfo}
					draftTransfers={this.state.draftTransfers}
					deadlineWeekTransfers={this.state.deadlineWeekTransfers}
					pastTransfers={this.state.pastTransfers}
					initTeamState={this.initTeamState}
					activateCacheChanges={this.activateCacheChanges}
					resetTeamName={this.resetTeamName}
					onTeamNameUpdate={this.updateTeamName}
					onTeamEdit={this.onTeamEdit}
					onTeamSelectionsUpdate={this.onTeamSelectionsUpdate}
					onDayChange={this.onDayChange}
					onPlayerSwap={this.onPlayerSwap}
					loadAllMatches={this.loadAllMatches}
					possibleFormation={this.possibleFormation}
					onTransferPlayerOut={this.onTransferPlayerOut}
					onDraftTransfersClear={this.onDraftTransfersClear}
					onTransferPlayerIn={this.onTransferPlayerIn}
					onTransfersSubmit={this.onTransfersSubmit}
					onTransfersReset={this.onTransfersReset}
					boosters={this.state.boosters}
					isTeamOwner={this.state.isTeamOwner}
					reloadUserTeams={this.reloadUserTeams}
					teamUser={this.state.teamUser}
					{...this.props}
				/>
			);
		}
	}

	const mapDispatchToProps = {
		create: teamsActions.add,
		updateTeamName: teamsActions.updateTeamName,
		fetchMatches: matchesActions.fetchMatches,
		fetchUserTeamsAndLeagues: userActions.fetchTeams,
	};

	function mapStateToProps({ user, matches, application, players, clubs }: StoreState.All) {
		return {
			matches,
			application,
			players,
			clubs,
			user
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(AbstractTeam)
};

export default withAbstractTeam;