import styled from 'styled-components';
import { Modal } from 'antd';

import 'antd/lib/modal/style';

const ConfirmationModalStyle = styled(Modal)`
	.ant-modal-content {
		border-radius: 0px;
		max-width: 575px;
		background-color: #000;
		color: #FFF;

		.ant-modal-title {
			font-family: "C-Bold";
			text-transform: uppercase;
			color: #000;
			background-color: ${props => props.theme.primaryColor};
			padding: 5px 5px 5px 36.5px;
		}

		.ant-modal-close-x {
			width: 30px;
			height: 30px;
			font-size: 20px;
			color: #000;
			line-height: 30px;
		}

		.ant-modal-header {
			border: 0px;
			border-radius: 0px;
			padding: 0;

			.ant-modal-title {
				color: #000;
				p {
					margin: 0px;
				}

				.custom-title-container {
					text-align: right;

					.anticon {
						margin-top: 5px;
						margin-right: 5px;
					}
				}
			}
		}

		.ant-modal-footer {
			display: none;
		}
	}

	.actions {
		text-align: right;
		margin-top: 15px;
		button {
			margin: 5px;
		}
	}
`;

export {
	ConfirmationModalStyle
};
