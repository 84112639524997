import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { ContainerStyle, BasicTableStyle } from './../PlayerList/PlayerListStyle';

class PricingTable extends Component {
	state = {};

	render() {
		const data = [
			{feature: {title: "Eigen Fantasy team", text: ""}, free: true, premium: true},
			{feature: {title: "Boosters", text: ""}, free: true, premium: true},
			{feature: {title: "Auto subs", text: ""}, free: true, premium: true},
			{feature: {title: "Transfers", text: ""}, free: true, premium: true},
			{feature: {title: "Premium 👑", text: "Elke speler met Premium krijgt een 👑 achter de naam. Zo weet iedereen dat je ons steunt én dat ze rekening met je moeten houden!"}, free: false, premium: true},
			{feature: {title: "Advanced stats", text: "Op de Statspagina krijg je extra filtermogelijkheden tijdens je scouting. Denk aan: hoeveel % gekozen, historische data, pickorde bij zijn trainer, reële transferwaarde, maar ook assists, kaarten, gestopte penalty's, vorm, etc."}, free: false, premium: true},
			{feature: {title: "Power subs", text: "Iedereen heeft 'auto subs', een handig systeem dat bankzitters in je team zet indien een basisspeler niet in actie kwam. Als Premium-speler heb je een extraatje. Met een 'power sub' kan je tijdens het voetbalweekend een bankzitter (die nog niet speelde) handmatig in je basis droppen, bijvoorbeeld in plaats van een speler die speelde maar misschien te weinig scoorde. Beschikbaar vanaf speeldag 5, na de testperiode."}, free: false, premium: true},
			{feature: {title: "Bespioneer de tegenstand", text: "Bekijk de teams in het algemene klassement en in je minicompetitie. En niet enkel de teams van de voorbije speeldag, je ziet ook de opstellingen voor de volgende speeldag."}, free: false, premium: true},
			{feature: {title: "Minicompetitie aanmaken", text: "Iedereen kan meespelen met een minicompetitie, maar enkel iemand met Premium kan er eentje opstarten. Elke minicompetitie is voorzien van een chat."}, free: false, premium: true},
			{feature: {title: "Add some swag", text: "Upload je custom teamlogo om er extra uit te springen in de rangschikkingen en op je teampagina."}, free: false, premium: true},
			{feature: {title: "Updates tijdens het seizoen", text: "We blijven werken om Fan Arena Fantasy te verbeteren. Op onze roadmap staat o.a. een draft en auction game mode."}, free: false, premium: true},
		];

		const withMetaData = data.map((item: any, index: number) =>
			Object.assign({}, item, { rank: index + 1 }));

		const columns = [{
			title: 'Feature',
			key: 'feature',
			dataIndex: 'feature',
			width: '60%',
			render: (text: string, record: any) => {
				let color = record.free ? '#FFF' : '#1858A8'
				return (
					<Tooltip title={record.feature.text}>
						<span style={{color: color}}>{record.feature.title}</span>
					</Tooltip>
				);
			},
		}, {
			title: 'Gratis',
			key: 'free',
			dataIndex: 'free',
			width: '20%',
			render: (option: boolean, record: any) => {
				return option ? `✅` : `❌`;
			}
		}, {
			title: 'Premium',
			key: 'premium',
			dataIndex: 'premium',
			width: '20%',
			render: (option: boolean, record: any) => {
				return option ? `✅` : `❌`;
			}
		}];

		return (
			<ContainerStyle>
				<BasicTableStyle
					style={{margin: "50px 40px"}}
					columns={columns}
					rowKey="rank"
					dataSource={withMetaData}
					pagination={false}
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
				/>
			</ContainerStyle>
		);
	}
}

export default PricingTable;
