import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { Player, AbstractTeamType, UserState } from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	FootballCalendar, Select, Button, Input, AbstractTeam, NewGameStats, Checkbox, Alert
} from './../../components';
import { notification, Icon } from 'antd';
import { theme } from './../../styles/theme-sporza';

import teamBackground from './../../assets/img/bg-football.png';
import teamSponsor from "./../../assets/img/header/megaspelen-logo.png";
import { startingListToPositionsList, getPublisherLogoURL, selectionPlayerSellValue } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import * as userActions from '../../actions/user' //TODOREPLACE BY TEAMACTIONS
import { EditableTeamNameStyle } from './../../components/Dashboard/DashboardStyles';
import { SaveLineupButton } from './../Team/TeamStyles';
import { NewTeamStyles } from './../NewTeam/NewTeamStyles';
import { Link } from '../../components/UI/Link/Link';

import { Element, scroller } from 'react-scroll'

export interface Props {
	match: any;
	user: UserState;
	t: any;
	nameVisible: boolean;
	onNameVisibleChange: any;
}

export interface State {
	notFound: boolean;
	updatingTeam: boolean;
	loaded: boolean;
}

interface BadgeProps {
	loading: any,
	imageUrl: any
}

function getBase64(img: any, callback: any) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		notification.error({ message: 'You can only upload JPG/PNG file!' });
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		notification.error({ message: 'Image must smaller than 2MB!' });
	}
	return isJpgOrPng && isLt2M;
}

class EditTeamContainer extends Component<AbstractTeamType & Props, State & BadgeProps> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			updatingTeam: false,
			loaded: false,
			loading: false,
			imageUrl: ''
		}
	};

	onPlaceHolderClick = (player: any) => {
		if (player && player.positionId || player && player.positionId === 0) {
			this.props.setActivePositionFilter(player.positionId);
		}

		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	componentDidMount() {
		const teamId = this.props.match.params.id;
		this.getTeamInfo(teamId)
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getTeamInfo(this.props.match.params.id);
		}
	}

	updateTeam = (teamId: number) => {
		if (this.state.updatingTeam) {
			return;
		}

		this.setState({ updatingTeam: true });

		this.props.onTeamEdit(teamId)
			.then(() => {
				this.setState({ updatingTeam: false });
			})
			.catch((error:any) => {
				console.log(error)
				this.setState({ updatingTeam: false });
			})

	};

	getTeamInfo = (teamId: number) => {
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname', 'portraitUrl'];
		const selectionProps: any[] = [];
		teamsActions.get(teamId)
			.then(result => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const transferValue = selectionPlayerSellValue(player);
						return Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps), { value: transferValue })
					});
				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => {
						const transferValue = selectionPlayerSellValue(player);
						return Object.assign({ inStarting: false }, pick(player, playerProps), pick(player.selection, selectionProps), { value: transferValue })
					});
				const teamName = result.team.name;

				let captainId = null;
				let viceCaptainId = null;

				const captainRecord = result.players
					.find((player: any) => player.selection.captain === 1);

				const viceCaptainRecord = result.players
					.find((player: any) => player.selection.captain === 2);

				if (captainRecord) {
					captainId = captainRecord.id;
				}

				if (viceCaptainRecord) {
					viceCaptainId = viceCaptainRecord.id;
				}
				let budget = result.team.budget !== null ? result.team.budget : result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				const boosters = {
					freeHit: result.team.freeHit,
					bank: result.team.bank,
					tripleCaptain: result.team.tripleCaptain,
					wildCard: result.team.wildCard
				};
				const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;

				if ((boosters.freeHit === deadlineWeek) && (result.team.freeHitBudget !== null)) {
					budget = result.team.freeHitBudget;
				}

				this.props.initTeamState(starting, bench, teamName, captainId, budget, undefined, undefined, undefined, [], [], [], viceCaptainId, boosters, undefined, undefined, result.user.nameVisible);
				this.setState({ loaded: true, imageUrl: result.team.badge });
			})
			.catch(error => {
				this.setState({ notFound: true, loaded: true });
			})
	};

	handleUpload = (f:any) => {
		this.setState({ loading: true });

		teamsActions.uploadBadge(f, this.props.match.params.id)
			.then((result: any) => {
				getBase64(f, (imageUrl: any) =>
					this.setState({
						imageUrl: imageUrl,
						loading: false,
					}),
				);

				notification.success({ message: this.props.t('team.badgeUploaded') });
			})
			.catch((err: any) => {
				console.log(err);
				notification.error({ message: this.props.t('team.uploadFailed') });
			})
	}

	render() {
		const {
			application,
			starting,
			bench,
			captainId,
			viceCaptainId,
			onViceCaptainSelect,
			teamName,
			budget,
			matches,
			user,
			teamNameChanged,
			initializedExternally,
			t,
			activePositionFilter,
			boosters,
			nameVisible,
			onNameVisibleChange
		} = this.props;
		const { updatingTeam, loaded } = this.state;

		const startingByPositions = startingListToPositionsList([].concat(starting as any, bench as any), [2, 5, 5, 3], 'football');
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player);
		const benchPicked = bench
			.filter(player => !!player);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const teamId = parseInt(this.props.match.params.id);

		const team = this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === teamId);

		const firstPlayingWeekPassed = team && team.id && matches && matches.info && matches.info.deadlineWeek
			&& (matches.info.deadlineWeek > team.weekId);
		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const deadlineEpochMilliseconds = deadlineDate ? moment(deadlineDate).valueOf() : 0;

		const wildCardOrFreeHitEnabled = boosters.wildCard === deadlineWeek || boosters.freeHit === deadlineWeek;
		const noMarginOrPaddingStyle = { margin: 0, padding: 0 };

		const gameOfficiallyStarted = team && team.id && matches && matches.info && matches.info.deadlineWeek &&
			matches.info.deadlineWeek > this.props.application.competition.officialStartWeek;

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';
		let publisherLogoURL = getPublisherLogoURL(userRegisterInfo);

		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'}  style={{fontSize: "40px"}} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<NewTeamStyles>
				{(notTeamOwner || this.state.notFound) && <Redirect
					to={{ pathname: `/home` }}
				/>}
				{loaded && team && starting && starting.length === 0 && <Redirect 
					to={{ pathname: `/new` }}
				/>}
				{loaded && deadlineWeek && team && (firstPlayingWeekPassed && gameOfficiallyStarted && !wildCardOrFreeHitEnabled) && <Redirect
					to={{ pathname: `/transfers/${team.id}` }}
				/>}
				{
					(team && initializedExternally && <Row style={noMarginOrPaddingStyle}>
						<Col md={12} sm={12} xs={24} className="left">
							<Block>
							<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>
								<NewGameStats budget={budget}
									totalPlayers={totalPlayersToPick}
									t={t}
									totalPlayersSelected={totalPlayersPicked} />

								<Alert style={{ textAlign: 'center', margin: "0px auto 20px" }}
									message={t('team.editTeamUnlimitedChangesMessage')}
									type="info"
									showIcon />

								<Team
									widthRatio={12}
									heightRatio={10}
									clubs={this.props.clubs.data}
									bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg_ek24.jpg'}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									playerType={PlayerType.SoccerShirt}
									captainId={captainId}
									viceCaptainId={viceCaptainId}
									showCaptainBadge={true}
									onViceCaptainSelect={onViceCaptainSelect}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									showPlayerValueInsteadOfPoints={true}
									showPlayerValue={true}
									onCaptainSelect={this.props.onCaptainSelect}
									modalEnabled={true}
									onRemove={(player: Player) => this.props.removePlayer(player)}
									onPlaceholderClick={this.onPlaceHolderClick}
									playerPointsColor={"#000"}
									playerPointsBgColor="#84FF00"
									t={this.props.t} />

								{
									(team && <SaveLineupButton style={{ width: '100%' }}>
										<Button
											onClick={(e: any) => this.updateTeam(team.id)}
											type="primary"
											disabled={updatingTeam}
											loading={updatingTeam}
											size="large">
											{
												!updatingTeam ?
													<Icon type="save" theme="filled" />
													: null
											}
											{t('team.saveTeam')}
										</Button>
									</SaveLineupButton>) || null
								}

								<Alert
									message="Enkele tips"
									description="Selecteer 15 spelers voor jouw droomelftal. Laat geen punten liggen en pas elke speeldag je team aan. En opgelet: blijf binnen het budget en kies max. 3 spelers van hetzelfde land."
									type="info"
									showIcon
									style={{margin: '10px auto'}}
									/>
							</Block>
							<Block>
							<Title type={TitleType.h2}>Ploegnaam aanpassen</Title>
		
							<Input onChange={this.props.onTeamNameChange} value={teamName} maxLength={64} />
							{(teamNameChanged && (
									<div style={{ textAlign: 'right' }}>
											<Button
													onClick={this.props.resetTeamName}
													style={{ margin: '5px' }}
													size="small"
											>
													Annuleer
											</Button>
					
											<Button
													onClick={(e: any) => this.props.onTeamNameUpdate(team.id)}
													style={{ margin: '5px' }}
													size="small"
											>
													Bewaar
											</Button>
									</div>
							)) ||
									null}
							<Checkbox onChange={onNameVisibleChange} checked={nameVisible}>Ik wens mijn naam zichtbaar in mijn minicompetitie en de rangschikking</Checkbox>
					
							</Block>
							{/* <Block>
								<div style={{textAlign: "center", backgroundColor: "#363435"}}><a href="https://www.betfirst.be/">
									<img
										alt=""
										style={{height: "75px", width: "auto"}}
										src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/betfirst.jpg"
									/>
								</a></div>
							</Block> */}
						</Col>
						<Col md={12} sm={12} xs={24} className="right">
							<Block>
								<Title type={TitleType.h2}>{t('general.footballAllPlayers')}</Title>
								<Element name="all-players">
									<PlayerList
										clubs={this.props.clubs.data}
										matches={matches.data}
										deadlineWeek={deadlineWeek}
										isLoading={this.props.players.isFetching}
										hidePositions={false}
										assetsCdn={application.competition.assetsCdn}
										activePositionFilter={activePositionFilter}
										isPickAble={this.props.isPickAble}
										playerType={PlayerType.SoccerShirt}
										data={this.props.players.data}
										onPick={this.props.pickPlayer}
										action
										t={this.props.t}
										showHeader={false}
										size={10}
									/>
								</Element>
							</Block>
						</Col>
					</Row>) || null
				}
			</NewTeamStyles>
		);
	}
}

const WrappedComponent = AbstractTeam(EditTeamContainer, { type: 'football' });


export default WrappedComponent;