import styled from 'styled-components';
import { Checkbox } from 'antd';

import 'antd/lib/checkbox/style';

export const CheckboxStyle = styled(Checkbox)`
  && {
    color: #9FA8AD;

    .ant-checkbox-inner {
    }
  
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        ::after {
          border-color: #000;
        }
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
    }
  }
`;
