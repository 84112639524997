import styled from "../../styles/styled-components";
import mediaQueries from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const RankingPrizesStyles = styled.div`
    .image-prizes-widget {
        width: 100%;
        max-width: 300px;
        border: 1px solid transparent;
        border-radius: 5px;
    }

    .ant-col {
        text-align: center;
    }

    .prize-row {
        padding: 20px 0 20px 0;
    }

    ul, p {
    text-align:left;
    }

    ul {
        display: inline-block;
    }
`;

const PrizesStyles = styled.div`
    padding: 0;

    .prizes-tabs {
        display: flex;
        -webkit-box-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        justify-content: space-between;
        list-style: none;
        padding: 0px;

        li {
            display: block;
            background-color: #3C3C3C;
            padding: 5px;
            list-style-type: none;
            width: 100%;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 0 1 auto;
            padding: 5px;
            overflow: hidden;
            cursor: pointer;

            :first-child {
                border-radius: 50px 0px 0px 50px;				
            }

            :last-child {
                border-radius: 0 50px 50px 0px;
            }

            &.active {
                background: ${props => props.theme.primaryColor};
            }
        }
    }
`;

export {
    RankingPrizesStyles,
    PrizesStyles
};