import React, { Component } from "react";
import lockr from 'lockr';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { ThemeProvider } from "./styles/styled-components";
import { theme } from "./styles/theme-sporza";
import 'moment/locale/nl';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withTranslation, WithTranslation } from 'react-i18next';

import config from './config';

lockr.prefix = config.LOCKR_PREFIX as string;

import { Header, Layout, Spinner, Footer, VrtLogin } from "./components";
import {
	Home,
	NewTeam,
	EditTeam,
	Stats,
	Rules,
	Join,
	Rankings,
	Forgot,
	PasswordReset,
	UserProfile,
	Terms,
	Register,
	Deadlines,
	Prizes,
	Points,
	Match,
	Team,
	Transfers,
	Leagues,
	Shop,
	Thanks,
	Privacy,
	Cookie,
	Club,
	VrtCallback
} from "./pages";
import { GlobalStyle } from "./styles/global";

function loggedIn(): boolean {
	return !!lockr.get('token');
}

function NoMatch() {  
	return (
	  <div>
		<h3>
			Pagina niet gevonden, <Link to="/home" style={{color: "#84FF00"}}>
				terug naar home
			</Link>
		</h3>
	  </div>
	);
  }
class App extends React.PureComponent<WithTranslation> {
	componentDidMount () {
		// @ts-ignore
		window.$crisp = [];
		// @ts-ignore
		window.CRISP_WEBSITE_ID = "2a661faf-50e1-4d08-85f3-cfc94c37f5c9";

		(function() {
			var s = document.createElement("script");

			s.src = "https://client.crisp.chat/l.js";
			// @ts-ignore
			s.async = 1;
			document.getElementsByTagName("head")[0].appendChild(s);
		})();
	}

	render() {
		// @ts-ignore
		const PrivateRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/home', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const PublicOnlyRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				!loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/home', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const UniversalRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => ((<Component {...props} {...{ t: this.props.t }} />))} />
		);

		return (
			<ThemeProvider theme={theme}>
				<Router>
					<React.Fragment>
						<GlobalStyle />
						<Header t={this.props.t} renderVrtWidget={() => <VrtLogin id="VrtHeader" elemStyle={{ width: '9.3em' }} theme="c4" />} />
						<Layout>
							<Switch>
								<PublicOnlyRoute exact path="/" component={Home} />
								<UniversalRoute path="/home" component={Home} />
								<PublicOnlyRoute path="/register" component={Register} />
								<PrivateRoute path="/new" component={NewTeam} />
								<PrivateRoute path="/leagues" component={Leagues} />
								<PrivateRoute path="/points/:id" component={Points} />
								<PrivateRoute path="/public/:id" component={Points} />
								<PrivateRoute path="/team/:id" component={Team} />
								<PrivateRoute path="/transfers/:id" component={Transfers} />
								<PrivateRoute path="/edit/:id" component={EditTeam} />
								<PrivateRoute path="/profile" component={UserProfile} />
								<PrivateRoute path="/join/:code" component={Join} />
								<UniversalRoute path="/rules" component={Rules} />
								<UniversalRoute path="/deadlines" component={Deadlines} />
								<UniversalRoute path="/terms" component={Terms} />
								<UniversalRoute path="/stats" component={Stats} />
								<UniversalRoute path="/match/:id" component={Match} />
								<UniversalRoute path="/prizes" component={Prizes} />
								<PublicOnlyRoute path="/forgot" component={Forgot} />
								<PublicOnlyRoute path="/reset-password/:resetToken" component={PasswordReset} />
								<UniversalRoute path="/rankings" component={Rankings} />
								<PrivateRoute path="/shop" component={Shop} />
								<PrivateRoute path="/thanks" component={Thanks} />
								<UniversalRoute path="/cookie" component={Cookie} />
								<UniversalRoute path="/privacy" component={Privacy} />
								<UniversalRoute path="/club/:id" component={Club} />
								<UniversalRoute path="/provider/vrt" component={VrtCallback}/>
								<UniversalRoute path="*" component={NoMatch} />
							</Switch>
							<Spinner />
						</Layout>
						{/* <Layout><Footer/></Layout> */}
					</React.Fragment>
				</Router>
			</ThemeProvider>
		);
	}
}

export default withTranslation('translation')(App);
