import React, { Component } from 'react';
import { TeamStyle, Sponsor } from './TeamStyle';
import Player, { PlayerType } from '../Player/Player';
import { Club, BoostersWeekStatus } from './../../types';
import i18next from 'i18next';

export interface TeamState {
}

export interface TeamProps {
	bg: string;
	bgSponsor?: string;
	widthRatio: number;
	heightRatio: number;
	playerType: PlayerType;
	selection: any;
	startedThisWeek?: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	onRemove?: any;
	onSwap?: any;
	isSwapAble?: any;
	onCaptainSelect?: any;
	onViceCaptainSelect?: any;
	modalEnabled?: boolean;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	assetsCdn: string;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerStatsPoints?: boolean;
	clubs: Club[];
	showPlayerValueInsteadOfPoints?: boolean;
	captainId?: number;
	captainHasPlayed?: boolean;
	showCaptainBadge?: boolean;
	boosterWeekStatus?: BoostersWeekStatus;
	viceCaptainId?: number;
	centerAligned?: boolean | undefined;
	t: i18next.TFunction;
}

class Team extends Component<TeamProps, TeamState> {
	render() {
		const {
			bg,
			bgSponsor,
			widthRatio,
			heightRatio,
			selection,
			playerType,
			playerBadgeColor,
			playerBadgeBgColor,
			playerPointsColor,
			playerPointsBgColor,
			onRemove,
			onSwap,
			onPlaceholderClick,
			swapPlayerId,
			swappedFrom,
			assetsCdn,
			actionLessPlayerIds,
			showPlayerValue,
			showPlayerStatsPoints,
			showPlayerValueInsteadOfPoints,
			clubs,
			onCaptainSelect,
			modalEnabled,
			captainId,
			centerAligned,
			isSwapAble,
			captainHasPlayed,
			viceCaptainId,
			showCaptainBadge,
			onViceCaptainSelect,
			boosterWeekStatus,
			t
		} = this.props;

		return (
			<TeamStyle bg={bg} widthRatio={widthRatio} heightRatio={heightRatio} centerAligned={centerAligned}>
				{bgSponsor && <Sponsor bg={bgSponsor} widthRatio={widthRatio} heightRatio={heightRatio} />}
				{selection.map((position: any, positionIndex: number) => {
					const sportSpecificProps: {
						shirtCycling?: string,
						shirtSoccer?: string,
						soccerJersey?: string,
						clubBadge?: string,
						portraitFace?: string,
						shirtFallback?: string,
						portraitFaceFallBack?: string,
						club?: Club
					} = {};

					return (
						<div className={`position`} key={`positionIndex-${positionIndex}`}>
							{
								position
								//.filter((player: any) => player.positionId !== 0)
								.map((player: any, playerIndex: number) => {
									const club = clubs.find((item: Club, index: number) => player && item.id === player.clubId);
									sportSpecificProps.club = club;

									if ([PlayerType.Cycling, PlayerType.SoccerShirt].includes(playerType) && player) {

										if (PlayerType.Cycling === playerType) {
											sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
										}

										if (PlayerType.SoccerShirt === playerType) {
											sportSpecificProps.shirtSoccer = `${assetsCdn}/jerseys/football/${player.clubId}.png`;
											if(player && player.clubId) {
												sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${player.clubId}.png`;
											} else {
												sportSpecificProps.clubBadge = undefined;
											}
										}

										sportSpecificProps.shirtFallback = `${assetsCdn}/jerseys/dummy.png`;
									}

									if (PlayerType.SoccerPortrait === playerType && player) {
										// sportSpecificProps.soccerJersey = `${assetsCdn}/jerseys/club_${player.clubId}.png`;
										sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${player.clubId}.png`;
										sportSpecificProps.portraitFace = player.portraitUrl;
										sportSpecificProps.portraitFaceFallBack = `${assetsCdn}/players/dummy.png`;
									}

									return <Player
										key={`playerIndex-${playerIndex}`}
										pointsColor={playerPointsColor}
										pointsBgColor={playerPointsBgColor}
										badgeColor={playerBadgeColor}
										badgeBgColor={playerBadgeBgColor}
										positionIndex={positionIndex}
										modalEnabled={modalEnabled}
										captainId={captainId}
										viceCaptainId={viceCaptainId}
										boosterWeekStatus={boosterWeekStatus}
										captainHasPlayed={captainHasPlayed}
										player={player}
										showPlayerStatsPoints={showPlayerStatsPoints}
										showPlayerValue={showPlayerValue}
										showCaptainBadge={showCaptainBadge}
										type={playerType}
										onRemove={onRemove}
										showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
										onSwap={onSwap}
										isSwapAble={isSwapAble}
										onCaptainSelect={onCaptainSelect}
										onViceCaptainSelect={onViceCaptainSelect}
										onPlaceholderClick={onPlaceholderClick}
										swapPlayerId={swapPlayerId}
										actionLessPlayerIds={actionLessPlayerIds}
										swappedFrom={swappedFrom}
										t={t}
										{...sportSpecificProps}
									/>
								})
							}
						</div>
					);
				})}
			</TeamStyle>
		);
	}
}

export default Team;