import React, { Component } from 'react';
import { Icon } from 'antd';

import { PointsStatsStyle, RowStyle, PointStyle } from './PointsStatsStyle';
import { StatsStyle } from '../Stats/StatsStyle';

import { Col, Row } from '..';

import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	visibleWeekPoints: number | string;
	weekWinnerPoints?: number;
	isPublic?: boolean;
	weekAveragePoints?: number;
	t: any;
};

class Stats extends Component<StatsOverviewProps, StatsOverviewState> {

	render() {
		const { t, isPublic } = this.props;

		return (
			<StatsStyle>
				<Row className="stat-row">
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">{isPublic ? t('pointsPage.publicTeamPoints') : t('pointsPage.yourPoints')}</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{this.props.visibleWeekPoints}</p>
					</Col>
				</Row>
				<Row className="stat-row" style={{ borderTop: '1px solid #3C3C3C' }}>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">{t('pointsPage.averagePoints')}</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{isNaN(this.props.weekAveragePoints as any) ? '-' : Math.floor(this.props.weekAveragePoints as number)}</p>
					</Col>
				</Row>
				<Row className="stat-row" style={{ borderTop: '1px solid #3C3C3C' }}>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">{t('pointsPage.weekWinnerPoints')}</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{this.props.weekWinnerPoints}</p>
					</Col>
				</Row>
			</StatsStyle>
		)
	}
}

export default Stats;