import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import theme from '../../styles/theme';
import { connect } from 'react-redux';
import { Block, Row, Col, Title, TitleType } from '../../components';
import * as userActions from '../../actions/user'
import { Divider } from 'antd';
import { StoreState, ApplicationState } from '../../types';
import { RankingPrizesStyles, PrizesStyles } from './PrizesStyles';
import { getPrizes } from './../../lib/helpers';

type PrizesProps = {
	application: ApplicationState;
}

class Prizes extends Component<PrizesProps> {
	state = {
		prizesMap: {} as { general: string, week: string, mountain: string, sprint: string, 'periode-1': string, 'periode-2': string },
		loaded: false
	}

	componentDidMount() {
		userActions.getPrizes(this.props.application.competition.competitionFeed)
			.then((result: any) => {
				this.setState({ prizesMap: getPrizes(result), loaded: true });
			});
	}

	render() {
		return <PrizesStyles>
			<Row>
				<Col lg={{ span: 20, offset: 2 }}>	
					<Row gutter={[16, 16]}>
						<Col lg={16}>
							<Block>
								<Title type={TitleType.h2}>Prijzen</Title>
									<p>Akkoord, Wielermanager speel je vooral voor de eer en voor de strijd onder vrienden in je minicompetitie. Toch zijn er ook dit jaar enkele leuke prijzen te winnen.</p>
									<Row className="prize-row" style={{background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 1ste plaats</h2>
															Win een schitterende Ridley Fenix en rijd jouw eigen klassiekers! De Fenix is zowel geschikt voor prestatiegerichte ritten als voor comfortabele afstandsuitdagingen. Ruim tien jaar geleden werd de fiets door de Belgische fietsenbouwer ontwikkeld met oog op de klassiekers, doorheen de jaren werd het zoveel meer dan enkel een kasseienvreter. Met schijfremmen en interne kabelgeleiding kruip je in het hoofd van de renners die jou de overwinning hebben bezorgd.
													</Col>
													<Col md={12} xs={24}>
															<img alt="prijs-winnaar-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_place_txt_klas24.jpg" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
									<Row className="prize-row" style={{ borderRadius: '5px', background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 2de en 3de plaats</h2>
															Never stop cycling met de ultieme GPS-fietscomputer op zonne-energie waar je op kunt vertrouwen wanneer je hem het meest nodig hebt.
															<p>Edge 1040 Solar is klaar voor elke rit, van afgelegen, onverharde paden tot onvergetelijke klimtochten.</p>
													</Col>
													<Col md={12} xs={24}>
															<img alt="prijs-tweede-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_2nd_place_txt_klas24.jpg" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
									<Row className="prize-row" style={{ margin: '20px auto',background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Speeldagwinnaar</h2>
															Lidl wordt dit voorjaar verspartner van jouw peloton.

															<p>Win een voorjaar lang gratis vers van Lidl, en beleef onvergetelijke fietsmomenten met jouw vrienden of familie.</p>
													</Col>
													<Col md={12} xs={24}>
															<img alt="speeldagprijs-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_3rd_place_txt_klas24.jpg" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
									<Row className="prize-row" style={{ margin: '20px auto',background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Top 10</h2>
															Win 10x €150 in sportvoeding van 6d Sports Nutrition. 6d is een Belgisch sportvoedingsmerk waar kwaliteit en wetenschap primeren. Hun ruim aanbod sportvoeding is er voor elke gedreven atleet die zijn prestaties wenst te maximaliseren. 6d is onder andere trouwe partner van Soudal Quick-Step en de Red Lions.
													</Col>
													<Col md={12} xs={24}>
															<img alt="eindprijs-sports-nutrition-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_top10_txt_klas24.jpg" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
									<Row className="prize-row" style={{ margin: '20px auto',background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Top 5</h2>
															Win een Decca voucher twv 50 euro en beleef jouw eigen wielermomenten in stijl! Decca, hét merk voor fietskleding en accessoires, staat garant voor hoogwaardige producten die jouw fietservaring naar een hoger niveau tillen. Met een ruim assortiment aan kleding voor zowel pedaleurs de charme als professionele renners, ben jij klaar om te schitteren op de fiets. Van ademende shirts tot comfortabele broeken, Decca voorziet jou van alles wat je nodig hebt om vol vertrouwen de weg op te gaan.
													</Col>
													<Col md={12} xs={24}>
															<img alt="eindprijs-deca-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/deca-prijs.png" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
									<Row className="prize-row" style={{ margin: '20px auto',background: '#1E1F1D' }}>
											<React.Fragment>
													<Col md={12} xs={24} className="prize-body">
															<h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Mewa-minicompetitie</h2>
															Doe mee met de Mewa-minicompetitie en win een unieke prijs.
															<p>Als je ploeg op kop staat in deze mini competitie na Classic Brugge–De Panne dan mag je het Mewa-konvooi vervoegen tijdens Gent-Wevelgem. Beleef deze klassieker vanuit de buik van het peloton.</p>
													</Col>
													<Col md={12} xs={24}>
															<img alt="speeldagprijs-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_league_mewa_txt_klas24.jpg" className="image-prizes-widget" />
													</Col>
											</React.Fragment>
									</Row>
							</Block>
						</Col>
						<Col lg={8}>
								<Block>
										<a href='https://www.garmin.com/nl-BE/'><img alt="garmin_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_garmin.jpg'/></a>
										<a href='https://www.ridley-bikes.com/nl_BE'><img alt="ridley_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_ridley.jpg'/></a>
								</Block>
						</Col>
				</Row>
				</Col>
			</Row>
		</PrizesStyles>
	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Prizes);
