import React, {Component} from "react";
import {UploadStyle} from "./UploadStyle";
import {UploadProps} from "antd/lib/upload";

export const Upload = (props: any) => {
	const {children, ...rest} = props;

	return (<UploadStyle
		{...rest}>
		{children}
	</UploadStyle>);
};

export default Upload;
