import { UserAction } from '../actions/user';
import { UserState, User, Team, League } from '../types/index';
import { HmacSHA256 } from 'crypto-js';
import config from './../config';
import {
	SET_PROFILE_DATA,
	LOG_OUT,
	USER_TEAMS_FETCH_FAILED,
	USER_TEAMS_FETCHED_WITH_SUCCESS,
	USER_TEAMS_LOADING_STARTED,
	USER_LEAGUES_FETCH_FAILED,
	USER_LEAGUES_FETCHED_WITH_SUCCESS,
	USER_LEAGUES_LOADING_STARTED,
	USER_TEAM_SET_TEAM_NAME
} from '../actions/actions-types';
import lockr from 'lockr';

const userData: User = lockr.get('user');


const emptyUserData: User = { firstName: '', lastName: '', id: null };
const emptyUserTeams: Team[] = [];
const emptyUserLeagues: League[] = [];
const defaultState = {
	authenticated: !!userData,
	isFetching: false,
	fetched: false,
	data: userData || emptyUserData,
	teams: emptyUserTeams,
	leagues: emptyUserLeagues,
	leaguesFetched: false
};

if (userData && userData.email && userData.email.length && (window as any).fcWidget && (window as any).fcWidget.user && (window as any).fcWidget.user.setProperties) {
	(window as any).fcWidget.user.setProperties({
		firstName: userData.firstName,
		lastName:  userData.lastName,
		email: userData.email,
	});
}


export function userReducer(state: UserState = defaultState, action: UserAction) {
	switch (action.type) {
		case SET_PROFILE_DATA:
			lockr.set('user', action.data);
			return { ...state, authenticated: true, data: action.data };
		case USER_TEAMS_FETCH_FAILED:
			return { ...state, isFetching: false, fetched: true };
		case USER_TEAMS_LOADING_STARTED:
			return { ...state, isFetching: true };
		case USER_TEAMS_FETCHED_WITH_SUCCESS:
			return {
				...state,
				teams: action.concat ? state.teams.concat(action.teams) : action.teams,
				fetched: true
			};
		case USER_TEAM_SET_TEAM_NAME:
			return {
				...state,
				teams: state.teams.map((team: Team) => {
					return team.id === action.id ? { ...team, name: action.name } : team;
				}),
				fetched: true
			};
		case USER_LEAGUES_FETCH_FAILED:
			return { ...state, isFetching: false, leaguesFetched: true };
		case USER_LEAGUES_LOADING_STARTED:
			return { ...state, isFetching: true };
		case USER_LEAGUES_FETCHED_WITH_SUCCESS:
			return { ...state, leagues: action.leagues, leaguesFetched: true, isFetching: false };
		case LOG_OUT:
			return { ...state, authenticated: false, data: emptyUserData, teams: emptyUserTeams };
	}
	return state;
}