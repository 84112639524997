import React, {Component} from "react";
import {SelectStyle, OptionStyle} from "./SelectStyle";
import {SelectProps} from "antd/lib/select";
import {theme} from './../../../styles/theme-sporza';

const dropDownStyle = {
	color: '#FFF',
	backgroundColor: theme.primaryContrast,
	borderRadius: '5px',
	overflow: 'hidden',
	boxShadow: 'none'
};

const dropDownListItemStyle = {
	color: '#FFF',
	backgroundColor: theme.primaryContrast
}

interface Props {
	values: any[];
	keyProperty: string;
	textProperty: string;
	block?: boolean;
}

export type MySelectProps = SelectProps & Props;

export default class Select extends Component<MySelectProps> {
	render() {
		const {values, keyProperty, textProperty, onChange, ...rest} = this.props;

		return (
			<SelectStyle
				{...rest}
				dropdownStyle={dropDownStyle}
				onChange={onChange as any}>
				{values.map(value => (
					<OptionStyle
						key={value[keyProperty]}
						value={value[keyProperty]}
						style={dropDownListItemStyle}
					>
						{value[textProperty]}
					</OptionStyle>
				))}
			</SelectStyle>
		);
	}
};
