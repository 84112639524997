import React, { Component } from "react";
import { SubstitutesStyle, Title } from "./SubstitutesStyle";
import Player, { PlayerType } from "../Player/Player";
import { Club } from './../../types';
import i18next from "i18next";

interface SubstitutesState {
}

interface SubstitutesProps {
	title: string;
	bgColor?: string;
	playerType: PlayerType;
	selection: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	isSwapAble?: any;
	onRemove?: any;
	onSwap?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	showPositionNumber?: boolean;
	assetsCdn: string;
	showCaptainBadge?: boolean;
	captainId?: number;
	viceCaptainId?: number;
	onPlaceholderClick?: any;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerValueInsteadOfPoints?: boolean;
	clubs: Club[];
	modalEnabled?: boolean;
	t: i18next.TFunction;
}

class Substitutes extends Component<SubstitutesProps, SubstitutesState> {
	render() {
		const {
			title, bgColor, selection, playerType, playerBadgeColor, playerBadgeBgColor, playerPointsColor, captainId, viceCaptainId,
			playerPointsBgColor, onRemove, onSwap, swapPlayerId, swappedFrom, assetsCdn, onPlaceholderClick, showCaptainBadge,
			actionLessPlayerIds, showPlayerValue, showPlayerValueInsteadOfPoints, clubs, modalEnabled, isSwapAble,
			showPositionNumber
		} = this.props;

		return (
			<SubstitutesStyle bgColor={'#131313'}>
				<div className="substitutes">
					{selection.map((player: any, index: number) => {
						const sportSpecificProps: {
							shirtCycling?: string,
							shirtSoccer?: string,
							soccerJersey?: string,
							clubBadge?: string,
							portraitFace?: string,
							shirtFallback?: string;
							portraitFaceFallBack?: string;
							club?: Club
						} = {};

						const club = clubs.find((item: Club, index: number) => player && item.id === player.clubId);
						sportSpecificProps.club = club;

						if ([PlayerType.Cycling, PlayerType.SoccerShirt].includes(playerType) && player) {

							if (PlayerType.Cycling === playerType) {
								sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
							}

							if (PlayerType.SoccerShirt === playerType) {
								sportSpecificProps.shirtSoccer = `${assetsCdn}/jerseys/football/${player.clubId}.png`;
							}

							sportSpecificProps.shirtFallback = `${assetsCdn}/jerseys/dummy.png`;
						}

						if (PlayerType.SoccerPortrait === playerType && player) {
							// sportSpecificProps.soccerJersey = `${assetsCdn}/jerseys/club_${player.clubId}.png`;
							sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${player.clubId}.png`;
							sportSpecificProps.portraitFace = player.portraitUrl;
							sportSpecificProps.portraitFaceFallBack = `${assetsCdn}/players/dummy.png`;
						}

						let positionLabel = '';

						if (showPositionNumber) {
							if (player && player.positionId === 1) {
								positionLabel = this.props.t('player.goalkeeperBenchLabelShort');
							} else {
								positionLabel = index.toString();
							}
						}

						return <React.Fragment key={`substitutes-${index}`}>
							<Player
								key={`substitutes-${index}`}
								pointsColor={playerPointsColor}
								pointsBgColor={playerPointsBgColor}
								badgeColor={playerBadgeColor}
								badgeBgColor={playerBadgeBgColor}
								player={player}
								type={playerType}
								modalEnabled={modalEnabled}
								showPlayerValue={showPlayerValue}
								onRemove={onRemove}
								isSwapAble={isSwapAble}
								captainId={captainId}
								viceCaptainId={viceCaptainId}
								showCaptainBadge={showCaptainBadge}
								showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
								onSwap={onSwap}
								swapPlayerId={swapPlayerId}
								swappedFrom={swappedFrom}
								benchPlayer={true}
								positionLabel={positionLabel}
								onPlaceholderClick={onPlaceholderClick}
								actionLessPlayerIds={actionLessPlayerIds}
								t={this.props.t}
								{...sportSpecificProps}
							/>
						</React.Fragment>;
					})}
				</div>
			</SubstitutesStyle>
		);
	}
}

export default Substitutes;