import styled from 'styled-components';
import { Modal } from 'antd';

import 'antd/lib/modal/style';

const PlayerModalStyle = styled(Modal)`
	.ant-modal-content {
		border-radius: 0px;
		max-width: 375px;

		.ant-modal-title {
			font-family: "C-Bold";
			text-transform: uppercase;
			color: #000;
			background-color: ${props => props.theme.primaryColor};
			padding: 5px 5px 5px 36.5px;
		}

		.ant-modal-close-x {
			width: 30px;
			height: 30px;
			font-size: 20px;
			color: #000;
			line-height: 30px;
		}
	
		.ant-modal-header {
			color: red;
			background-color: #000;
			border: 0px;
			border-radius: 0px;
			padding: 0;

			.ant-modal-title {

				p {
					margin: 0px;
				}
			}
		}

		.ant-modal-body {
			padding: 0;

			p {
				margin-bottom: 0;
			}

			.surname, .forename {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 150px;
			}

			.club a {
				color: #000;
			}

			.action {
				margin: 20px 0;
				text-align: center;
				cursor: pointer;

				.anticon {
					font-size: 30px;
					margin: 10px;
    			display: block;
				}
			}

			.points {
				background-color: ${props => props.theme.primaryColor}; 
				color: #000;
				position: absolute;
				right: 0;
				top: 0px;
				text-align: center;
				padding: 10px;
				margin-bottom: 10px;

				.value {
				}

				.label {
					color: #000;
					display: block;
				}
			}

			.player-header {
				margin: 0px;
				background-color: #84FF00;
				padding: 0;
			}
			.player-body {
				margin: 0px;
				color: #FFF;
				background-color: #000;
			}
		}

		.ant-modal-footer {
			display: none;
		}
	}

	.player-avatar {
		width: 85px;
		height: 80px;
	}
`;

const PointsOverViewTable = styled.table`
	width: 100%;

	thead {
		background: ${props => props.theme.primaryColor};
		color: #000;
	}

	tr {
		th, td {
			padding: 5px;

			&:nth-child(3) {
				text-align: right;
			}
		}
	}
`;

export {
	PlayerModalStyle,
	PointsOverViewTable
};
