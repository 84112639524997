import styled from "../../styles/styled-components";
import mediaQueries from "../../styles/media-queries";

const SidebarStyle = styled.div`
	background-color: white;
	a {
		display: block;
		padding: 15px;

		&.active {
			background-color: ${(props: any) => props.theme.secondaryColor};
			color: #ffffff;

			.team-name {
				.anticon {
					color: #fff !important;
				}
			}
		}

		&.active-primary {
			background-color: ${(props: any) => props.theme.primaryColor};
			color: #ffffff;
		}

		.anticon-caret-right {
			float: right;
		}

		.anticon-credit-card {
			margin-right: 5px;
		}

		.team-name {
			white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	width: 70%;
			display: inline-block;
			
			.badge {
				margin-right: 5px;

				.anticon-check-circle {
					color: ${props => props.theme.secondaryColor};
				}
		
				.anticon-exclamation-circle {
					color: #fb6b5b;
				}
			}
		}
	}

	.ant-divider-horizontal {
		margin: 0px;
	}
`;
export {
	SidebarStyle,
};