import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router-dom';

import * as userActions from '../../actions/user';
import * as leaguesActions from '../../actions/leagues';
import {
	ApplicationState,
	StoreState,
	UserState,
	MatchesState,
} from './../../types';
import {
	ContainerStyle,
	SelectGroupStyle,
	TableStyle,
} from './../../components/PlayerList/PlayerListStyle';
import { TeamsList, Select, Input, CommentList, Upload} from './../../components';
import { Link } from './../../components/UI/Link/Link';
import { Popover } from './../../components/UI/Popover/Popover';
import Hashids from 'hashids';
import config from './../../config';
import CopyToClipboard from 'react-copy-to-clipboard';
import lockr from 'lockr';
import { Element, scroller } from 'react-scroll';
import { Icon, Tabs, notification} from 'antd';
const { TabPane } = Tabs;

import {
	Title,
	TitleType,
	Block,
	Row,
	Col,
	Button,
	LeaguesList,
	Alert,
} from './../../components';

import {
	EmailShareButton,
	FacebookShareButton,
	FacebookMessengerShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	TwitterIcon,
	WhatsappIcon,
  } from "react-share";

export interface Props {
	user: UserState;
	application: ApplicationState;
	matches: MatchesState;
	location: any;
	match: any;
	fetchUserTeamsAndLeagues: any;
	t: any;
	hideMainTitle?: boolean;
}

export interface State {
	selectedLeagueInfo: any;
	selectedLeagueId: number | null;
	searchLeagueName: string;
	searchLeagueResult: any[] | null;
	createLeagueName: string;
	invitedLeagueId: number | null;
	invitedLeagueInfo: any;
	addingNewLeague: boolean;
	selectedWeekId: number;
	initialized: boolean;
	loading: boolean;
	imageUrl: string;
}

function beforeUpload(file: any) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		notification.error({ message: 'You can only upload JPG/PNG file!' });
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		notification.error({ message: 'Image must smaller than 2MB!' });
	}
	return isJpgOrPng && isLt2M;
}

function getBase64(img: any, callback: any) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}
class Leagues extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedLeagueInfo: {},
			selectedLeagueId:
				(props.match.params.leagueId &&
					parseInt(props.match.params.leagueId)) ||
				null,
			searchLeagueName: '',
			createLeagueName: '',
			searchLeagueResult: null,
			invitedLeagueId: null,
			invitedLeagueInfo: {},
			addingNewLeague: false,
			selectedWeekId: -1,
			initialized: false,
			loading: false,
			imageUrl: `${this.props.application.competition.assetsCdn}/files/COVER-DEFAULT.png`
		};
	}

	componentDidMount() {
		this.activate();
	}

	activate() {
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		if (team) {
			leaguesActions.fetchTeamLeagues(team.id)
				.then(response => {
					if (response && response.leagues && response.leagues.length) {
						const league = response.leagues[0];

						this.initLeagueById(league.id)
					} else {
						this.setState({ initialized: true });
					}

					if (lockr.get('inviteCode')) {
						const hashIdInstance = new Hashids(`${this.props.application.competition.competitionFeed}${this.props.application.competition.seasonId}`, 12);
						const invitedLeagueId = hashIdInstance.decode(lockr.get('inviteCode'))[0];

						leaguesActions
							.fetchLeagueInfo(invitedLeagueId)
							.then(invitedLeagueInfo => {
								this.setState({ invitedLeagueId, invitedLeagueInfo });
							});

						scroller.scrollTo('join-leagues', {
							duration: 1000,
							delay: 100,
							smooth: true,
							offset: 100,
						});
					}
				});
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.user.teams.length !== prevProps.user.teams.length) {
			this.activate();
		}


		if (
			this.props.user && this.props.user.teams && this.props.user.teams[0] && this.props.user.teams[0].leagues &&
			prevProps.user && prevProps.user.teams && prevProps.user.teams[0] && prevProps.user.teams[0].leagues &&
			this.props.user.teams[0].leagues.length !== prevProps.user.teams[0].leagues.length &&
			!this.state.selectedLeagueId &&
			this.props.user.teams[0].leagues.length
		) {
			const league = this.props.user.teams[0].leagues[0];

			this.initLeagueById(league.id);
		}
	}


	initLeagueById = (leagueId: number) => {
		return leaguesActions.fetchLeagueInfo(leagueId)
			.then(leagueInfo => {
				this.setState({
					selectedLeagueInfo: leagueInfo,
					selectedLeagueId: leagueId,
					initialized: true,
					imageUrl: (leagueInfo.league.cover) ? leagueInfo.league.cover : `${this.props.application.competition.assetsCdn}/files/COVER-DEFAULT.png`
				});
			});
	};

	loadLeagueById = (id: number, weekId?: number) => {
		const loadingWeekId = weekId || this.state.selectedWeekId;

		leaguesActions
			.fetchLeagueInfo(
				id,
				loadingWeekId && loadingWeekId > 0 ? loadingWeekId : null
			)
			.then(leagueInfo => {
				this.setState({
					selectedLeagueInfo: leagueInfo,
					selectedLeagueId: id,
					initialized: true,
					imageUrl: (leagueInfo.league.cover) ? leagueInfo.league.cover : `${this.props.application.competition.assetsCdn}/files/COVER-DEFAULT.png`
				});
			});
	};

	onLeagueSelect = (leagueId: any) => {
		this.loadLeagueById(leagueId);
	};

	onSearchLeagueNameChange = (e: any) => {
		this.setState({ searchLeagueName: e.target.value });
	};

	onNewLeagueNameChange = (e: any) => {
		this.setState({ createLeagueName: e.target.value });
	};

	onSearch = (e: any) => {
		const hashIdInstance = new Hashids(`${this.props.application.competition.competitionFeed}${this.props.application.competition.seasonId}`, 12);
		const invitedLeagueId = hashIdInstance.decode(this.state.searchLeagueName)[0];
		//todo error if code not found

		leaguesActions
			.fetchLeagueInfo(invitedLeagueId)
			.then(invitedLeagueInfo => {
				this.setState({ invitedLeagueId, invitedLeagueInfo });
			});
	};

	onLeagueCreate = (e: any) => {
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		if (team) {
			this.setState({ addingNewLeague: true });

			leaguesActions
				.createLeague(
					this.state.createLeagueName,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					team.id
				)
				.then((result: any) => {
					this.setState({ createLeagueName: '' });
					this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
					this.loadLeagueById(result.league.id);
					this.setState({ addingNewLeague: false });
				});
		}
	};

	onJoinLeague = (league: any) => {
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		if (team) {
			leaguesActions.joinLeague(league.id, team.id).then(joinResult => {
				this.setState({
					searchLeagueResult: null,
					searchLeagueName: ''
				});
				this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
				this.loadLeagueById(league.id);
			});
		}
	};

	onInviteLeagueCancel = () => {
		lockr.rm('inviteCode');
		this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
	};

	onInviteLeagueJoin = (league: any) => {
		lockr.rm('inviteCode');
		this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
		this.onJoinLeague(league);
	};

	onTeamKick = (teamId: number, leagueId: number) => {
		if (this.state.selectedLeagueId) {
			leaguesActions.leaveLeague(leagueId, teamId).then(() => {
				this.loadLeagueById(leagueId);
			});
		}
	};

	onLeagueLeave = () => {
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		if (team) {
			if (team && team.id && this.state.selectedLeagueId) {
				leaguesActions
					.leaveLeague(this.state.selectedLeagueId, team.id)
					.then(() => {
						this.setState({
							searchLeagueResult: null,
							searchLeagueName: '',
							selectedLeagueId: null,
						});
						this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
					});
			}
		}
	};

	onLeagueRemove = () => {
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		if (team && team.id && this.state.selectedLeagueId) {
			leaguesActions.removeLeague(this.state.selectedLeagueId).then(() => {
				this.setState({
					searchLeagueResult: null,
					searchLeagueName: '',
					selectedLeagueId: null,
				});
				this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
			});
		}
	};

	onWeekSelect = (weekId: any) => {
		this.setState({ selectedWeekId: weekId });
		const { selectedLeagueId } = this.state;
		if (selectedLeagueId) {
			this.loadLeagueById(selectedLeagueId, weekId);
		}
	};

	onLeagueWeekIdChange = (weekId: any) => {
		return leaguesActions.editLeague(this.state.selectedLeagueInfo.league.id, weekId)
			.then(() => {
				this.loadLeagueById(this.state.selectedLeagueInfo.league.id);
			});
	};

	handleUpload = (f:any) => {
		this.setState({ loading: true });

		leaguesActions.uploadCover(f, this.state.selectedLeagueInfo.league.id, this.state.selectedLeagueInfo.league.name)
			.then((result: any) => {
				getBase64(f, (imageUrl: any) =>
					this.setState({
						imageUrl: imageUrl,
						loading: false,
					}),
				);

				notification.success({ message: this.props.t('leaguesPage.coverUploaded') });
			})
			.catch((err: any) => {
				console.log(err);
				notification.error({ message: this.props.t('leaguesPage.uploadFailed') });
			})
	}

	render() {
		const { user, t } = this.props;
		const {
			selectedLeagueId,
			selectedLeagueInfo,
			searchLeagueName,
			createLeagueName,
			searchLeagueResult,
			invitedLeagueId,
			invitedLeagueInfo,
			addingNewLeague,
			selectedWeekId,
			initialized,
			imageUrl
		} = this.state;
		const teams = selectedLeagueInfo && selectedLeagueInfo.result && selectedLeagueInfo.result.data ? selectedLeagueInfo.result.data : [];
		const totalRecords = selectedLeagueInfo && selectedLeagueInfo.result ? selectedLeagueInfo.result.totalRecords : 0;
		const isAdmin =
			selectedLeagueInfo &&
			selectedLeagueInfo.league &&
			selectedLeagueInfo.league.admin === user.data.id;
		const team = user && user.teams && user.teams[0];
		const teamLeagues = (team && team.leagues) || []

		const joinIgnoreLeagueIds = teamLeagues.map((league: any) => league.id);
		let selectedLeagueInviteUrl = '';

		const currentWeek = this.props.matches.weeks.find(week => week.weekId === this.props.matches.info.displayWeek);
		const displayWeek = this.props.matches.weeks.find((week: any) => currentWeek && week && currentWeek.weekId === week.weekId);

		let weeks = this.props.matches.weeks
			.map((week: any) => {
				return {
					id: week.weekId,
					name: `${t('general.footballWeek')} ${week.weekId}`,
				};
			});

		let changeLeaguePossibleWeeks = this.props.matches.weeks
			.map((week: any) => {
				return {
					id: week.weekId,
					name: `${t('general.footballWeek')} ${week.weekId}`,
				};
			});

		weeks.unshift({ id: -1, name: t('general.footballAllWeeks') });

		if (selectedLeagueId) {
			const hashIdInstance = new Hashids(`${this.props.application.competition.competitionFeed}${this.props.application.competition.seasonId}`, 12);
			selectedLeagueInviteUrl = `${hashIdInstance.encode(selectedLeagueId)}`;
		}

		return (
			<React.Fragment>
				{user.fetched && !team && <Redirect to={{ pathname: `/new` }} />}

				<Row>
					<Col md={24}>
						<Block>
							{ !this.props.hideMainTitle ? 
								<React.Fragment>
									<Title type={TitleType.h2}>{t('leaguesPage.leaguesTitle')}</Title>
								</React.Fragment>
							: null }

							{(invitedLeagueInfo &&
								invitedLeagueInfo.league &&
								invitedLeagueInfo.league.id &&
								joinIgnoreLeagueIds.indexOf(invitedLeagueInfo.league.id) ===
								-1) ?
									<div style={{ padding: '50px' , textAlign: 'center' }}>
										<Alert
											message={`${t('leaguesPage.invitedLeagueConfirmationPrefix')} ${
												invitedLeagueInfo.league.name
												}?`}
											type="info"
											showIcon
										/>
										<Button
											onClick={this.onInviteLeagueCancel}
											type="danger"
											style={{ margin: '5px' }}
										>
											{t('general.confirmationModalNoButtonLabel')}
										</Button>

										<Button
											onClick={(e: any) =>
												this.onInviteLeagueJoin(invitedLeagueInfo.league)
											}
											type="primary"
											style={{ margin: '5px' }}
										>
											{t('general.confirmationModalYesButtonLabel')}
										</Button>
									</div>
							:
							(initialized &&
								<Tabs defaultActiveKey="1" tabBarStyle={{
									border: 'none',
									padding: '5px 0'
									// paddingTop: "100px",
									// backgroundImage: `url(${imageUrl})`,
									// backgroundSize: "cover",
									// backgroundRepeat: "no-repeat",
									// backgroundPosition: "center"
								}}>
									{ selectedLeagueId ?
									<TabPane tab={selectedLeagueInfo.league.name} key="1">
				<Row gutter={[16, 16]}>
													<Col md={12}>
														<Select
															keyProperty={'id'}
															value={selectedLeagueId}
															style={{ width: '100%' }}
															notFoundContent={t('leaguesPage.dropDownNoLeague')}
															textProperty={'name'}
															onSelect={this.onLeagueSelect}
															values={teamLeagues}
														/>
													</Col>
													<Col md={12}>
														<Select
															keyProperty={'id'}
															style={{ width: '100%' }}
															value={selectedWeekId}
															notFoundContent={'No weeks.'}
															textProperty={'name'}
															onSelect={this.onWeekSelect}
															values={weeks}
														/>
													</Col>
												</Row>
												<Row gutter={[16, 16]}>
													<Col md={24}>
														<TeamsList
															data={teams}
															application={this.props.application}
															leagueId={selectedLeagueId ? selectedLeagueId : 0}
															weekId={selectedWeekId}
															weeks={this.props.matches.info}
															size={10}
															totalRecords={totalRecords}
															showHeader={true}
															adminTeamId={(team && team.id) || null}
															isAdmin={isAdmin}
															onTeamKick={(team: any) =>
																this.onTeamKick(team.id, selectedLeagueId)
															}
														/>
													</Col>
												</Row>
												<Row style={{ margin: '5px auto 20px', textAlign: 'center' }}>
													{
														selectedLeagueInfo && selectedLeagueInfo.league && selectedLeagueInfo.league.id ?
															<Alert
																message={`De puntentelling begint vanaf speeldag ${selectedLeagueInfo.league.weekId}`}
																type="info"
															/> : null
													}
												</Row>
									</TabPane>
									: null}
									<TabPane tab={t('leaguesPage.leaguesSetup')} key="2">
										{!selectedLeagueId && (
											<Alert
												message={t('general.youTeamDoesNotBelongToAnyLeagueMessage')}
												type="info"
												showIcon
												style={{margin: '10px'}}
											/>
										) || null}
										
										{ selectedLeagueId ? 
											<React.Fragment>
											<Row style={{ marginBottom: '20px', borderBottom: "1px solid #3C3C3C"}}>
												<Col md={18} sm={24} xs={24}>
														<Alert
															message={t('leaguesPage.inviteLeagueDescription')}
															type="info"
															showIcon
														/>
														
														<Input
															disabled={true}
															value={selectedLeagueInviteUrl}
														/>

														<CopyToClipboard text={selectedLeagueInviteUrl}>
															<Button type="primary" style={{ marginTop: '3px' }} size="small">
																<Icon type="copy" /> {t('leaguesPage.copyInviteLeagueButtonLabel')} </Button>
														</CopyToClipboard>

														{/* <div style={{margin: "20px auto"}}>
															<FacebookShareButton url={selectedLeagueInviteUrl}>
																<FacebookIcon size={32} />
															</FacebookShareButton>
															<FacebookMessengerShareButton appId="1615176165408821" url={selectedLeagueInviteUrl}>
																<FacebookMessengerIcon size={32} />
															</FacebookMessengerShareButton>
															<WhatsappShareButton url={selectedLeagueInviteUrl}>
																<WhatsappIcon size={32} />
															</WhatsappShareButton>
															<EmailShareButton url={selectedLeagueInviteUrl}>
																<EmailIcon size={32} />
															</EmailShareButton>
															<TwitterShareButton url={selectedLeagueInviteUrl}>
																<TwitterIcon size={32} />
															</TwitterShareButton>
														</div> */}

												</Col>
												<Col md={6} sm={24} xs={24}>
													<h4>{t('leaguesPage.leagueStartingWeekLabel')}</h4>
														{isAdmin && selectedLeagueInfo && selectedLeagueInfo.league && selectedLeagueInfo.league.id ?
															<Select
																keyProperty={'id'}
																style={{ maxWidth: '150px', margin: '0 auto' }}
																value={selectedLeagueInfo.league.weekId}
																notFoundContent={'No weeks.'}
																textProperty={'name'}
																onSelect={this.onLeagueWeekIdChange}
																values={changeLeaguePossibleWeeks}
															/>
														: null}

														{(isAdmin && (
															<Row style={{ marginTop: '20px'}}>
																{/* <Upload
																	name="cover"
																	accept=".png,.jpeg,.jpg,.gif"
																	action={this.handleUpload}
																	beforeUpload={beforeUpload}
																>
																	<Button type="primary" style={{ marginTop: '3px' }} size="small">
																		<Icon type="upload" /> {t('leaguesPage.cover')}
																	</Button>
																</Upload> */}
																
																<Button
																	style={{ margin: '3px' }}
																	onClick={this.onLeagueRemove}
																	type="danger"
																	size="small"
																>
																	<Icon type="delete" />
																	{t('leaguesPage.removeLeague')}
																</Button>
															</Row>
														)) || null}

														
														<Row style={{ marginTop: '20px'}}>
															<Button
																style={{ margin: '3px' }}
																onClick={this.onLeagueLeave}
																type="danger"
																size="small"
															>
																<Icon type="logout" />
																{t('leaguesPage.leaveLeague')}
															</Button>
														</Row>
												</Col>
											</Row>
										</React.Fragment>
										: null }
										
										<Row>
											<Col md={12} sm={12} xs={12}>
												<Element name="join-leagues">
														<h3>{t('leaguesPage.searchLeagueTitle')}</h3>

														<span style={{ width: '40%', display: 'inline-block' }}><Input
															onChange={this.onSearchLeagueNameChange}
															placeholder={t('leaguesPage.searchLeagueByNamePlaceholder')}
															value={searchLeagueName}
														/>
														</span>
														<span style={{ width: '20%', display: 'inline-block', paddingLeft: '10px' }}>
															<Button
																type="primary"
																disabled={!searchLeagueName.length}
																onClick={this.onSearch}
																size="large"
															>
																{t('general.search')}
															</Button>
														</span>
														{(searchLeagueResult && (
															<div style={{ marginTop: '5px' }}>
																<LeaguesList
																	size={5}
																	showHeader={false}
																	t={t}
																	isRowExpandable={true}
																	noLeaguesMessage={t('leaguesPage.emptyLeaguesListTableMessage')}
																	onJoin={this.onJoinLeague}
																	joinIgnoreLeagueIds={joinIgnoreLeagueIds}
																	isLoading={false}
																	data={searchLeagueResult.map((item, index) =>
																		Object.assign({}, item, { recordNumber: index + 1 })
																	)}
																/>
															</div>
														)) ||
															null}
												</Element>
											</Col>
											<Col md={12} sm={12} xs={12}>
													<h3>{t('leaguesPage.newLeagueTitle')}</h3>
													<span style={{ width: '40%', display: 'inline-block' }}><Input
														onChange={this.onNewLeagueNameChange}
														placeholder={t('leaguesPage.leagueNamePlaceholder')}
														value={createLeagueName}
													/>
													</span>
													<span style={{ width: '20%', display: 'inline-block', paddingLeft: '10px' }}>
													<Button
														type="primary"
														disabled={createLeagueName.length <= 3 || addingNewLeague}
														onClick={this.onLeagueCreate}
														size="large"
													>
														{t('leaguesPage.newLeagueButtonLabel')}
													</Button>
													</span>
											</Col>
										</Row>
									</TabPane>
								</Tabs>
							)}
						</Block>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
};

export function mapStateToProps({
	user,
	application,
	matches,
}: StoreState.All) {
	return {
		user,
		application,
		matches,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Leagues);