import React, { Component } from "react";
import { Form, notification } from 'antd';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user';
import { Row, Col, Button, Input, TitleType, Title, FormItem, Block } from '../../components';

import config from '../../config';
import { ForgotStyles } from "./ForgotStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { Redirect } from "react-router";
import { StoreState, UserState, ApplicationState } from '../../types';

export interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	application: ApplicationState;
	t: any;
}

export interface ValidationObject {
	status: string,
	message: string
}

export interface ForgotFormValues {
	email: string,
	emailValidation: ValidationObject,
	redirectToHome: boolean;
}

const defaultValidationObject = { status: '', message: '' };

const validateEmail = (email: string) => {
	var re = /\S+@\S+\.\S+/;
	return re.test(email);
};

class Forgot extends Component<Props, ForgotFormValues> {
	state = {
		email: '',
		emailValidation: defaultValidationObject,
		redirectToHome: false
	}

	handleSubmit = (e: any) => {
		e.preventDefault();

		if (this.state.emailValidation.status !== 'error') {
			userActions.forgot(this.state.email)
				.then(result => {
					notification.success({ message: this.props.t('user.forgotPasswordSuccess') });
					this.setState({ redirectToHome: true });
				})
				.catch((error:any) => {
					console.log(error)
					notification.error({ message: 'Er ging iets verkeerd. Contacteer ons via de chat.' });
					this.setState({ redirectToHome: true });
				});
		}
	}

	onEmailChange = (e: any) => {
		const newState = { email: e.target.value, emailValidation: this.state.emailValidation };
		if (!e.target.value) {
			newState.emailValidation = { status: 'error', message: this.props.t('user.forgotPasswordEmailRequired') };
		} else if (!validateEmail(e.target.value)) {
			newState.emailValidation = { status: 'error', message: this.props.t('user.forgotPasswordInvalidEmail') };
		} else {
			newState.emailValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	render() {
		const { email, emailValidation } = this.state;
		const { t } = this.props;

		return (
			<ForgotStyles>
				{this.state.redirectToHome && <Redirect
					to={{ pathname: `/home` }}
				/>}
				<PageStyle>
					<Row>
						<Col md={{ span: 10, offset: 7 }} sm={24} xs={24}>
							<Form className="forgot-form">
								<Title type={TitleType.h2}>{t('user.forgotPassword')}</Title>
								<Block>
									<FormItem
										style={{padding: "20px 20px 0"}}
										validateStatus={emailValidation.status}
										help={emailValidation.message}>
										<Input
											type="email"
											value={email}
											name="email"
											onChange={this.onEmailChange}
											placeholder={t('user.userEmailPlaceholder')} />
									</FormItem>

									<Button type="primary" onClick={this.handleSubmit} htmlType="submit">{t('user.forgotPasswordTrigger')}</Button>
									<Link to={`/home`} style={{ textDecoration: 'underline', margin: "20px", padding: "20px", display: "block" }}>Ga terug naar aanmelden</Link>
								</Block>
							</Form>
						</Col>
					</Row>
				</PageStyle>
			</ForgotStyles>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
