import styled from "../../styles/styled-components";
import mediaQueries from "../../styles/media-queries";

const News = styled.div`
	.feed {
		padding: 10px;
		.title {
			font-weight: bolder;
			text-align: justify;			
		}

		.image-container {
			img {
				width: 100%;
			}
		}

		.description {
			text-align: justify;
		}

		.more {
			text-align: right;
		}
	}
`;

export {
	News,
};