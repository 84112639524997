import React, { Component } from "react";
import { Icon, notification } from 'antd';
import moment from 'moment';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user'
import { Row, Col, Block, Title, TitleType, Button, PricingTable } from '../../components';

import { StoreState, UserState, ApplicationState } from '../../types';

interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
	t: any;
}

export interface State {
    
}

class Thanks extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
            
		};
    };

    componentDidMount() {
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init('823483751796412');
        ReactPixel.default.track('Purchase', {currency: "EUR", value: 9.90});
    }
    
	render() {
		const { user, t } = this.props;

		return (
			<React.Fragment>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>{t('shop.thanks')}</Title>
                            <div style={{textAlign: "center", padding: "10px 50px 50px"}}><h4 style={{color: "#FFF"}}>{t('shop.premiumMemberMessage')}</h4></div>

                            <Row className="onboarding">
                                {/* <Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/1-Team.png'></img></Col> */}
                                <Col lg={{span: 12, offset: 6}}>
                                    <h4 className="title">NODIG JE VRIENDEN UIT</h4>
                                    <p>Fan Arena Fantasy is nog leuker als je tegen je vrienden, familie of collega’s speelt.</p>
                                    <p>Nu je Premium bent, kan je zelf een minicompetitie aanmaken. Nodig vervolgens iedereen uit en strijd voor de titel van Fan Arena Baas!</p>
                                </Col>
                            </Row>
                            <Row className="onboarding">
                                {/* <Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/1-Team.png'></img></Col> */}
                                <Col lg={{span: 12, offset: 6}}>
                                    <h4 className="title">WORD LID VAN DE COMMUNITY</h4>
                                    <p>Word lid van de Facebookgroep “Fantasy Pro League” en blijf op de hoogte van de laatste ontwikkelingen, nieuw toegevoegde spelers, etc. Zoek je nog volk om tegen te spelen? Stel je vraag!</p>
                                </Col>
                            </Row>
                            <Row className="onboarding">
                                {/* <Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/1-Team.png'></img></Col> */}
                                <Col lg={{span: 12, offset: 6}}>
                                    <h4 className="title">DOE JE RESEARCH</h4>
                                    <p>Als Premium heb je handige tools om je team samen te stellen. Bespioneer je tegenstanders. Snuffel in de statspagina naar data van goudwaarde. En check de verwachte opstellingen van elk team.</p>
                                </Col>
                            </Row>
                            <Row className="onboarding" style={{paddingBottom: "50px"}}>
                                {/* <Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/1-Team.png'></img></Col> */}
                                <Col lg={{span: 12, offset: 6}}>
                                    <h4 className="title">GEBRUIK POWER SUBS</h4>
                                    <p>Met een 'power sub' kan je tijdens het voetbalweekend een bankzitter (die nog niet speelde) handmatig in je basis droppen. Bijvoorbeeld in plaats van een speler die speelde maar misschien te weinig scoorde.</p>
                                </Col>
                            </Row>
						</Block>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Thanks);