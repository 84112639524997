import styled from "styled-components";
import {Button} from 'antd';

import 'antd/lib/button/style';

export const ButtonStyle = styled(Button)`

		&:hover {
			color: #000;
		}

		border: none !important;
    border-radius: 100px;
    margin: 5px auto;

	&.ant-btn-primary {
		color: #000;
		background-color: #84FF00;
	}

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
		padding-left: 40px;
		
		&.ant-btn-sm {
			padding-left: 21.5px;

			span {
				margin-left: 5px;
			}
		}
    }

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
        margin-left: 0;
    }
`;