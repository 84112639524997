/* eslint-disable default-case */
import React, { Component } from 'react';
import { Icon } from 'antd';

import { PlayerModalStyle, PointsOverViewTable } from './PlayerModalStyle';
import { Player, Club } from './../../types';
import { theme } from "../../styles/theme-sporza";
import { getPlayerPositionHexColor } from './../../lib/helpers';
import { Link } from '../UI/Link/Link';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg, PlayerStyle, ClubBadgeBg, SoccerJersey } from './../Player/PlayerStyle';
import { Row, Col, PlayerType } from './../../components';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg, ViceCaptainButtonSvg } from './../../styles/custom-icons';
import { firstLetterUppercased } from './../../lib/helpers';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;
const ViceCaptainIcon = (props: any) => <Icon component={ViceCaptainButtonSvg} {...props} />;
const DeleteIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const SwapIcon = (props: any) => <Icon component={SwapButtonSvg} {...props} />;
const UndoIcon = (props: any) => <Icon component={RollBackSvg} {...props} />;

const PlayerActionsPoints: any = {
	yellowCard: { 1: -1, 2: -1, 3: -1, 4: -1 },
	redCard: { 1: -3, 2: -3, 3: -3, 4: -3 },
	ownGoal: { 1: -2, 2: -2, 3: -2, 4: -2 },
	playedUpTo60Min: { 1: 1, 2: 1, 3: 1, 4: 1 },
	playedMoreThan60Min: { 1: 2, 2: 2, 3: 2, 4: 2 },
	drawMatch: { 1: 0, 2: 0, 3: 0, 4: 0 },
	assist: { 1: 3, 2: 3, 3: 3, 4: 3 },
	goal: { 1: 6, 2: 6, 3: 5, 4: 4 },
	missedPenalty: { 1: -2, 2: -2, 3: -2, 4: -2 },
	stoppedPenalty: { 1: 5, 2: 0, 3: 0, 4: 0 },
	cleanSheet: { 1: 4, 2: 4, 3: 1, 4: 0 },
	goalTaken: { 1: -1, 2: -1, 3: 0, 4: 0 },
	// mvpPoints: {},
	skillIndex: {},
	// bonus: {},
	shotOffTarget: {},
	errorLeadToGoal: {},
	saves: {},
	// bigChanceCreated: {},
	// headClearance: {},
	// ballRecovery: {},
	duels: {},
	// interception: {}
};

export interface PlayerModalState {
}

export interface PlayerModalProps {
	visible: boolean;
	player: Player;
	club: Club;
	shirtCycling?: string;
	portraitFace?: string;
	portraitFaceFallBack?: string;
	clubBadge?: string;
	onCancel: any;
	onCaptainSelect?: any;
	onViceCaptainSelect?: any;
	onRemove?: any;
	isSwapAble?: any;
	onSwap?: any;
	type?: PlayerType;
	shirtFallback?: string;
	swapPlayerId?: number | null;
	soccerJersey?: string;
	t: any;
}

class PlayerModal extends Component<PlayerModalProps, PlayerModalState> {

	onRemove = (event: any) => {
		this.props.onRemove(this.props.player);
		this.props.onCancel(event);
	};

	onCaptainSelect = (event: any) => {
		this.props.onCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onViceCaptainSelect = (event: any) => {
		this.props.onViceCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onSwap = (event: any) => {
		this.props.onSwap(this.props.player);
		this.props.onCancel(event);
	}

	getPointsOverviewList = (player: any) => {

		const pointsOverviewArray = Array.isArray(player.pointsOverview) ? player.pointsOverview : [player.pointsOverview];
		
		return pointsOverviewArray.map((matchPointsOverview: any, index: number) => {
		const pointsOverview: any = [];
		// const matchOverview = (Array.isArray(player.pointsOverview)) ? player.pointsOverview[0] : player.pointsOverview;
		console.log('pointsOverview', pointsOverview, player.name, matchPointsOverview);
		Object.keys(PlayerActionsPoints)
			.map((actionName: string) => {
				switch (actionName) {
					case 'yellowCard': {
						const yellowCards = matchPointsOverview.yellow_cards || [];
						const redCards = matchPointsOverview.red_card || [];
						redCards.length <= 0 ? yellowCards.length > 0 ? pointsOverview.push({ action: this.props.t('player.yellowCardLabel'), quantity: yellowCards.length, points: yellowCards.length * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					case 'redCard': {
						const redCards = matchPointsOverview.red_card || [];
						redCards.length > 0 ? pointsOverview.push({ action: this.props.t('player.redCardLabel'), quantity: redCards.length, points: redCards.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'ownGoal': {
						const ownGoals = matchPointsOverview.own_goals || [];
						ownGoals.length > 0 ? pointsOverview.push({ action: this.props.t('player.ownGoalLabel'), quantity: ownGoals.length, points: ownGoals.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'playedUpTo60Min': {
						const playedLessThan60Min = player.pointsOverview && matchPointsOverview.time && matchPointsOverview.time < 60;

						if (playedLessThan60Min) {
							pointsOverview.push({ action: this.props.t('player.playedUpTo60MinLabel'), quantity: 1, points: PlayerActionsPoints[actionName][player.positionId] });
						}
						break;
					};

					case 'playedMoreThan60Min': {
						const playedMoreThan60Min = player.pointsOverview && matchPointsOverview.time && matchPointsOverview.time >= 60;

						if (playedMoreThan60Min) {
							pointsOverview.push({ action: this.props.t('player.playedMoreThan60MinLabel'), quantity: 1, points: PlayerActionsPoints[actionName][player.positionId] });
						}
						break;
					};

					// case 'drawMatch': {
					// 	const drawMatches = matchPointsOverview.drawMatches || (matchPointsOverview.time >= 50 && matchPointsOverview.result === 'D' ? 1 : 0) || 0;
					// 	pointsOverview.push({ action: this.props.t('player.drawMatchLabel'), quantity: drawMatches, points: drawMatches * PlayerActionsPoints[actionName][player.positionId] });
					// 	break;
					// };

					case 'assist': {
						const assists = matchPointsOverview.assists || [];
						assists.length > 0 ? pointsOverview.push({ action: this.props.t('player.assistLabel'), quantity: assists.length, points: assists.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'goal': {
						const goals = matchPointsOverview.goals || [];
						goals.length > 0 ? pointsOverview.push({ action: this.props.t('player.goalLabel'), quantity: goals.length, points: goals.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'missedPenalty': {
						const penaltyMissed = matchPointsOverview.penalty_miss || [];
						penaltyMissed.length > 0 ? pointsOverview.push({ action: this.props.t('player.missedPenaltyLabel'), quantity: penaltyMissed.length, points: penaltyMissed.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'stoppedPenalty': {
						const stoppedPenalty = matchPointsOverview.penalty_saved || [];
						stoppedPenalty.length > 0 ? pointsOverview.push({ action: this.props.t('player.stoppedPenaltyLabel'), quantity: stoppedPenalty.length, points: stoppedPenalty.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'cleanSheet': {
						const cleanSheet = matchPointsOverview.conceeded ?
							(matchPointsOverview.time >= 60 && matchPointsOverview.conceeded.length === 0 ? 1 : 0) : 0
							player.positionId < 4 ? cleanSheet ? pointsOverview.push({ action: this.props.t('player.cleanSheetLabel'), quantity: cleanSheet, points: cleanSheet * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					case 'goalTaken': {
						const goalTaken = matchPointsOverview.conceeded || [];
						const countsAsTwo = parseInt(`${goalTaken.length / 2}`);
						player.positionId < 3 ? goalTaken ? pointsOverview.push({ action: this.props.t('player.goalTakenLabel'), quantity: countsAsTwo, points: countsAsTwo * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					// case 'bonus': {
					// 	const bonus = matchPointsOverview.bonus || false;
					// 	bonus ? pointsOverview.push({ action: this.props.t('player.bonusLabel'), quantity: 1, points: bonus }) : null
						
					// 	break;
					// };

					case 'duels': {
						const duels = matchPointsOverview.duelWon - matchPointsOverview.duelLost || 0;
						pointsOverview.push({ action: this.props.t('player.duels'), quantity: duels, points: duels > 0 ? 1 : 0 })

						break;
					};

					case 'interception': {
						const interception = matchPointsOverview.interception || false;
						(interception > 1) ? pointsOverview.push({ action: this.props.t('player.interception'), quantity: interception, points: 1 }) : null

						break;
					};

					case 'shotOffTarget': {
						const shotOffTarget = matchPointsOverview.shotOffTarget || false;
						shotOffTarget ? pointsOverview.push({ action: this.props.t('player.shotOffTarget'), quantity: shotOffTarget, points: (shotOffTarget % 2 !== 0) ? -(shotOffTarget-1)/2 : -shotOffTarget/2 }) : null

						break;
					};

					case 'errorLeadToGoal': {
							const errorLeadToGoal = matchPointsOverview.errorLeadToGoal || false;
							errorLeadToGoal ? pointsOverview.push({ action: this.props.t('player.errorLeadToGoal'), quantity: errorLeadToGoal, points: -errorLeadToGoal }) : null

							break;
					};

					case 'saves': {
							const saves = matchPointsOverview.saves || false;
							const savePoints = (saves % 2 !== 0) ? ((saves-1)/2) : (saves/2);

							saves ? pointsOverview.push({ action: this.props.t('player.saves'), quantity: saves, points: savePoints }) : null

							break;
					};

					case 'bigChanceCreated': {
							const bigChanceCreated = matchPointsOverview.bigChanceCreated || false;
							(bigChanceCreated > 1) ? pointsOverview.push({ action: this.props.t('player.bigChanceCreated'), quantity: bigChanceCreated, points: 1 }) : null

							break;
					};
					case 'headClearance': {
							const headClearance = matchPointsOverview.headClearance || false;
							(headClearance > 1) ? pointsOverview.push({ action: this.props.t('player.headClearance'), quantity: headClearance, points: 1 }) : null

							break;
					};
					case 'ballRecovery': {
							const ballRecovery = matchPointsOverview.ballRecovery || false;
							(ballRecovery>7 && player.positionId>1) ? pointsOverview.push({ action: this.props.t('player.ballRecovery'), quantity: ballRecovery, points: 1 }) : null

							break;
					};
					case 'skillIndex': {
						const skillIndex = matchPointsOverview.skillIndex || false;
						skillIndex ? pointsOverview.push({ action: this.props.t('player.skillIndexLabel'), quantity: skillIndex, points: (skillIndex !== 0) ? 
								(4 - skillIndex) : 0 }) 
						: null

						break;
					};
				}
			});
			console.log(pointsOverview)

		return pointsOverview;

		});
	};

	render() {
		const { player, shirtCycling, club, onCaptainSelect, onRemove, onSwap, swapPlayerId, shirtFallback, isSwapAble, type, portraitFace,
			portraitFaceFallBack, clubBadge, soccerJersey, onViceCaptainSelect, t } = this.props;

		const PositionLabels: any = {
			0: t('player.coach'),
			1: t('player.goalkeeper'),
			2: t('player.defender'),
			3: t('player.midfielder'),
			4: t('player.attacker')
		};
		const playerName = (player && `${player.surname} ${player.forename && firstLetterUppercased(player.forename)}.`);

		const playerPositionColor = getPlayerPositionHexColor(player, theme);

		const showPointsOverview = player && player.pointsOverview && player.points !== null && player.points !== undefined;
		let pointsOverview: any = [];

		if (showPointsOverview && player.pointsOverview.length !== 0) {
			pointsOverview = this.getPointsOverviewList(player);
		}
		console.log('pointsOverview', pointsOverview);

		const title = <span>{playerName}</span>;

		let totalActions = 0;

		if (onCaptainSelect) {
			totalActions += 1;
		}

		if (onViceCaptainSelect) {
			totalActions += 1;
		}

		if (onSwap && (player.id !== swapPlayerId)) {
			totalActions += 1;
		}

		if (onSwap && (player.id === swapPlayerId)) {
			totalActions += 1;
		}

		if (onRemove) {
			totalActions += 1;
		}

		const actionColumnSize = 24 / totalActions;

		return (
			<PlayerModalStyle
				title={`${player.forename} ${player.surname}`}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={[]}
			>
				<Row className="player-header">
					<Col md={6} sm={6} xs={6}>
						<PlayerStyle className="player-avatar">
							{PlayerType.SoccerShirt === type ? <PlayerBg style={{ position: 'inherit', maxWidth: '90%' }} src={shirtCycling} fallback={shirtFallback} /> : null}
							{PlayerType.SoccerPortrait === type ? <PlayerBg src={portraitFace} /> : null}
							{PlayerType.SoccerPortrait === type ? <ClubBadgeBg src={clubBadge} /> : null}
							{PlayerType.SoccerPortrait === type ? <SoccerJersey bg={soccerJersey} /> : null}
						</PlayerStyle>
					</Col>
					<Col md={18} sm={18} xs={18}>
						<p className={'surname'}>{player.forename} {player.surname}</p>
						<p className={'club'}>{club.name}</p>
						<p className={'position'} style={{ color: playerPositionColor }}>{PositionLabels[player.positionId]}</p>

						{
							showPointsOverview ?
								<span className={'points'}>
									<span className={'value'}>{player.points}</span>
									<span className={'label'}>{t('general.points')}</span>
								</span> :
								null
						}
					</Col>
					{/* <Col md={24}>
						{
							showPointsOverview ?
								<span className={'points'}>
									<span className={'value'}>{player.points}</span>
									<span className={'label'}>{t('general.points')}</span>
								</span> :
								null
						}
					</Col> */}
				</Row>
				<Row className="player-body">
					{
						onCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onCaptainSelect}>
									<CaptainIcon />
									{t('player.captainBadgeLabel')}
								</div>
							</Col> :
							null
					}
					{
						onViceCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onViceCaptainSelect}>
									<ViceCaptainIcon />
									{t('player.viceCaptainBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onSwap && isSwapAble && isSwapAble(player) && (player.id !== swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<SwapIcon />
									{t('player.swapBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onSwap && (player.id === swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<UndoIcon />
									{t('player.undoBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onRemove && !swapPlayerId ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onRemove}>
									<DeleteIcon />
									{t('player.removeBadgeLabel')}
								</div>
							</Col> :
							null
					}
				</Row>
				{
                    showPointsOverview ?
                        <PointsOverViewTable>
                            <thead>
                                <tr>
                                    <th>{t('player.breakDownTableActionColumnName')}</th>
                                    <th>{t('player.breakDownTableQuantityColumnName')}</th>
                                    <th>{t('player.breakDownTablePointsColumnName')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pointsOverview
                                    .map((match: any, index: number) => {
                                        return(
                                            <>   {pointsOverview.length > 1 ? <tr key={`match-${index}`}>
                                                <td>Match {index+1}</td>
                                                <td>{match.action}</td>
                                            </tr> : null }
                                            { match.map((category: any, index: number) =>
                                                <tr key={`overview-${index}`}>
                                                    <td style={{ fontSize: '80%' }}>{category.action}</td>
                                                    <td>{category.quantity}</td>
                                                    <td>{category.points}</td>
                                                </tr>
                                            )}
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </PointsOverViewTable> : null
                }
			</PlayerModalStyle>
		)
	}
}


export default PlayerModal;