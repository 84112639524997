import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as userActions from '../../actions/user';
import * as teamActions from '../../actions/teams';
import * as leaguesActions from '../../actions/leagues';
import {
	ApplicationState,
	StoreState,
	MatchesState,
	UserState,
	ClubsState,
} from './../../types';
import {
	ContainerStyle,
	BasicTableStyle,
} from './../../components/PlayerList/PlayerListStyle';
import { Select, Input, Tabs, TeamPointsModal } from './../../components';
import config from './../../config';
import Link from '../../components/UI/Link/Link';
import { getPrizes } from './../../lib/helpers';

import { selectTopMarginOnSmallerScreens } from '../../components/UI/Select/SelectStyle';
const SelectStyled = selectTopMarginOnSmallerScreens(Select);

import {
	Title,
	TitleType,
	Block,
	Row,
	Col,
	Button,
	LeaguesList,
	Alert,
} from './../../components';

import { Leagues as LeaguesTable } from './../../pages'

import { Tabs as antTabs } from 'antd';

const TabPane = antTabs.TabPane;

export interface Props {
	application: ApplicationState;
	matches: MatchesState;
	match: any;
	user: UserState;
	clubs: ClubsState;
	t: any;
	location: any;
}

export interface State {
	rankingsResults: any;
	selectedRankingsType: string;
	searchRankingTerm: string;
	currentPage: number;
	selectedWeekId: number;
	selectedMonth: number;
	selectedPeriodId: number;
	totalRecordsPerPage: number;
	fetchingRankings: boolean;
	prizesMap: any;
	weeklyWinnerModalVisible: boolean;
	selectedWeeklyWinnerTeamId?: number;
	selectedWeeklyWinnerWeekId?: number;
	selectedWeeklyWinner?: string;
}

class Leagues extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			rankingsResults: [],
			selectedRankingsType: 'general',
			searchRankingTerm: '',
			selectedWeekId: 1,
			selectedMonth: 1,
			selectedPeriodId: 2,
			currentPage: 1,
			totalRecordsPerPage: 10,
			fetchingRankings: false,
			prizesMap: {},
			weeklyWinnerModalVisible: false
		};
	}

	componentDidMount() {
		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			this.state.selectedWeekId,
			this.state.selectedMonth
		);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			this.props.matches.info.deadlineWeek &&
			this.props.matches.info.deadlineWeek !==
			prevProps.matches.info.deadlineWeek
		) {
			const deadlineWeekId = this.props.matches.info.deadlineWeek;
			this.setState({
				selectedWeekId: deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
				//todo selectedMonth to current month
			});
		}
	}

	fetchRankings = (
		selectedRankingsType: any,
		totalRecordsPerPage: any,
		currentPage: any,
		searchRankingTerm: any,
		selectedWeekId: any,
		selectedMonth: any,
		selectedPeriodId?: any,
	) => {
		this.setState({ fetchingRankings: true });
		const rankingTypeId = selectedRankingsType === 'custom' ? selectedPeriodId || this.state.selectedPeriodId : null;
		const rankingType = selectedRankingsType.indexOf('custom') !== -1 ? 'custom' : selectedRankingsType;

		const deadlineWeekId = this.props.matches.info.deadlineWeek || 1;
			this.setState({
				selectedWeekId: !selectedWeekId ? deadlineWeekId > 1 ? deadlineWeekId - 1 : 1 : selectedWeekId,
				//todo selectedMonth to current month
			});
		return teamActions
			.getRankings(
				this.props.application.competition.competitionFeed,
				rankingType,
				totalRecordsPerPage,
				currentPage,
				searchRankingTerm,
				selectedWeekId,
				selectedMonth,
				rankingTypeId
			)
			.then(result => {
				this.setState({ rankingsResults: result, fetchingRankings: false });
			});
	};

	onSearchTermChanged = (e: any) => {
		this.setState({ searchRankingTerm: e.target.value });
	};

	onTabChange = (rankingType: string) => {
		if (rankingType === 'minicompetitions') {
			this.setState({ selectedRankingsType: rankingType });
			return;
		}

		const deadlineWeekId = this.props.matches.info.deadlineWeek;

		const newStateObject = {
			selectedRankingsType: rankingType,
			currentPage: 1,
			searchRankingTerm: '',
			rankingsResults: [],
			selectedWeekId:
				deadlineWeekId && deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
			selectedMonth: 1 //todo update to current month
		};

		if (['custom', 'pundits'].includes(rankingType)) {
			newStateObject.selectedWeekId = -1;
		}

		this.setState(newStateObject);

		if (rankingType === 'pundits') {
			// this.fetchLeagueInfo();
		} else {
			this.fetchRankings(
				rankingType,
				this.state.totalRecordsPerPage,
				this.state.currentPage,
				this.state.searchRankingTerm,
				newStateObject.selectedWeekId,
				this.state.selectedMonth
			);
		}
	};

	// fetchLeagueInfo(weekId?: number) {
	// 	const team =
	// 		this.props.user && this.props.user.teams && this.props.user.teams[0];
	// 	leaguesActions
	// 		.fetchLeagueInfo(
	// 			config.EXPERTS_LEAGUE_ID,
	// 			weekId && weekId > 0 ? weekId : null,
	// 			[team && team.id]
	// 		)
	// 		.then((leagueInfo: any) => {
	// 			const punditsData = leagueInfo.teams.map(
	// 				(teamItem: any, index: number) => {
	// 					return {
	// 						team: {
	// 							id: teamItem.id,
	// 							name: teamItem.name,
	// 							points: teamItem.points,
	// 							rank: index + 1,
	// 						},
	// 						user: teamItem.user,
	// 					};
	// 				}
	// 			);
	// 			this.setState({
	// 				rankingsResults: { data: punditsData },
	// 				fetchingRankings: false,
	// 			});
	// 		});
	// }

	handleTableChange = (pagination: any, filters: any, sorter: any) => {
		this.setState({ currentPage: pagination.current });

		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			pagination.current,
			this.state.searchRankingTerm,
			this.state.selectedWeekId,
			this.state.selectedMonth
		);
	};

	onSearchByName = () => {
		const rankingType =
			this.state.selectedRankingsType === 'custom' &&
				this.state.selectedWeekId > 0
				? 'week'
				: this.state.selectedRankingsType;

		this.fetchRankings(
			rankingType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			this.state.selectedWeekId,
			this.state.selectedMonth
		);
	};

	onWeekSelect = (weekId: any) => {
		this.setState({ selectedWeekId: weekId });

		if (this.state.selectedRankingsType === 'pundits') {
			// this.fetchLeagueInfo(weekId);
		} else {
			const rankingType = 'week';
				// this.state.selectedRankingsType === 'custom' && weekId > 0
				// 	? 'week'
				// 	: this.state.selectedRankingsType;

			this.fetchRankings(
				rankingType,
				this.state.totalRecordsPerPage,
				this.state.currentPage,
				this.state.searchRankingTerm,
				weekId,
				this.state.selectedMonth
			);
		}
	};

	onMonthSelect = (month: any) => {
		this.setState({ selectedMonth: month });

		const rankingType = 'month';

		this.fetchRankings(
			rankingType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			this.state.selectedWeekId,
			month
		);
	};

	onPeriodSelect = (periodId: any) => {
		this.setState({ selectedPeriodId: periodId });

		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			null,
			periodId
		);
	};

	onWeeklyWinnerPopUpCancel = (event: any) => {
		event.stopPropagation();
		this.setState({ weeklyWinnerModalVisible: false });
	}

	showWeekWinner = (teamId: number, weekId: number, owner: any, event: any) => {
		event.preventDefault();
		this.setState({ weeklyWinnerModalVisible: true, selectedWeeklyWinnerTeamId: teamId, selectedWeeklyWinnerWeekId: weekId, selectedWeeklyWinner: owner });
	};

	render() {
		const { application, matches, clubs, user, t } = this.props;
		const { prizesMap, weeklyWinnerModalVisible, selectedWeeklyWinnerTeamId, selectedWeeklyWinnerWeekId, selectedWeeklyWinner,
			selectedPeriodId } = this.state;

		const weekWinnerModalEnabled = false;

		const {
			searchRankingTerm,
			rankingsResults,
			totalRecordsPerPage,
			fetchingRankings,
			currentPage,
			selectedRankingsType,
			selectedWeekId,
			selectedMonth
		} = this.state;

		const columns = [
			{
				title: '#',
				key: 'rank',
				dataIndex: 'team',
				width: '20%',
				render: (team: any, record: any, index: number) => {
					return <b>{team.rank ? team.rank : '-'}</b>;
				},
			},
			{
				title: t('rankingsPage.rankingsTableTeamColumnName'),
				key: 'team',
				width: '60%',
				dataIndex: 'team',
				render: (team: any, record: any, index: number) => {
					const rank = team.rank ? team.rank : ((currentPage - 1) * totalRecordsPerPage) + index + 1;

					let prizeMapKey = selectedRankingsType;

					if (selectedRankingsType === 'custom') {
						if (this.state.selectedPeriodId === 15) {
							prizeMapKey = 'periode-0'
						} else if (this.state.selectedPeriodId === 13) {
							prizeMapKey = 'periode-1';
						} else if (this.state.selectedPeriodId === 14) {
							prizeMapKey = 'periode-2';
						}
					}
					const prize = team.rank && team.prizes && prizesMap[prizeMapKey] &&
						prizesMap[prizeMapKey]
							.find((prize: any) => {
								return team.prizes >= prize.firstRank && team.prizes <= prize.lastRank;
							});

					return (
						<React.Fragment>
							{weekWinnerModalEnabled && selectedRankingsType === 'week' && rank === 1 &&
								(this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek && this.props.matches.info.deadlineWeek > 1)
								? (<Link to={`/`} onClick={(e: any) => this.showWeekWinner(team.id, selectedWeekId, record.user, e)}>{team.name}</Link>)
								: (<b>{team.name} {}</b>)}
							<span style={{ display: 'block', fontSize: '12px' }}>
								{(record.user.nameVisible) ? `${record.user.firstName} ${record.user.lastName}` : null}
							</span>
						</React.Fragment>
					);
				},
			},
			{
				title: t('rankingsPage.rankingsTablePointsColumnName'),
				key: 'points',
				width: '20%',
				dataIndex: 'team',
				render: (team: any, record: any) => {
					return <span>{team.points || 0}</span>;
				},
			},
		];

		const pagination = {
			current: currentPage,
			total: rankingsResults.totalRecords,
			pageSize: totalRecordsPerPage,
		};

		let weeks = this.props.matches.weeks.map((week: any) => {
			return {
				id: week.weekId,
				name: `${t('general.footballWeek')} ${week.weekId}`,
			};
		});

		let months = [
			{id: 1, name: 'Jan'},
			{id: 2, name: 'Feb'},
			{id: 3, name: 'Mar'},
			{id: 4, name: 'Apr'},
			{id: 5, name: 'May'},
			{id: 6, name: 'Jun'},
			{id: 7, name: 'Jul'},
			{id: 8, name: 'Aug'},
			{id: 9, name: 'Sep'},
			{id: 10, name: 'Oct'},
			{id: 11, name: 'Nov'},
			{id: 12, name: 'Dec'}
		];

		let periods = [
			{ id: 2, name: t('rankingsPage.regularCompetitionPeriod') },
			{ id: 3, name: t('rankingsPage.playoffsPeriod') },
		];

		if (
			selectedRankingsType === 'custom' ||
			selectedRankingsType === 'pundits'
		) {
			weeks.unshift({ id: -1, name: t('general.footballAllWeeks') });
		}

		return (
			<Row>
				<Col lg={{ span: 20, offset: 2 }}>
					<Row gutter={[16, 16]}>
						<Col lg={{ span: 24 }}>
						<Block>
						<Title type={TitleType.h2}>{t('rankingsPage.rankingsTitle')}</Title>
				{/* {(['general'].indexOf(selectedRankingsType) !== -1 && (<Row gutter={[16, 16]} type="flex" justify="space-around" align="middle">
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_place_klas24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{color: '#84FF00', fontWeight: 'bold'}}>Ridley Fenix Disc</span><p>Eerste plaats eindklassement</p></div>
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_2nd_place_klas24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{margin: '10px', color: '#84FF00', fontWeight: 'bold'}}>Garmin Edge 1040 Solar</span><p>2e en 3e plaats eindklassement</p></div>
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_3rd_place_klas24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{margin: '10px', color: '#84FF00', fontWeight: 'bold'}}>Een voorjaar gratis vers</span><p>Speeldagwinnaar</p></div>
                                    </div>
                                </Col>
                            </Row>)) || null} */}
				<Row style={{paddingBottom: "20px"}}>
					<Col md={24} sm={24} xs={24}>
						<Tabs
							defaultActiveKey="general"
							size={'large'}
							onChange={this.onTabChange}
						>
							<TabPane tab={t('rankingsPage.generalTabLabel')} key="general" />
							<TabPane tab={t('rankingsPage.weekTabLabel')} key="week" />
							{/* <TabPane tab={t('rankingsPage.monthTabLabel')} key="month" />
							<TabPane tab={t('rankingsPage.periodTabLabel')} key="custom" /> */}
						</Tabs>
					</Col>
					{/* todo no name search */}
					{/* {(selectedRankingsType !== 'minicompetitions' && (
					<Col md={12} sm={24} xs={24}>
						<span style={{ width: '80%', display: 'inline-block' }}>
							<Input
								onChange={this.onSearchTermChanged}
								placeholder={t('rankingsPage.searchByTeamNameOrUser')}
								value={searchRankingTerm}
							/>
						</span>

						<span style={{ width: '20%', display: 'inline-block' }}>
							<div style={{ marginLeft: '5px' }}>
								<Button
									type="primary"
									disabled={
										searchRankingTerm.length > 0 &&
										searchRankingTerm.length < 3
									}
									onClick={this.onSearchByName}
								>
									{t('general.search')}
								</Button>
							</div>
						</span>
					</Col>)) || null} */}

					{(['week', 'pundits'].indexOf(selectedRankingsType) !==
						-1 && (
							<Col md={12} sm={24} xs={24}>
								<SelectStyled
									keyProperty={'id'}
									style={{ width: '100%', marginTop: '15px' }}
									value={selectedWeekId}
									notFoundContent={'No weeks.'}
									textProperty={'name'}
									onSelect={this.onWeekSelect}
									values={weeks}
								/>
							</Col>
						)) ||
						null}

					{(['month'].indexOf(selectedRankingsType) !==
						-1 && (
							<Col md={12} sm={24} xs={24}>
								<SelectStyled
									keyProperty={'id'}
									style={{ width: '100%', marginTop: '15px' }}
									value={selectedMonth}
									notFoundContent={'No results.'}
									textProperty={'name'}
									onSelect={this.onMonthSelect}
									values={months}
								/>
							</Col>
						)) ||
						null}

					{(['custom'].indexOf(selectedRankingsType) !==
						-1 && (
							<Col md={12} sm={24} xs={24}>
								<SelectStyled
									keyProperty={'id'}
									style={{ width: '100%', marginTop: '15px' }}
									value={selectedPeriodId}
									notFoundContent={'No Periods.'}
									textProperty={'name'}
									onSelect={this.onPeriodSelect}
									values={periods}
								/>
							</Col>
						)) ||
						null}

					{
						selectedRankingsType !== 'minicompetitions' ?
							<Col md={24} sm={24} xs={24} style={{ marginTop: '10px' }}>
								<ContainerStyle>
									<BasicTableStyle
										columns={columns}
										dataSource={!fetchingRankings ? rankingsResults.data : []}
										showHeader={true}
										locale={{ emptyText: t('rankingsPage.rankingsNotAvailableYet') }}
										loading={fetchingRankings}
										pagination={selectedRankingsType === 'pundits' ? { ...pagination, pageSize: 20 } : pagination}
										onChange={this.handleTableChange}
										rowKey={(record: any, index: number) =>
											`record-${index + 1}`
										}
										rowClassName={(record: object, index: number) =>
											index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
										}
									/>
								</ContainerStyle>
							</Col>
							: null
					}
				</Row>
				{
					selectedWeeklyWinnerTeamId && selectedWeeklyWinnerWeekId ?
						<TeamPointsModal
							teamId={selectedWeeklyWinnerTeamId}
							weekId={selectedWeeklyWinnerWeekId}
							selectedWeeklyWinner={selectedWeeklyWinner}
							visible={weeklyWinnerModalVisible}
							onCancel={this.onWeeklyWinnerPopUpCancel}
							user={user}
							application={application}
							clubs={clubs}
							t={this.props.t} /> :
						null
				}
			</Block>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}

export const mapDispatchToProps = {
	fetchTeamLeagues: userActions.fetchUserTeamLeaguesAndPersist,
};

export function mapStateToProps({
	application,
	matches,
	user,
	clubs
}: StoreState.All) {
	return {
		application,
		matches,
		user,
		clubs
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Leagues);
