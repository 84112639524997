import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { TransProps } from 'react-i18next';
import i18next from 'i18next';
import { pick } from 'lodash';
import lockr from 'lockr';
import { useTranslation } from "react-i18next";

import { HeaderStyle, Logo, Nav, Hamburger, HeaderBackground, MobileBackButton, NavLastItem } from "./HeaderStyle";
import bg from '../../assets/img/header/bg.jpg';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col } from "./../../components";
import { useLockBodyScroll } from '@fransvilhelm/hooks';
import { Icon } from 'antd';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import * as clubsActions from '../../actions/clubs'
import * as matchesActions from '../../actions/matches'
import * as teamsActions from '../../actions/teams'
import { StoreState, UserState, ApplicationState, MatchesState } from './../../types';

function MobileLockScroll() {
    useLockBodyScroll();
    return null;
}

export const staticPagesTitleMap: { [key: string]: string } = {
	'/stats': 'STATS',
	'/rankings': 'KLASSEMENT',
	'/home': 'HOME',
	'/login': 'LOGIN',
	'/register': 'REGISTER',
	'/rules': 'SPELREGELS',
	'/deadlines': 'WEDSTRIJDEN',
	'/new': 'NIEUW TEAM'
};

declare global {
    interface Window {
        VRT: any;
        digitalData: any;
        adhese: any;
    }
}

type HeaderProps = RouteComponentProps & TransProps & {
	user: UserState;
	application: ApplicationState;
	matches: MatchesState;
	fetchPlayers: any;
	fetchClubs: any;
	fetchMatchesInfo: any;
	fetchMatches: any;
	fetchUserTeamsAndLeagues: any;
	fetchTeamLeagues: any;
	onSetProfileData: any;
	logout: any;
	match: any;
	t: i18next.TFunction;
	renderVrtWidget?: () => React.ReactNode;
}

class Header extends Component<HeaderProps> {
	headerRef = React.createRef<HTMLElement>();
	state = {
		windowWidth: window.innerWidth,
		menuToggled: false,
		subscriptions: 0,
		pageTitle: '',
		showTeams: false,
		navOpen: false
	};

	componentDidMount() {
		this.props.fetchPlayers(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchClubs(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatchesInfo(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatches(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		if (this.props.user.data.id) {
			this.props.fetchUserTeamsAndLeagues(
				this.props.application.competition.competitionFeed
			);

			userActions.profile()
				.then((result) => {
					const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'vrtId', 'country', 'postCode', 'email', 'registerInfo', 'avatar', 'role']);
					lockr.set('user', user);
					this.props.onSetProfileData(user);

					if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
						(window as any).$crisp.push(["set", "user:email", result.email]);
						// name: `${result.firstName} ${result.lastName}`,
					}
				});
		}

		// NEEDS SETTING CONFIG
		// teamsActions.getSubscriptions(
		// 	this.props.application.competition.competitionFeed,
		// 	this.props.application.competition.seasonId
		// )
		// 	.then(result => {
		// 		this.setState({ subscriptions: result.total });
		// 	})

		this._checkCurrentPageTitle();
		window.addEventListener('resize', this.onWindowResize);
	}

	componentDidUpdate(prevProps: HeaderProps) {
		if (this.props.location !== prevProps.location) {
			this._checkCurrentPageTitle();
		}

		if (this.props.user.teams.length !== prevProps.user.teams.length && this.props.user.teams.length) {
            const team = this.props.user.teams[0];
            this.props.fetchTeamLeagues(team.id);
        }
	}

	_checkCurrentPageTitle = () => {
        if(window && window.digitalData) {
            window.digitalData.page.page_url = document.location.href.split('?')[0];
            window.digitalData.page.page_uri = document.location.pathname;
            window.digitalData.page.trackPageView = "true";
        }

        if(window && window.adhese) {
            window.adhese.ClearAds();
            window.adhese.GetAds(false);
        }

		if (staticPagesTitleMap[this.props.location.pathname]) {
			this.setState({ pageTitle: staticPagesTitleMap[this.props.location.pathname] });
		} else if (this.props.location.pathname.includes('my-teams/team/')) {
			const teamId = this.props.location.pathname
				.split('/')
				.find((chunk: any) => !!parseInt(chunk));

			const team = teamId && this.props.user.teams.find((team: any) => team.id === parseInt(teamId));

			if (team) {
				this.setState({ pageTitle: team.name });
			}
		} else {
			this.setState({ pageTitle: '' });
		}
	};

	_onPressHamburger = (ev: any) => {
		// const body = document.getElementsByTagName("body")[0];

		// if (this.state.navOpen) {
		// 	body.style.transform = "";
		// } else {
		// 	body.style.transform = "translateX(80vw)";
		// }

		this.setState({
			navOpen: !this.state.navOpen,
		});

		// this._openSubMenu(ev);
	};

	_openSubMenu = (ev: any) => {
		this.setState({ menuToggled: !this.state.menuToggled })

        if(this.state.menuToggled && this.state.navOpen) {
            this.setState({navOpen: !this.state.navOpen})
        }
	};

	_onShowTeamsToggle = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ showTeams: !this.state.showTeams });
	};

	onMenuItemNavigate = (ev: any) => {
		if (this.state.windowWidth < 768) {
			this._onPressHamburger(ev);
		}
	};

	onWindowResize = () => {
		this.setState({ windowWidth: window.innerWidth });
	};

	onLogOut = (e: any) => {
		this.props.logout();
	};

	onHelpChatToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.onMenuItemNavigate(e);

		if ((window as any).Tawk_API) {
			(window as any).Tawk_API.maximize();
		}
	}

	render() {
		const { user, matches, location, t, renderVrtWidget } = this.props;
		const userTeam = user.teams[0];
		const showTransfers = userTeam && matches.info.deadlineWeek && (matches.info.deadlineWeek > userTeam.weekId);
		const showPoints = userTeam && matches.info.deadlineWeek && (matches.info.deadlineWeek > userTeam.weekId);
		const isActive = (match: string) => location.pathname.indexOf(match) !== -1;
		const gameOver = !matches.info.deadlineWeek;
		const gameInProgress = !!matches.info.deadlineWeek;

		let allMenuItems: string[] = ['home', 'stats', 'rules'];
		let menuTotalItems = allMenuItems.length;

		allMenuItems.push('rankings');
		menuTotalItems += 1;

		// if (user.authenticated) {
		// 	// allMenuItems.push('logout');
		// 	menuTotalItems += 2;
		// }

		if (user.authenticated && userTeam) {
			allMenuItems.push('leagues');
			menuTotalItems += 1;
		}

		let visibleMenuItems: string[] = ([] as string[]).concat(allMenuItems);
		let collapsedMenuItems: string[] = [];
		const menuItemSize = 80;
		const wildCardOrFreeHitEnabled = matches.info.deadlineWeek && userTeam && (matches.info.deadlineWeek === userTeam.freeHit || matches.info.deadlineWeek === userTeam.wildCard);

		const insertToMenuAtPosition = (positionIndex: number, item: string) => {
			if (allMenuItems.indexOf(item) === -1) {
				allMenuItems.splice(positionIndex, 0, item);
				visibleMenuItems = ([] as string[]).concat(allMenuItems);
				menuTotalItems += 1;
			}
		};

		if ((user.authenticated && showPoints) || gameOver) {
			insertToMenuAtPosition(1, 'points');
		} else if (user.authenticated && !userTeam) {
			insertToMenuAtPosition(1, 'new');
		}

		if (user.authenticated && userTeam && gameInProgress) {
			if (showPoints) {
				insertToMenuAtPosition(2, 'team');
			} else {
				insertToMenuAtPosition(1, 'team');
			}
		}

		if (wildCardOrFreeHitEnabled) {
			insertToMenuAtPosition(3, 'edit');
		}

		if (user.authenticated && userTeam && !showTransfers && gameInProgress) {
			if (showTransfers) {
				insertToMenuAtPosition(3, 'edit');
			} else {
				insertToMenuAtPosition(2, 'edit');
			}
		}

		if (user.authenticated && showTransfers && gameInProgress && !wildCardOrFreeHitEnabled) {
			if (showTransfers) {
				insertToMenuAtPosition(4, 'transfers');
			} else {
				insertToMenuAtPosition(3, 'transfers');
			}
		}

		const containerWidth = this.state.windowWidth - (this.state.windowWidth * 10 / 100);
		const availableMenuSpace = containerWidth - 20;

		const result = Math.floor(availableMenuSpace / menuItemSize);
		const rank = result < menuTotalItems ? result - 1 : result;

		visibleMenuItems = allMenuItems; //allMenuItems.slice(0, rank);
		collapsedMenuItems = allMenuItems.slice(rank, menuTotalItems);

		const isCollapsed = (menuItem: string) => collapsedMenuItems.indexOf(menuItem) !== -1;
		const isVisible = (menuItem: string) => visibleMenuItems.indexOf(menuItem) !== -1;

		return (
			<React.Fragment>
				<HeaderBackground bg={bg}>
                    <Layout style={{ background: 'transparent' }}>
                        <HeaderStyle ref={this.headerRef}>
                            <Hamburger onClick={this._onPressHamburger}>
                                <span></span>
                                {this.state.navOpen && <MobileLockScroll />}
                            </Hamburger>
                            <Nav open={this.state.navOpen}>
                                <MobileBackButton onClick={() => this.setState({ navOpen: !this.state.navOpen })}>
                                    <Icon type="arrow-left" />
                                </MobileBackButton>
                                <ul className="">
                                    <li className={`${isActive('home') || location.pathname === '/' ? 'active' : ''}`}>
                                        <Link onClick={this.onMenuItemNavigate} to="/home">
                                            Home
                                        </Link>
                                    </li>

                                    {(userTeam && isVisible('points') && (
                                        <li className={` ${isActive('points') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to={`/points/${userTeam.id}`}>
                                                Punten
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('team') && (
                                        <li className={`${isActive('team') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to={`/team/${userTeam.id}`}>
                                                Team
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('edit') && (
                                        <li className={`${isActive('edit') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to={`/edit/${userTeam.id}`}>
                                                Transfers
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('new') && (
                                        <li className={`${isActive('new') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/new">
                                                Team
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('transfers') && (
                                        <li className={`${isActive('transfers') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to={`/transfers/${userTeam.id}`}>
                                                Transfers
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('leagues') && (
                                        <li className={`${isActive('leagues') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/leagues">
                                                Minicompetitie
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('stats') && (
                                        <li className={`${isActive('stats') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/stats">
                                                Stats
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('rankings') && (
                                        <li className={`${isActive('rankings') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/rankings">
                                                Rangschikking
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('rules') && (
                                        <li className={`${isActive('rules') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/rules">
                                                Spelregels
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('prizes') && (
                                        <li className={`${isActive('prizes') ? 'active' : ''}`}>
                                            <Link onClick={this.onMenuItemNavigate} to="/prizes">
                                                Prijzen
                                            </Link>
                                        </li>
                                    )) ||
                                        null}

                                    {(isVisible('logout') && (
                                        <li className={`${isActive('logout') ? 'active' : ''}`}>
                                            <Link onClick={this.onLogOut} to="/home">
                                                Log uit
                                            </Link>
                                        </li>
                                    )) ||
                                        null}
                                    {/* {(collapsedMenuItems.length && (
                                        <li className={`has-children ${this.state.menuToggled ? 'open' : ''}`}>
                                            <a href="#/" onClick={this._openSubMenu}>
                                                Menu
                                            </a>
                                            <ul>
                                                {(isCollapsed('points') && (
                                                    <li className={`${isActive('points') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/points">
                                                            Punten
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('team') && (
                                                    <li className={`${isActive('team') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to={`/team/${userTeam.id}`}>
                                                            Team
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('edit') && (
                                                    <li className={`${isActive('edit') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to={`/edit/${userTeam.id}`}>
                                                            Transfers
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('new') && (
                                                    <li className={`${isActive('new') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/new">
                                                            Team
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('transfers') && (
                                                    <li className={`${isActive('transfers') ? 'active' : ''}`}>
                                                        <Link
                                                            onClick={this._openSubMenu}
                                                            to={`/transfers/${userTeam.id}`}
                                                        >
                                                            Transfers
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('leagues') && (
                                                    <li className={`${isActive('leagues') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/leagues">
                                                            Minicompetitie
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('stats') && (
                                                    <li className={`${isActive('stats') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/stats">
                                                            Stats
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('rankings') && (
                                                    <li className={`${isActive('rankings') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/rankings">
                                                            Rangschikking
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('rules') && (
                                                    <li className={`${isActive('rules') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/rules">
                                                            Spelregels
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('prizes') && (
                                                    <li className={`${isActive('prizes') ? 'active' : ''}`}>
                                                        <Link onClick={this._openSubMenu} to="/prizes">
                                                            Prijzen
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}

                                                {(isCollapsed('logout') && (
                                                    <li className={`${isActive('logout') ? 'active' : ''}`}>
                                                        <Link onClick={this.onLogOut} to="/home">
                                                            Log uit
                                                        </Link>
                                                    </li>
                                                )) ||
                                                    null}
                                            </ul>
                                        </li>
                                    )) ||
                                        null} */}
                                    {renderVrtWidget ? <NavLastItem>{renderVrtWidget()}</NavLastItem> : null}
                                </ul>
                            </Nav>
                        </HeaderStyle>
                    </Layout>
                </HeaderBackground>
				
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	fetchPlayers: playersActions.fetchPlayers,
	fetchClubs: clubsActions.fetchClubs,
	fetchMatchesInfo: matchesActions.fetchMatchesInfo,
	fetchMatches: matchesActions.fetchMatches,
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
	fetchTeamLeagues: userActions.fetchUserTeamLeaguesAndPersist,
	logout: userActions.logOutUser,
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams,
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application,
		matches
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Header)
);
