import React, { Component } from 'react';
import { Icon } from 'antd';

import { ConfirmationModalStyle } from './ConfirmationModalStyle';
import { ModalProps } from "antd/lib/modal";
import { Row, Col, Button } from '..';
import { DeleteButtonSvg } from './../../styles/custom-icons';
import parseHTML from 'html-react-parser';
import { Title } from '../Substitutes/SubstitutesStyle';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;

export interface PlayerModalState {
}

export interface PlayerModalProps {
	visible: boolean;
	title: string;
	text: string;
	onCancel: any;
	onConfirm: any;
	t: any;
}

class PlayerModal extends Component<PlayerModalProps, PlayerModalState> {


	render() {
		const { visible, onCancel, onConfirm, text, t, title } = this.props;

		return (
			<ConfirmationModalStyle
				title={title}
				closable={true}
				visible={this.props.visible}
				onCancel={onCancel}
				footer={[]}
			>
				<Row>
					<Col md={24} sm={24} xs={24}>
						{parseHTML(text)}
					</Col>
				</Row>
				<Row>
					<Col md={24} sm={24} xs={24} className="actions">
						<Button type="primary" onClick={(e: any) => this.props.onConfirm()} size="default"> {t('general.confirmationModalYesButtonLabel')} </Button>
						<Button type="danger" onClick={(e: any) => this.props.onCancel()} size="default"> {t('general.confirmationModalNoButtonLabel')} </Button>
					</Col>
				</Row>
			</ConfirmationModalStyle>
		)
	}
}


export default PlayerModal;