import styled from "../../styles/styled-components";
import { TeamProps } from "./Team";
import mediaQueries from "../../styles/media-queries";
import { PlayerStyle } from "../Player/PlayerStyle";

const TeamStyle = styled.div`
	color: #FFF;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0px 0 30px;
	flex: 1;
	max-width: 975px;
	height: ${(props: TeamProps) => (props.heightRatio / props.widthRatio) * 1.5 * 320}px; /*  Make mobile size higher */
	background: url(${(props: TeamProps) => props.bg}) no-repeat center center/120% 100%;
	border-radius: 0;
	margin: 75px auto;

	.position {
		display: flex;
		width: 100%;
    	justify-content: center;
    	flex-wrap: wrap;
		-webkit-box-pack: center;
		
		@media ${mediaQueries.tablet} {
			flex-wrap: nowrap;
		}

		${PlayerStyle} {
			margin: 8px;

			@media ${mediaQueries.mobileM} {
				margin: 1px;
			}

			@media ${mediaQueries.tablet} {
				margin: 0 10px;
			}

			@media ${mediaQueries.desktop} {
				margin: 0 8px;
			}
		}
	}
	
	@media ${mediaQueries.mobileM} {
		padding: 0px 0 30px;
		height: ${(props: TeamProps) => (props.heightRatio / props.widthRatio) * 1.5 * 375}px;
		margin: 0;
	}
	
	@media ${mediaQueries.tablet} {
		max-width: 960px;
		height: ${(props: TeamProps) => (props.heightRatio / props.widthRatio) * 1.5 * 500}px;
		background-size: 100% 100%;
	}
` as any;

interface SponsorProps {
	bg: string;
	widthRatio: number;
	heightRatio: number;
}
const Sponsor = styled.div`
	position: absolute;
	top: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 8}px;
	left: 0;
	right: 0;
	height: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 30}px;
	background-color: rgba(255,0,0,0.5);
	background: ${(props: SponsorProps) => `url(${props.bg})`} no-repeat center center/contain;
	transform: scale(0.8);

	@media ${mediaQueries.mobileM} {
		top: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 12}px;
		height: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 28}px;
	}

	@media ${mediaQueries.tablet} {
		top: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 15}px;
		height: ${(props:SponsorProps) => (props.heightRatio / props.widthRatio) * 30}px;
	}
`;

export {
	TeamStyle,
	Sponsor
};