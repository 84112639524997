import React, { Component } from 'react';
import { Icon } from 'antd';
import {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
} from './PlayerStatsListStyle';
import { TableStyle } from "../../components/PlayerList/PlayerListStyle";
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import PlayerAvatar from '../Player/Player';
import Select from '../UI/Select/Select';
import Link from '../UI/Link/Link';
import { theme } from "../../styles/theme-sporza";
import { PlayerType } from '../Player/Player';
import { Club, Player, OnPickType, IsPickAbleType, UserState, MatchesState, ApplicationState } from '../../types';
import { roundNextHalf, getPlayerPositionHexColor } from '../../lib/helpers';
import { BikeSvg, FootballSvg, EuroSvg, FootballFieldSvg, StrategySvg } from '../../styles/custom-icons';
import * as playersActions from '../../actions/players'


const FootballIcon = (props: any) => <Icon component={FootballSvg} {...props} />;
const EuroIcon = (props: any) => <Icon component={EuroSvg} {...props} />;
const StrategyIcon = (props: any) => <Icon component={StrategySvg} {...props} />;

export interface PlayerStatsListProps {
	size: number;
	action: boolean;
	isLoading?: boolean;
	hidePositions?: boolean;
	onSelect?: any;
	showHeader?: boolean;
	playerType: PlayerType;
	clubs: any[];
	matches: MatchesState;
	activePositionFilter?: number,
	setActivePositionFilter?: any,
	activeStatsFilter?: number,
	setActiveStatsFilter?: any,
	assetsCdn: string;
	actionLabel?: string;
	playerTax?: number | undefined;
	t: any;
	application: ApplicationState;
	user: UserState;
}

export interface PlayerStatsListState {
	filters: any;
	pagination: any;
	stats: any[];
	sorter: string;
}

class PlayerStatsList extends Component<PlayerStatsListProps, PlayerStatsListState> {

	constructor(props: PlayerStatsListProps) {
		super(props);

		this.state = {
			filters: {
				name: '',
				playerValue: 100,
				clubId: -1,
				weekId: -1,
				positionId: props.activePositionFilter || -1,
				stat: props.activeStatsFilter || 0,
			},
			pagination: {
				pageNumber: 1,
				pageRecords: props.size,
				totalRecords: null
			},
			stats: [],
			sorter: 'total DESC' //todo default to value gameweek 0
		};
	}

	componentDidMount() {
		this.fetchPlayersStats();
	}

	__getFilterValue(filterName: string) {
		return this.state.filters[filterName] !== -1 ? this.state.filters[filterName] : null;
	};

	async fetchPlayersStats() {
		return playersActions.getPlayersAggregatedStats(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			this.state.pagination.pageNumber,
			this.state.pagination.pageRecords,
			this.state.sorter,
			this.state.filters.name.length ? this.state.filters.name : null,
			this.__getFilterValue('weekId'),
			this.__getFilterValue('clubId'),
			this.__getFilterValue('positionId'),
			this.__getFilterValue('playerValue')
		)
			.then(result => {
				const pagination = { ...this.state.pagination, totalRecords: result.totalRecords };
				this.setState({ pagination, stats: result.data });
			});
	};

	componentDidUpdate(prevProps: PlayerStatsListProps, prevState: PlayerStatsListState) {
		if (this.props.activePositionFilter !== prevProps.activePositionFilter) {
			const filters = { ...this.state.filters, positionId: this.props.activePositionFilter }
			this.setState({ filters });
		}

		if (this.props.activeStatsFilter !== prevProps.activeStatsFilter) {
			const filters = { ...this.state.filters, stat: this.props.activeStatsFilter }
			this.setState({ filters });
		}

		const sorterChanged = this.state.sorter !== prevState.sorter;
		const filtersChanged = this.state.filters.name !== prevState.filters.name
			|| this.state.filters.playerValue !== prevState.filters.playerValue
			|| this.state.filters.clubId !== prevState.filters.clubId
			|| this.state.filters.weekId !== prevState.filters.weekId
			|| this.state.filters.positionId !== prevState.filters.positionId
			|| this.state.filters.stat !== prevState.filters.stat;
		const paginationChanged = this.state.pagination.pageNumber !== prevState.pagination.pageNumber;

		if (sorterChanged || filtersChanged || paginationChanged) {
			this.fetchPlayersStats();
		}
	}

	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});

		if (this.props.activePositionFilter && this.props.setActivePositionFilter && name === 'positionId') {
			this.props.setActivePositionFilter(value);
		} else {
			this.setState({ filters });
		}
	};

	onStatChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});

		if (this.props.activeStatsFilter && this.props.setActiveStatsFilter && name === 'positionId') {
			this.props.setActiveStatsFilter(value);
		} else {
			this.setState({ filters });
		}
	};

	handleTableChange = (pagination: any, filters: any, sorter: any) => {
		const newPagination = { ...this.state.pagination, pageNumber: pagination.current };
		const sorterString = sorter && sorter.columnKey ? `${sorter.columnKey} ${sorter.order === 'descend' ? 'DESC' : 'ASC'}` : 'total DESC';  //todo default to value gameweek 0
		this.setState({ pagination: newPagination, sorter: sorterString });
	};

	render() {
		const {
			action,
			size,
			showHeader,
			clubs,
			hidePositions,
			playerType,
			isLoading,
			assetsCdn,
			actionLabel,
			playerTax,
			t
		} = this.props;

		const { stats } = this.state;

		const clubsList = [{
			id: -1,
			name: <span className={'prefixed-label'}> <FootballIcon style={{ marginRight: 5 }} /> {t('general.footballAllClubs')} </span>
		}]
			.concat(clubs);

		const budgets = [
			{ text: <span className={'prefixed-label'}> <EuroIcon /> {t('general.footballAllBudget')} </span>, value: 100 },
			{ text: `${t('general.budgetFilterPrefix')} 10 ${t('general.budgetFilterSuffix')}`, value: 10 },
			{ text: `${t('general.budgetFilterPrefix')} 9.5 ${t('general.budgetFilterSuffix')}`, value: 9.5 },
			{ text: `${t('general.budgetFilterPrefix')} 9 ${t('general.budgetFilterSuffix')}`, value: 9 },
			{ text: `${t('general.budgetFilterPrefix')} 8.5 ${t('general.budgetFilterSuffix')}`, value: 8.5 },
			{ text: `${t('general.budgetFilterPrefix')} 8 ${t('general.budgetFilterSuffix')}`, value: 8 },
			{ text: `${t('general.budgetFilterPrefix')} 7.5 ${t('general.budgetFilterSuffix')}`, value: 7.5 },
			{ text: `${t('general.budgetFilterPrefix')} 7 ${t('general.budgetFilterSuffix')}`, value: 7 },
			{ text: `${t('general.budgetFilterPrefix')} 6.5 ${t('general.budgetFilterSuffix')}`, value: 6.5 },
			{ text: `${t('general.budgetFilterPrefix')} 6 ${t('general.budgetFilterSuffix')}`, value: 6 },
			{ text: `${t('general.budgetFilterPrefix')} 5.5 ${t('general.budgetFilterSuffix')}`, value: 5.5 },
			{ text: `${t('general.budgetFilterPrefix')} 5 ${t('general.budgetFilterSuffix')}`, value: 5 },
			{ text: `${t('general.budgetFilterPrefix')} 4.5 ${t('general.budgetFilterSuffix')}`, value: 4.5 },
			{ text: `${t('general.budgetFilterPrefix')} 4 ${t('general.budgetFilterSuffix')}`, value: 4 }
		];

		const positions = [
			{ id: -1, name: <span className={'prefixed-label'}> <StrategyIcon style={{verticalAlign: '-0.4em'}} /> {t('general.footballAllPositions')} </span> },
			{ id: 0, name: t('player.coach'), short: t('player.coachShort') },
			{ id: 1, name: t('player.goalkeeper'), short: t('player.goalkeeperShort') },
			{ id: 2, name: t('player.defender'), short: t('player.defenderShort') },
			{ id: 3, name: t('player.midfielder'), short: t('player.midfielderShort') },
			{ id: 4, name: t('player.attacker'), short: t('player.attackerShort') },
		];

		const statsFilter = [
			{ id: 0, text: <span className={'prefixed-label'}> {t('statsPage.attackingStats')} </span>, value: [{value: 'statGoals', label: t('statsPage.goalsColumnForAllPlayersTable')}, {value: 'statAssists', label: t('statsPage.assistsColumnForAllPlayersTable')}] },
			{ id: 1, text: `${t('statsPage.defendingStats')}`, value: [{value: 'statConceeded', label: t('statsPage.againstColumnForAllPlayersTable')}, {value: 'statCleanSheet', label: t('statsPage.cleanColumnForAllPlayersTable')}] },
			{ id: 2, text: `${t('statsPage.cardStats')}`, value: [{value: 'statYellow', label: t('statsPage.yellowColumnForAllPlayersTable')}, {value: 'statRed', label: t('statsPage.redColumnForAllPlayersTable')}] },
			{ id: 3, text: `${t('statsPage.playStats')}`, value: [{value: 'statTimePlayed', label: t('statsPage.timePlayedColumnForAllPlayersTable')}, {value: 'statMatchPlayed', label: t('statsPage.matchPlayedColumnForAllPlayersTable')}] },
			// { id: 4, text: `${t('statsPage.userStats')}`, value: [{value: 'statTimePlayed', label: t('statsPage.timePlayedColumnForAllPlayersTable')}, {value: 'statMatchPlayed', label: t('statsPage.matchPlayedColumnForAllPlayersTable')}] },//points per min //selection %// pickorder//
		];

		let weeks: any = this.props.matches.weeks.map((week: any) => {
			return {
				id: week.weekId,
				name: `${t('general.footballWeek')} ${week.weekId}`,
			};
		});

		weeks.unshift({ id: -1, name: <span className={'prefixed-label'}> <Icon type="calendar" style={{color: theme.primaryColor }} /> {t('general.footballAllWeeks')} </span> });

		const columns = [
			{
				key: 'rank',
				title: '#',
				dataIndex: 'generalInfo',
				width: '5%',
				render: (text: string, record: any, index: number) => {
					const rank = ((this.state.pagination.pageNumber - 1) * this.state.pagination.pageRecords) + index + 1;

					return (
						<React.Fragment>
							<span> {rank} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: 'name',
				sorter: true,
				title: t('statsPage.playerColumnForAllPlayersTable'),
				dataIndex: 'generalInfo',
				width: '20%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record.generalInfo.short || record.generalInfo.name} </span>
							{/* <PlayerAvatar
								pointsColor={'#000'}
								pointsBgColor={'#fff'}
								badgeColor={'#fff'}
								badgeBgColor={'#fff'}
								avatarOnly={false}
								player={{id: record.playerId, short: record.generalInfo.short}}
								type={PlayerType.SoccerPortrait}
								t={this.props.t}
								soccerJersey={`${assetsCdn}/jerseys/club_${record.clubId}.png`}
								// clubBadge = {`${assetsCdn}/badges/club_${record.clubId}.png`}
								portraitFace = {`${assetsCdn}/players/player_${record.playerId}.png`}
								portraitFaceFallBack = {`${assetsCdn}/players/dummy.png`}
							/> */}
						</React.Fragment>
					);
				},
			},
			{
				key: 'clubName',
				title: t('statsPage.clubColumnForAllPlayersTable'),
				sorter: true,
				dataIndex: 'clubName',
				width: '10%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							{record.clubName}
						</React.Fragment>
					);
				},
			},
			{
				key: 'positionId',
				title: t('statsPage.positionColumnForAllPlayersTable'),
				sorter: true,
				dataIndex: 'positionId',
				width: '10%',
				render: (text: string, record: any) => {
					const position = positions.find((item: any) => item.id === record.positionId);

					return (
						<React.Fragment>
							<span> {position && position.name || ''} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: 'total',
				title: t('statsPage.pointsColumnForAllPlayersTable'),
				sorter: true,
				dataIndex: 'total',
				width: '10%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record.total} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: statsFilter[this.state.filters.stat].value[0].value,
				title: statsFilter[this.state.filters.stat].value[0].label,
				sorter: true,
				dataIndex: statsFilter[this.state.filters.stat].value[0].value,
				width: '10%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record[statsFilter[this.state.filters.stat].value[0].value]} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: statsFilter[this.state.filters.stat].value[1].value,
				title: statsFilter[this.state.filters.stat].value[1].label,
				sorter: true,
				dataIndex: statsFilter[this.state.filters.stat].value[1].value,
				width: '10%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record[statsFilter[this.state.filters.stat].value[1].value]} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: 'playerValue',
				title: t('statsPage.valueColumnForAllPlayersTable'),
				sorter: true,
				dataIndex: 'playerValue',
				width: '10%',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record.playerValue}M </span>
						</React.Fragment>
					);
				},
			}
		];

		let tableEventHandler: any = () => { };

		if (this.props.onSelect) {
			tableEventHandler = (player: any) => ({
				onClick: (event: any) => {
					this.props.onSelect(player);
				},
			});
		}

		const pagination = {
			current: this.state.pagination.pageNumber,
			total: this.state.pagination.totalRecords,
			pageSize: this.state.pagination.pageSize,
		};

		return (
			<ContainerStyle>
				{
					<Input
						prefix={<Icon type="search" style={{color: theme.primaryColor }} />}
						type="text"
						placeholder={t('general.playersListSearchInputPlaceholder')}
						name="name"
						onChange={(event: any) =>
							this.onFilterChange(event.target.name, event.target.value)
						}
						style={{ margin: 0 }}
					/>
				}
				<SelectGroupStyle>
					<Select
						block
						values={clubsList}
						style={{ marginLeft: 0 }}
						keyProperty={'id'}
						onSelect={(value: any) => this.onFilterChange('clubId', value)}
						textProperty={'name'}
						placeholder={clubsList[0].name}
					/>
					{(!hidePositions && (
						<Select
							style={{ margin: '0px 0px' }}
							block
							value={this.state.filters.positionId}
							keyProperty={'id'}
							textProperty={'name'}
							onSelect={(value: any) => this.onFilterChange('positionId', value)}
							values={positions}
							placeholder={positions[0].name}
						/>
					)) || null}
					<Select
						block
						value={this.state.filters.weekId}
						keyProperty={'id'}
						textProperty={'name'}
						onSelect={(value: any) => this.onFilterChange('weekId', value)}
						values={weeks}
						placeholder={weeks[0].name}
					/>
					<Select
						block
						values={budgets}
						style={{ marginRight: 0 }}
						keyProperty={'value'}
						onSelect={(value: any) => this.onFilterChange('playerValue', value)}
						textProperty={'text'}
						placeholder={budgets[0].text}
					/>
					<Select
						block
						style={{ marginRight: 0 }}
						value={this.state.filters.stat}
						keyProperty={'id'}
						textProperty={'text'}
						onSelect={(value: any) => this.onStatChange('stat', value)}
						values={statsFilter}
						placeholder={statsFilter[0].text}
					/>
				</SelectGroupStyle>

				<TableStyle
					columns={columns}
					dataSource={stats}
					showHeader={showHeader}
					locale={{ emptyText: t('general.playersListEmpty') }}
					loading={isLoading}
					pagination={pagination}
					onChange={this.handleTableChange}
					onRow={tableEventHandler}
					rowKey="id"
					rowClassName={(record: object, index: number) =>
						`${index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'} ${
						this.props.onSelect ? 'cursor-pointer' : ''
						}`
					}
				/>
			</ContainerStyle>
		);
	}
}

export default PlayerStatsList;
