import React, { Component } from 'react';
import { Icon } from 'antd';

import { StatsStyle } from './StatsStyle';
import { Col, Row } from '..';

import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	budget: number;
	totalPlayers: number;
	totalPlayersSelected: number;
	remainingFreeTransfers: number;
	minusPoints: number;
	t: any;
};

class TransfersOverview extends Component<StatsOverviewProps, StatsOverviewState> {

	render() {
		const { t } = this.props;

		return (
			<StatsStyle>
				<Row className="stat-row">
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">{t('transfersPage.overviewBudget')}</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{this.props.budget.toFixed(2)}M</p>
					</Col>
				</Row>
				<Row className="stat-row" style={{ borderTop: '1px solid #3C3C3C' }}>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">{t('transfersPage.overviewPlayers')}</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{this.props.totalPlayersSelected}/{this.props.totalPlayers}</p>
					</Col>
				</Row>
				<Row className="stat-row" style={{ borderTop: '1px solid #3C3C3C' }}>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="label">Transfers</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<p className="points">{this.props.remainingFreeTransfers < 0 ? 0 : this.props.remainingFreeTransfers}</p>
					</Col>
				</Row>
			</StatsStyle>
		)
	}
}

export default TransfersOverview;