import styled from 'styled-components';
import { Layout } from 'antd';

import 'antd/lib/layout/style';
import mediaQueries from '../../../styles/media-queries';

export const LayoutStyle = styled(Layout)`

@media ${mediaQueries.tablet} {
	padding: 20px 0;
}

padding: 10px;

&.ant-layout {
		max-width: 1460px;
		margin: 0 auto;
		background: transparent;
}
`;
