import styled, {keyframes} from "../../styles/styled-components";

const FooterStyle = styled.footer`
	padding: 20px;
	color: #FFF;

	ul {
		list-style: none;
		display: flex;
    align-items: stretch;
		justify-content: space-between;
		padding: 0;
		margin: 0;

		li {
			display: block;
			flex: 0 1 auto;
			list-style-type: none;
			width: 100%;
			text-align: center;
			padding: 5px;
			

			a {			
				color: #FFF;
				text-decoration: none;
				text-transform: none;
				border: none;
	
				.anticon {
					margin-right: 5px;
				}
			}

			a:focus, a:hover {
				color: #FFF !important;
			}
		}
	}
` as any;

export {
	FooterStyle,
};