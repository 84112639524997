import React, { Component } from "react";
import moment from 'moment';
import { Redirect } from "react-router-dom";
import lockr from 'lockr';
import { Player, AbstractTeamType } from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	TeamOverview, Select, Button, Input, AbstractTeam, NewGameStats, Alert, Checkbox
} from './../../components';
import { Icon } from 'antd';
import { theme } from './../../styles/theme-sporza';

import teamBackground from './../../assets/img/bg-football.png';
import teamSponsor from '../../assets/img/header/megaspelen-logo.png';
import { startingListToPositionsList, getPublisherLogoURL } from './../../lib/helpers'
import { NewTeamStyles } from './NewTeamStyles';
import * as teamsActions from '../../actions/teams'

import { Element, scroller } from 'react-scroll'

export interface Props {
	t: any;
	matches: any;
	user: any;
};

export interface State {
	redirectToPayments: boolean;
	hasPlayers: boolean;
}

class NewTeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			redirectToPayments: false,
			hasPlayers: false
		};
	};

	componentDidMount() {
		this.props.activateCacheChanges();

		const cachedChanges = lockr.get('cachedNewTeamChanges') as { starting: any, bench: any, teamName: string, captainId: number, budget: number };

		if (cachedChanges) {
			this.props.initTeamState(
				cachedChanges.starting,
				cachedChanges.bench,
				cachedChanges.teamName,
				cachedChanges.captainId,
				cachedChanges.budget
			);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if(this.props.user.teams[0] && (this.props.user.teams !== prevProps.user.teams || this.props.matches.weeks !== prevProps.matches.weeks)) {
			this.loadTeam();
		}
	}

	loadTeam = () => {
		return teamsActions.get(this.props.user.teams[0].id, undefined, true).then(result => {
			this.props.setTeamName(result.team.name);
			this.setState({ hasPlayers: (result.players.length !== 0) }); 
		})
	}

	onPlaceHolderClick = (player: any) => {
		if (player && player.positionId || player && player.positionId === 0) {
			this.props.setActivePositionFilter(player.positionId);
		}
		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	onTeamSave = (e: any) => {
		if (this.props.savingTeamPending) {
			return false;
		}

		this.props.onTeamSave()
			.then(() => {
				this.setState({ redirectToPayments: true });
			})
			.catch((error:any) => {
				console.log(error)
			});
	};

	onTeamReset = (team: any) => {
		if (this.props.savingTeamPending) {
			return false;
		}

		this.props.onTeamReset(team)
			.then(() => {
				this.setState({ redirectToPayments: true });
			})
			.catch((error:any) => {
				console.log(error)
			});
	};

	render() {
		const { application, starting, bench, captainId, viceCaptainId, teamName, budget, savingTeamPending, t, activePositionFilter, matches } = this.props;
		const { redirectToPayments, hasPlayers } = this.state;

		const startingByPositions = startingListToPositionsList([].concat(starting as any, bench as any), [2, 5, 5, 3], 'football');
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player.id);
		const benchPicked = bench
			.filter(player => !!player.id);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		return (
			<React.Fragment>
			{team && team.id && hasPlayers && <Redirect
					to={{ pathname: `/team/${team.id}` }}
				/>}
				<Row gutter={[16, 16]}>
					<Col md={12} sm={24} xs={24} className="left">
						<Block>
							<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>

							<Input
								onChange={this.props.onTeamNameChange}
								style={{ maxWidth: '100%' }}
								placeholder={t('team.newTeamChooseTeamNameInputPlaceholder')}
								value={teamName}
								maxLength={55}
							/>

							<Checkbox onChange={this.props.onNameVisible}>Ik wens mijn naam zichtbaar in mijn minicompetitie en de rangschikking</Checkbox>

							<NewGameStats 
								budget={budget}
								totalPlayers={totalPlayersToPick}
								totalPlayersSelected={totalPlayersPicked}
								t={t} />

							<Team
								widthRatio={12}
								heightRatio={10}
								clubs={this.props.clubs.data}
								bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg_ek24.jpg'}
								assetsCdn={application.competition.assetsCdn}
								selection={startingByPositions}
								showCaptainBadge={true}
								showPlayerValue={true}
								playerType={PlayerType.SoccerShirt}
								captainId={captainId}
								viceCaptainId={viceCaptainId}
								onPlaceholderClick={this.onPlaceHolderClick}
								showPlayerValueInsteadOfPoints={true}
								onCaptainSelect={this.props.onCaptainSelect}
								onViceCaptainSelect={this.props.onViceCaptainSelect}
								onRemove={(player: Player) => this.props.removePlayer(player)}
								modalEnabled={true}
                                playerBadgeColor={"#000"}
                                playerBadgeBgColor={theme.primaryColor}
                                playerPointsColor={"#000"}
                                playerPointsBgColor={"#dsdsds"}
								t={this.props.t} />

							<Row style={{textAlign: 'center'}}>
								{(team && !hasPlayers && 
									<Button
										onClick={(e: any) => this.onTeamReset(team)}
										type="primary"
										disabled={savingTeamPending}
										loading={savingTeamPending}
										style={{ margin: "10px 0" }}
										size="large">
										<Icon type="save" theme="filled" />
										{t('team.saveTeam')}
									</Button>
									) || <Button
										onClick={this.onTeamSave}
										type="primary"
										disabled={savingTeamPending}
										loading={savingTeamPending}
										style={{ margin: "10px 0" }}
										size="large">
										<Icon type="save" theme="filled" />
										{t('team.saveTeam')}
									</Button>
								}
								
								{/* <Block>
								<div style={{textAlign: "center", backgroundColor: "#363435"}}><a href="https://www.betfirst.be/">
										<img
											alt=""
											style={{height: "75px", width: "auto"}}
											src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/betfirst.jpg"
										/>
									</a></div>
								</Block> */}
							</Row>
							<Alert
								message="Enkele tips"
								description="Selecteer 15 spelers voor jouw droomelftal. Laat geen punten liggen en pas elke speeldag je team aan. En opgelet: blijf binnen het budget en kies max. 3 spelers van hetzelfde land."
								type="info"
								showIcon
								style={{margin: '10px auto'}}
								/>
						</Block>
					</Col>
					<Col md={12} sm={24} xs={24} className="right">
						<Block>
							<Title type={TitleType.h2}>{t('general.footballAllPlayers')}</Title>
							<Element name="all-players">
								<PlayerList
									clubs={this.props.clubs.data}
									matches={matches.data}
									deadlineWeek={matches.info.deadlineWeek}
									isLoading={this.props.players.isFetching}
									hidePositions={false}
									activePositionFilter={activePositionFilter}
									assetsCdn={application.competition.assetsCdn}
									isPickAble={this.props.isPickAble}
									playerType={PlayerType.SoccerShirt}
									data={this.props.players.data}
									onPick={this.props.pickPlayer}
									action
									t={this.props.t}
									showHeader={false}
									size={10}
								/>
							</Element>
						</Block>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(NewTeamContainer, { type: 'football' });


export default WrappedComponent;