import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import { connect } from 'react-redux';
import { Block, Row, Col, Title, TitleType } from '../../components';
import * as userActions from '../../actions/user'
import { StoreState, ApplicationState } from './../../types';
import { CookieStyles } from './CookieStyles';

type RulesProps = {
	application: ApplicationState;

}

class Cookie extends Component<RulesProps> {
	state = {
		text: ''
	}

	componentDidMount() {
		userActions.getPage(this.props.application.competition.competitionFeed, 'cookie-policy')
			.then((result: any) => {
				const body = result && result[0] && result[0].translations && result[0].translations[0] && result[0].translations[0].body;
				this.setState({ text: body || '' })
			});
	}

	render() {
		return (
			<CookieStyles>
				<Row>
					<Col span={24}>
						<Title type={TitleType.h2}>Cookie Policy</Title>
						<Block className="rules">
							{ parseHTML(this.state.text) }
						</Block>
					</Col>
				</Row>
			</CookieStyles>
		);
	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Cookie);
