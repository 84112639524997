import styled from 'styled-components';
import { Input } from 'antd';

import 'antd/lib/input/style';

const inputFocusStyle = `
	border: none;
	box-shadow: none;
	outline: none;
`;

const antInputStyle = `
		color: #FFF;
		background-color: #3C3C3C;
		padding: 20px 15px;
		margin: 10px auto;
		border: none;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			height: 50px;
			padding-top: 15px;
	 	}

		&:hover {
			border: none;
		}

		&:focus {
			${inputFocusStyle}	
		}

		::placeholder {
			color: #bfbfbf;
		}

		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #bfbfbf;
		}
		
		::-ms-input-placeholder { /* Microsoft Edge */
				color: #bfbfbf;
		}

		&:disabled {
				color: rgba(0, 0, 0, 0.25);
				background: #F9F9F9;
				border: none;
		}

		&.ant-input-lg {
				padding-left: 20px;
				height: 40px;
		}

		&.ant-input-sm {
				padding-left: 10px;
				height: 32px;
		}
`;

export const InputStyle = styled(Input)`
		&.ant-input-affix-wrapper {
			.ant-input {
				${antInputStyle}
			}	

			.ant-input-prefix {
				color: #FFF;
				top: 50%;
			}
		}

		&.ant-input {
			${antInputStyle}
		}
`;

export const InputPasswordStyle = styled(Input.Password)`
		&.ant-input-password {
			&.ant-input-affix-wrapper:hover {
				.ant-input:focus {
					${inputFocusStyle}	
				}
			}

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				text-align: left;
			}

			.ant-input {
				${antInputStyle}
			}
    }
`;
