import styled from '../../../styles/styled-components';
import bullet from "../../../assets/img/bullet.png";

const H1Style = styled.h1`
	color: #FFF;
`;

const H2Style = styled.h2`
    font-size: 24px;
    font-family: "C-Bold";
    color: #FFF;
    margin-bottom: 0;
    margin-top: 0;
`;

const H3Style = styled.h3`
    text-align: center;
    margin-bottom: 0px;
    padding: 10px;
    background-color: #FFF;
    color: #FFF;
    font-size: 18px;
`;

const H4Style = styled.h4`
    text-align: center;
    margin-bottom: 0px;
    padding: 10px;
    // background-color: #eee;
    // color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
`;

export {
	H1Style,
	H2Style,
	H3Style,
	H4Style
};