import axios from 'axios';
import lockr from 'lockr';
import config from './../config';
import jwtDecode, { JwtPayload } from "jwt-decode";

axios.interceptors.request.use(function (axiosConfig) {
	if(lockr.get('token')) {
		const user = jwtDecode<any>(lockr.get('token'));

		if (lockr.get('user') && (window.VRT && window.VRT.SSOController) && Date.now() >= user.exp * 1000) {
			console.log("EXPIRED TOKEN");

			return window.VRT.SSOController.getNewIdToken(function(res:any, err:any) {
				if(err) {
					console.log(err);
				}
				//update token localstorage
				lockr.set('token', res);
				//update axios headers
				axiosConfig.headers.Authorization = res;

				// const CancelToken = axios.CancelToken;			
				// return axios.request({
				// 	...axiosConfig,
				// 	cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request'))
				//   });
				return axios.request({
					...axiosConfig
				});
			});
		}
	}

	// Do something before request is sent
	return axiosConfig;
}, function (error) {
	console.log("INTERCEPTOR ERROR");
	console.log(error);
	// Do something with request error
	return Promise.reject(error);
});

function requestHeaders() {
	const authToken = lockr.get('token');
	const defaultHeaders = {
		'Content-Type': 'application/json',
	};

	if (authToken) {
		return Object.assign({}, defaultHeaders, {'Authorization': authToken});
	} else {
		return defaultHeaders;
	}
}

function post(url: string, data: any, headerConfig?: object) {
	const headers = requestHeaders();
	data.widgetUser = lockr.get('widgetUser');

	if (headerConfig) { Object.assign(headers, headerConfig); }

	return axios({method: 'post', url: `${config.API_URL}/${url}`, data, headers})
		.then(res => res.data);
}

function get(url: string, params: any) {
	const headers = requestHeaders();
	return axios({method: 'get', url: `${config.API_URL}/${url}`, params, headers})
		.then(res => res.data);
}

function put(url: string, data: any) {
	const headers = requestHeaders();
	data.widgetUser = lockr.get('widgetUser');
	
	return axios({method: 'put', url: `${config.API_URL}/${url}`, data, headers})
		.then(res => res.data);
}

function deleteRequest(url: string, params: any) {
	const headers = requestHeaders();
	return axios({method: 'delete', url: `${config.API_URL}/${url}`, params, headers})
		.then(res => res.data);
}

export default {
	post: post,
	get: get,
	put: put,
	delete: deleteRequest
};
