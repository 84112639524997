import React, { Component } from 'react';
import { ContainerStyle, BasicTableStyle } from './../PlayerList/PlayerListStyle';
import Button from '../UI/Button/Button';
import Link from '../UI/Link/Link';
import * as leaguesActions from '../../actions/leagues';

export interface TeamsListProps {
	leagueId: number;
	weekId: number | null;
	data: any;
	size: number;
	isLoading?: boolean;
	showHeader?: boolean;
	isAdmin: boolean;
	adminTeamId?: number | null;
	onTeamKick: any;
	application: any;
	weeks: any;
	totalRecords?: any;
}

export interface TeamsListState {
	rankingsResults: any;
	currentPage: number;
	fetchingRankings: boolean;
}

class TeamsList extends Component<TeamsListProps, TeamsListState> {
	state = {
		rankingsResults: this.props.data.map((row: any, index: number) =>
			Object.assign({}, row.team, { rank: index + 1 })),//.data?.map((team: any) => Object.assign({}, team.team, { rank: team.rank })),
		currentPage: 1,
		fetchingRankings: false,
	};

	handleTableChange = (pagination: any) => {
		this.setState({ 
			currentPage: pagination.current,
			fetchingRankings: true 
		});

		leaguesActions.fetchLeagueInfo(this.props.leagueId ? this.props.leagueId : 0, this.props.weekId, undefined, pagination.current).then(
				//todo pagination
				leagueInfo => {
						this.setState({
								rankingsResults: leagueInfo.result.data.map((team: any) => Object.assign({}, team.team, { 
										rank: (pagination.current-1)*10+team.rank 
								})),
								fetchingRankings: false,
						});
				},
		);
	};

	componentDidUpdate(prevProps: TeamsListProps) {
		if(this.props.data !== prevProps.data) { 
				this.setState({
						rankingsResults: this.props.data.map((row: any, index: number) =>
							Object.assign({}, row.team, { rank: index + 1 })),//.data?.map((team: any) => Object.assign({}, team.team, { rank: team.rank })),
						// selectedRankingsType: 'general',
						// searchRankingTerm: '',
						// selectedWeekId: 1,
						currentPage: 1,
						// totalRecordsPerPage: 10,
						fetchingRankings: false,
				});
		}
	}

	render() {
		const { size, showHeader, isLoading, isAdmin, adminTeamId, totalRecords } = this.props;
		
		const columns = [{
			title: '#',
			key: 'rank',
			dataIndex: 'rank',
			width: '10%',
			render: (text: string, record: any) => {
				return <span>{text} <img style={{maxHeight: "35px",maxWidth: "35px",borderRadius: "50%", display: "inline-block"}}
				src={record.badge} /></span>
			}
		}, {
			key: 'name',
			title: 'Naam',
			width: '50%',
			dataIndex: 'name',
			render: (text: string, record: any) => {
				const firstTeamGameWeek = (this.props.weeks.deadlineWeek === this.props.application.competition.officialStartWeek);

				return <React.Fragment>
				
					{
						(firstTeamGameWeek) ? <b>{text}</b> : <Link to={`public/${record.id}`}> <b>{text}</b></Link>
					}
					<span style={{ display: 'block', fontSize: '12px' }}>
						{(record.name_visible) ? `${record.first_name} ${record.last_name}` : null}
					</span>
				</React.Fragment>;
			}
		}, {
			key: 'points',
			title: 'Punten',
			width: '20%',
			dataIndex: 'points',
			render: (text: string, record: any) => {
				return <b>{text === null ? '0' : text}</b>
			}
		}];

		if (isAdmin) {
			columns.push({
				key: 'action',
				title: '',
				width: '20%',
				dataIndex: 'action',
				render: (text: string, team: any) => {
					return (adminTeamId && adminTeamId !== team.id && <Button
						onClick={(e: any) => this.props.onTeamKick && this.props.onTeamKick(team)}
						size="small">
						Verwijder
					</Button>) || <span />;
				}
			});
		}

		return (
			<ContainerStyle>
				<BasicTableStyle
					columns={columns}
					dataSource={this.state.rankingsResults}
					showHeader={showHeader}
					locale={{ emptyText: 'Rangschikking nog niet beschikbaar' }}
					loading={isLoading}
					pagination={{
						current: this.state.currentPage,
            total: totalRecords,
						pageSize: size 
					}}
					rowKey="id"
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
					onChange={this.handleTableChange}
				/>
			</ContainerStyle>
		);
	}
}

export default TeamsList;
