import {
	loadLeagues,
	leaguesFetchedWithSuccess,
	leaguesFetchFailed,
	UserAction,
} from './user';

import { Dispatch } from 'redux';
import http from './../lib/http';
import { notification } from 'antd';

export function fetchTeamLeagues(teamId: number) {
	return http.get(`leagues/${teamId}`, {});
}

export function fetchLeagueInfo(
	leagueId: number,
	weekId?: number | null,
	externalTeamIds: number[] = [],
	pageNumber?: number | null
) {
	return http.get(
		`league/${leagueId}?&pageRecords=10&pageNumber=${pageNumber? pageNumber : 1}
		${weekId ? `&weekId=${weekId}` : ''}
		${
		externalTeamIds && externalTeamIds.length
			? `&externalTeamIds=[${externalTeamIds.join(',')}]`
			: ''
		}`,
		{}
	);
}

export function searchLeagues(
	name: string,
	competitionFeed: string,
	seasonId: number
) {
	return http.get(`league/search`, { name, competitionFeed, seasonId });
}

export function createLeague(
	name: string,
	competitionFeed: string,
	seasonId: number,
	teamId: number
) {
	return http.post(`league/team/${teamId}`, {
		name,
		competitionFeed,
		seasonId,
	});
}

export function joinLeague(leagueId: number, teamId: number) {
	return http.post(`league/${leagueId}/join/${teamId}`, {});
}

export function leaveLeague(leagueId: number, teamId: number) {
	return http.delete(`league/${leagueId}/${teamId}`, {});
}

export function removeLeague(leagueId: number) {
	return http.delete(`league/${leagueId}`, {});
}

export function editLeague(leagueId: number, weekId?: string, name?: string) {
	return http.put(`league/${leagueId}`, { weekId, name });
}

export function uploadCover(file: any, leagueId: any, leagueName: any) {
	const formData = new FormData();
	formData.append('leagueId', leagueId)
	formData.append('leagueName', leagueName)
	formData.append('cover',file)
    const config = {'Content-Type': 'multipart/form-data'};
	
	return http.post(`league/cover`, formData, config);
}