import styled from "styled-components";
import { Table } from "antd";

import mediaQueries from "../../styles/media-queries";
import { theme } from '../../styles/theme-sporza';


import "antd/lib/table/style";

const ContainerStyle = styled.div`
	
`;

const SelectGroupStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;

	> div {
		margin: 5px 0;
	}

	@media ${mediaQueries.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;

		> div {
			margin: 0 5px;
		}
	}
`;

const PlayerStyle = styled.div`
	${(props: any) =>
		props.type === "desktop" &&
		`
		display: none;
	`}
	
	@media ${mediaQueries.mobileL} {
		${(props: any) =>
		props.type === "desktop" &&
		`
			display: block;
		`}
	}

	.name {
		color: #FFF;
		white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	max-width: 150px;
	}

	${(props: any) =>
		props.type === "mobile" &&
		`
		display: block;
	`}

	@media ${mediaQueries.mobileL} {
		${(props: any) =>
		props.type === "mobile" &&
		`
			display: none;
		`}
	}

	p {
		margin-bottom: 0;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			color: ${(props: any) => props.clubColor};

			span {
				${(props: any) =>
		props.position === "gk" &&
		`
					color: ${props.theme.positionGk};
				`}

				${(props: any) =>
		props.position === "df" &&
		`
					color: ${props.theme.positionDf};
				`}

				${(props: any) =>
		props.position === "mf" &&
		`
					color: ${props.theme.positionMf};
				`}

				${(props: any) =>
		props.position === "fw" &&
		`
					color: ${props.theme.positionFw};
				`}
			}
		}

	}
` as any;

export {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
};
