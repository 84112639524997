import dropdownSvg from "../../assets/img/dropdown-action.svg";
import mediaQueries from "../../styles/media-queries";
import styled, {keyframes} from "../../styles/styled-components";

const slideIn = keyframes`
    0% { opacity: 0; top: -5px; }
    30% { opacity: 1; top: -5px; }
    100% { opacity: 1; top:  0px; } 
`;

const HeaderBackground = styled.div<{bg: string}>`
    background: #1E1F1D;
    border-top: 1px solid rgba(127, 139, 146, 0.32);
    display: flex;
    align-items: center;
    height: 60px;
`;

const HeaderStyle = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    
    .hamburger-menu {
        position: relative;
        
        .game-switcher {
            position: absolute;
            width: 100vw;
            height: 100vh;
            background: #3C3C3C;
            top: 42px;
            z-index: 2;
            left: -10px;
            padding: 10px 30px;
            
            nav + nav {
                margin-top: 20px;
            }
            
            button, .nav-link {
                display: inline-block;
                font-size: 18px;
                margin-bottom: 10px;
                border-bottom: 1px solid #7F8B9252;
                width: 100%;
                text-align: left;
                padding: 0;
            }
            
            button:hover, button.active, .nav-link:hover, .nav-link.active {
                color: #FF286E;
            }
        }
    }

    .active-feed, .nav-links {
        display: none;
    }

    @media ${mediaQueries.tablet} {
        .active-feed {
            display: inline;
        }
        .nav-links {
            display: block;
        }
        .mobile-nav-links {
            display: none;
        }
        
        .header-section {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .hamburger-menu {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            .game-switcher {
                top: 0;
                left: 18px;
                width: auto;
                height: auto;
                padding: 0;
                background: transparent;
                
                nav {
                    margin: -15px 0 0 5px !important;
                    padding-left: 6px;
                }
                
                button {
                    font-size: inherit;
                    border: 0;
                    margin: 0;
                }
            }
        }


        .mobile-menu {
            display: none;
            position: absolute;
            width: 70%;
            left: 0;
            top: 100%;
        }

        .hamburger-menu:hover .mobile-menu {
            display: block;
        }

        .nav-links {
            text-align: center;
        }

        .nav-link {
            color: white;
            text-decoration: none;
            margin: 0 10px;
            border-bottom: 3px solid transparent;
        }

        .nav-link:hover,
        .nav-link.active {
            border-color: #FF286E;
        }

        .header-button {
            background-color: #FF286E;
            color: white;
            border: none;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
        }
    }

    // @media ${mediaQueries.tablet} {
    //     height: 100px;
    //     background-position: center bottom -90px;
    // }

    // @media ${mediaQueries.desktop} {
    //     background-position: center bottom -170px;
    // }

    // @media ${mediaQueries.desktopL} {
    //     background-position: center bottom -220px;
    // }

    // @media screen and (min-height: 1000px) {
    //     height: 150px;
    // }
` as any;

const Logo = styled.div`
    display: flex;
    align-items: center;

    > img {
        width: 0px;
        visibility: hidden;

        @media ${mediaQueries.tablet} {
            visibility: visible;
            width: 100px;
            height: 100px;
        }
    }
`;

const MobileBackButton = styled.button`
    color: ${props => props.theme.primaryColor};
    font-size: 1.5em;
    display: flex;
    align-self: flex-end;
    margin: 10px 10px 0 0;

    @media ${mediaQueries.tablet} {
        display: none;
    }
`;

const Nav = styled.nav<{open:boolean}>`
    flex: 1;
    position: absolute;
    top: 0;
    left: ${(props) => props.open ? '0' : '-80vw'};
    width: 80vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0,0,0,0.95);
    border-right: 4px solid ${props => props.theme.primaryColor};
    transition: left 150ms linear;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media ${mediaQueries.tablet} {
        flex-direction: row; 
        justify-content: center;
        // padding-top: 20px;
        display: block;
        position: static;
        width: auto;
        height: auto;
        background-color: transparent;
        border-right: 0;
    }

    // .title {	
    //     text-align: center;
    //     margin: 20px;

    //     @media ${mediaQueries.tablet} {
    //         padding-left: 10px;
    //         padding-bottom: 10px;
    //         margin: 0;
    //         text-align: left;
    //     }

    //     h1 {
    //         color: white;
    //         line-height: 1;
    //         font-size: 18px;
    //         margin-bottom: 0;

    //         @media ${mediaQueries.tablet} {
    //             margin-bottom: 5px;
    //             font-size: 24px;
    //         }
    //     }

    //     .sponsor {
    //         display: flex;
    //         align-items: center;

    //         h4 {
    //             color: white;
    //             font-size: 12px;
    //             margin-right: 10px;

    //             @media ${mediaQueries.tablet} {
    //                 font-size: 14px;
    //             }
    //         }			

    //         img {
    //             height: 50px;
    //             width: auto;
    //             margin-top: 5px;
    //         }
    //     }
    // }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        li {
            padding: 20px;
            border-bottom: 1px #3C3C3C solid;

            @media ${mediaQueries.tablet} {
                padding: 0px;
                border: none;
            }
        }

        li.has-children > a {
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                background-image: url(${dropdownSvg});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-left: 5px;
                margin-top: 2px;
            }
        }
        
        li.has-children > ul {
            display: none;
        }
        
        li.active > a {
            color: ${props => props.theme.primaryColor}

            @media ${mediaQueries.tablet} {
                color: #FFF;
                border-color: ${props => props.theme.primaryColor}
            }
        }

        li a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 10px;
            color: #FFF;
            padding: 0px;
            border-bottom: 3px solid transparent;
            transition: background-color 150ms ease-in;
            
            &:focus {
                text-decoration: none;
            }
        }
    }

    > ul {
        
        @media ${mediaQueries.tablet} {
            flex-direction: row;
            background-position: center bottom -90px;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        > li {
            position: relative;
            // top: -5px;

            &.open {
                > ul {
                    z-index: 1;
                    display: inherit;
                    > li {
                        animation: 250ms ${slideIn} ease-in;
                        animation-fill-mode: forwards;

                        &:nth-child(2) {
                            animation-delay: 50ms;
                        }
                        
                        &:nth-child(3) {
                            animation-delay: 100ms;
                        }

                        &:nth-child(4) {
                            animation-delay: 150ms;
                        }
                    }
                }
            }

            > a {
                
            }

            > ul {
                position: absolute;

                @media ${mediaQueries.tablet} {
                    right: 0;
                }

                > li {
                    opacity: 0;
                    position: relative;
                }
            }
        }
    }
`;

const Hamburger = styled.button`
    background: none;
    appearance: none;
    border: 0;
    display: block;
    width: 35px;
    height: 30px;
    padding: 5px;
    margin-right: 20px;

    &:before, &:after {
        content: '';
    }

    span, &:before, &:after {
        width: 25px;
        display: block;
        height: 3px;
        background-color: #FFF;
        border-radius: 3px;
    }

    span {
        margin: 4px 0;
    }

    @media ${mediaQueries.tablet} {
        display: none;
    }
`;

const NavLastItem = styled.li`
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @media ${mediaQueries.tablet} {
        padding-top: 0;
        margin-right: 0px;
    }
`;

export {
    HeaderStyle,
    Logo,
    MobileBackButton,
    Nav,
    Hamburger,
    HeaderBackground,
    NavLastItem
};
