import React, { Component } from "react";
import { Form, notification, Select } from 'antd';
import { pick } from 'lodash';
import lockr from 'lockr';
import moment from 'moment';
import { H3 } from "../../styles/global";

import { Button, Input, InputPassword, Checkbox, FormItem, DatePicker, Link } from '..';
import * as userActions from '../../actions/user'

import { RegisterStyle } from "./RegisterStyle";

export interface RegisterFormProps {
	form: any,
	t: any
}

export interface RegisterFormValues {
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	dob: string,
	termsAccepted: boolean,
	commercialAccepted: boolean,
	registerInfo: string
}

class RegisterForm extends Component<RegisterFormProps> {

	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err: any, values: RegisterFormValues) => {
			if (!err) {
				userActions.register(
					values.firstName,
					values.lastName,
					values.email,
					values.password,
					moment(values.dob).format('YYYY-MM-DD'),
					values.commercialAccepted,
					values.registerInfo
				)
					.then((result: any) => {
						if (result && result.token) {
							
							if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
								(window as any).$crisp.push(["set", "user:email", result.email]);
								// name: `${result.firstName} ${result.lastName}`,
							}

							const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode', 'registerInfo']);
							lockr.set('token', result.token);
							lockr.set('user', user);
							window.location.href = '/new';
						}
					})
					.catch((error:any) => {
						console.log(error)
						notification.error({ message: this.props.t('user.registerEmailAlreadyExists') });
					})
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { t } = this.props;
		const dobFormat = 'DD/MM/YYYY';
		const { Option } = Select;

		return (
			<RegisterStyle>
				<Form onSubmit={this.handleSubmit} className="register-form">
					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('firstName', {
							rules: [{ required: true, message: t('user.registerFirstNameRequiredValidation') }],
						})(
							<Input placeholder={t('user.userInputPlaceholderFirstName')} />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('lastName', {
							rules: [{ required: true, message: t('user.registerLastNameRequiredValidation') }],
						})(
							<Input placeholder={t('user.userInputPlaceholderLastName')} />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('email', {
							rules: [{ required: true, message: t('user.registerEmailRequiredValidation') }],
						})(
							<Input type="email" placeholder={t('user.userEmailPlaceholder')} />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('password', {
							rules: [
								{ required: true, message: t('user.registerPasswordRequiredValidation') },
								{ min: 6, message: t('user.registerPasswordLengthValidation') }],
						})(
							<InputPassword type="password" placeholder={t('user.userPasswordPlaceholder')} />
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator('termsAccepted', {
							initialValue: false,
							rules: [{
								required: true,
								transform: (value: any) => (value || undefined),
								type: 'boolean',
								message: t('user.registerTermsRequiredValidation')
							}],
						})(
							<Checkbox style={{color: "#FFF"}}>
								{t('user.registerIAgreePrefix')} <Link to={'/rules'}>{t('user.registerGameRule')}</Link>.
							</Checkbox>
						)}
					</FormItem>

					<Button type="primary" htmlType="submit" style={{ marginBottom: '20px', width: '100%' }}>{t('user.registerTriggerButtonLabel')}</Button>
					<p><Link to={`/home`}>{t('user.registerHaveAlreadyAnAccount')}</Link></p>
				</Form>
			</RegisterStyle>
		);
	}
}

export default RegisterForm;