import React, {Component} from 'react';
import Countdown from 'react-countdown-now';

import {Overview, Value, Label} from './TeamOverviewStyle';
import 'antd/lib/divider/style';
import {Divider} from 'antd';
import {Row, Col} from './../UI/Grid/Grid'

export interface TeamCreateOverviewState {
}

export interface TeamCreateOverviewProps {
	budget: number;
	totalPlayersSelected: number;
	totalPlayers: number;
	showRemainingTransfers?: boolean;
	remainingTransfers?: number;
	deadlineEpochMilliseconds?: number;
	deadlineWeek?: number;
}

class TeamCreateOverview extends Component<TeamCreateOverviewProps, TeamCreateOverviewState> {
	render() {
		const deadlineRenderer = ({days, hours, minutes, seconds}: any) => {
			return <span>
				{days}d {hours}u {minutes}m {seconds}s
			</span>;
		};

		return (
			<Overview>
				<Row className="header" style={{margin: 0}}>
					<Col md={8} xs={6}>
						<Label>
							Budget:
						</Label>
						<Value>
							<div>${this.props.budget}M</div>
						</Value>
					</Col>

					<Col md={8} xs={6}>
						<Label>
							Spelers:
						</Label>
						<Value>
							<div>{this.props.totalPlayersSelected}/{this.props.totalPlayers}</div>
						</Value>
					</Col>

					<Col md={8} xs={12}>
						<Label>
							{this.props.deadlineWeek ? `Speeldag ${this.props.deadlineWeek}` : '-'}
						</Label>
						<Value>
							<div>
								{
									this.props.deadlineEpochMilliseconds ?
									<Countdown
										renderer={deadlineRenderer}
										date={this.props.deadlineEpochMilliseconds}/>
									: '-'
								}
							</div>
						</Value>
					</Col>
				</Row>
				{
					(this.props.showRemainingTransfers &&
						<Row style={{marginTop: '15px'}}>
							<Col span={24} style={{textAlign: 'center'}}>
								<Label>
									Resterende transfers: {this.props.remainingTransfers && this.props.remainingTransfers < 0 ? 0 : this.props.remainingTransfers}
								</Label>
							</Col>
						</Row>) || null
				}
			</Overview>
		)
	}
}

export default TeamCreateOverview;