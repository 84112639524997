import styled from 'styled-components';

const StatsStyle = styled.div`
	border-radius: 0;
	margin-top: 10px;

	.stat-row {
		padding: 0px;
		margin: 0px;

		&.no-border {
			border-bottom: none;
		}

		.label {
			font-size: 1.5em;
			color: #FFF;
		}

		.points {
			font-size: 2em;
			color: ${props => props.theme.primaryColor};
			display: block;
			text-align: right;
			font-weight: bold;
			margin: 0;
		}
	}

	.stat {
		padding: 5px 0;

		.label {
			color: #989998;
			text-align: left;
			font-size: 14px;
		}

		.points {
			color: ${props => props.theme.primaryColor};
			font-size: 14px;
			text-align: right;
		}

		.has-transfers {
			span {
				border-bottom: 2px solid ${props => props.theme.primaryColor};
				cursor: pointer;
				padding: 0px 5px 0px 5px;
			}
		}

		.green {
			color: green;
		}

		.red {
			color: red;
		}
	}
`;


export {
	StatsStyle
};
