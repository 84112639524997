const theme = {
	primaryColor: "#84FF00",
	primaryContrast: '#3C3C3C',
	secondaryColor: '#000',
	positionCoa: '#d1d7da',
	positionGk: '#4ab969',
	positionDf: '#2cb3c7',
	positionMf: '#ffc333',
	positionFw: '#fb6b5b'
};

export {
	theme
};