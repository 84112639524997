import React, { Component } from 'react';
import { Icon } from 'antd';

import { StatsStyle } from './StatsStyle';
import { Col, Row, TransfersModal } from './../.';

import { ApplicationState, MatchesState } from '../../types';
import * as teamsActions from '../../actions/teams'
import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

import { BoostersWeekStatus } from '../../types';

interface PointsConfirmation {
	id: number;
	seasonId: number;
	weekId: number;
	confirmed: number;
}

export interface StatsOverviewState {
	pointsConfirmation: PointsConfirmation[];
	transfersModalEnabled: boolean
}

export interface StatsOverviewProps {
	application: ApplicationState;
	matches: MatchesState;
	generalPoints: number | string;
	generalRank: number | string;
	weekId?: number;
	totalTransfers: number;
	visibleWeekPoints: number | string;
	visibleWeekRank: number | string;
	weekPointsConfirmed?: boolean,
	provisionalPoints?: number;
	weekWinnerPoints?: number;
	transfers?: any[];
	boosterWeekStatus: BoostersWeekStatus;
	t: any;
};

class Stats extends Component<StatsOverviewProps, StatsOverviewState> {
	constructor(props: StatsOverviewProps) {
		super(props);
		this.state = {
			pointsConfirmation: [],
			transfersModalEnabled: false
		}

		// teamsActions.getPointsConfirmation(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId)
		// .then((pointsConfirmation: PointsConfirmation[]) => {
		// 	this.setState({ pointsConfirmation })
		// });
	}

	showTransfersModal = () => {
		this.setState({ transfersModalEnabled: true })
	}

	hideTransfersModal = () => {
		this.setState({ transfersModalEnabled: false })
	}

	render() {
		const { t, transfers, matches } = this.props;
		const { transfersModalEnabled } = this.state;

		let weekConfirmation: any = null;
		weekConfirmation = this.state.pointsConfirmation
			.find((item: PointsConfirmation) => !!(item.weekId === (this.props.weekId)));
		const weekPointsConfirmed = weekConfirmation && weekConfirmation.confirmed ? parseInt(weekConfirmation.confirmed) : false;

		return (
			<StatsStyle>
				{/* <Row className="stat">
					<Col lg={16} md={16} sm={16} xs={16} className="label">{t('pointsPage.weekStatusTitle')}</Col>
					<Col lg={8} md={8} sm={8} xs={8} className={`points result ${weekPointsConfirmed ? 'confirmed' : 'unconfirmed'}`}>
						{weekPointsConfirmed ? 
							<React.Fragment>
								<ConfirmedIcon/> {t('pointsPage.weekStatusConfirmed')}
							</React.Fragment>
						: <React.Fragment>
							<UnConfirmedIcon/> {t('pointsPage.weekStatusNotConfirmed')}
						  </React.Fragment>
						}
					</Col>
				</Row> */}
				<Row className="stat">
					<Col lg={18} md={18} sm={18} xs={18} className="label">{`${t('pointsPage.overviewWeekPoints')} ${this.props.weekId}`}</Col>
					<Col lg={6} md={6} sm={6} xs={6} className="points">{this.props.visibleWeekPoints}</Col>
				</Row>
				<Row className="stat">
					<Col lg={18} md={18} sm={18} xs={18} className="label">{`${t('pointsPage.overviewWeekRank')} ${this.props.weekId}`}</Col>
					<Col lg={6} md={6} sm={6} xs={6} className="points">{this.props.visibleWeekRank}</Col>
				</Row>				
				<Row className="stat">
					<Col lg={18} md={18} sm={18} xs={18} className="label">{t('pointsPage.overviewGeneralPoints')}</Col>
					<Col lg={6} md={6} sm={6} xs={6} className="points">{this.props.generalPoints}</Col>
				</Row>
				<Row className="stat">
					<Col lg={18} md={18} sm={18} xs={18} className="label">{t('pointsPage.overviewGeneralRank')}</Col>
					<Col lg={6} md={6} sm={6} xs={6} className="points">{this.props.generalRank}</Col>
				</Row>
				<Row className="stat">
					<Col lg={18} md={18} sm={18} xs={18} className="label">{`${t('pointsPage.overviewWeekTotalTransfers')} ${this.props.weekId}`}</Col>
					<Col lg={6} md={6} sm={6} xs={6} className={`points ${this.props.totalTransfers ? 'has-transfers' : ''}`}>
						<span onClick={this.props.totalTransfers ? this.showTransfersModal : () => null}> {this.props.totalTransfers} </span>
					</Col>
				</Row>

				<TransfersModal
					visible={transfersModalEnabled}
					transfers={transfers}
					t={t}
					onCancel={this.hideTransfersModal}>
				</TransfersModal>
			</StatsStyle>
		)
	}
}

export default Stats;