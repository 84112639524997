import styled from 'styled-components';
import { Select } from 'antd';

import 'antd/lib/select/style';
import { MySelectProps } from './Select';

const SelectStyle = styled(Select)`
    width: 200px;
    color: #FFF !important;

    &.ant-select-dropdown-menu-item {
        color: #FFF;
    }

    ${(props: MySelectProps) => props.block === true && `
        width: 100%;
    `}

    &.ant-select-open .ant-select-selection, .ant-select-selection:focus {
        border: none;
        box-shadow: none;
    }

    .ant-select-selection {
        border-radius: 8px;
        background: #3C3C3C;
        border: none;

        background: #3C3C3C url(/assets/img/dropdown-action.svg) right 15px center no-repeat;

        .ant-select-selection__placeholder {
            font-weight: bold;
            color: #FFF;
        }
    }

    .ant-select-prefix {
        color: #FFF;
    }

    &.ant-select-disabled .ant-select-selection {
        background: #3C3C3C url(/assets/img/dropdown-action-disabled.svg) right 15px center no-repeat;
        
        .ant-select-selection__placeholder {
            color: #FFF;
        }
    }

    .ant-select-arrow {
        color: ${props => props.theme.primaryColor};
    }
` as any;

const selectTopMarginOnSmallerScreens = (component: any) => styled(component)`
	&.ant-select {
        color: #FFF;

		@media (max-width: 768px) {
			margin-top: 10px;
		}
	}
`;

const OptionStyle = styled(Select.Option)``;

export {
	SelectStyle,
	OptionStyle,
	selectTopMarginOnSmallerScreens
};